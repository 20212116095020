import "./styles.css"
import {body} from "../../../text/story-transition";
import {MutableRefObject} from "react";
import GamePage from "../game-page/game-page";
import {useAppSelector} from "../../../redux/store";
import {GameMode} from "../../../types2";
import {selectIsSolo} from "../../../redux/selectors";
import {numberString} from "../character-submit/character-submit";
import {useTranslation} from "react-i18next";
import StoryTransitionRow from "./story-transition-row";
import StoryTransitions from "./story-transitions";

type Props = {
    timeToStartRef: MutableRefObject<number>,
}


const bodies = [body.part1, body.partX, body.partEnd]
const bodiesSolo = [body.part1Solo, body.partXSolo, body.partEndSolo]


const StoryTransition = ({timeToStartRef}: Props) => {

    const selectGameSettings = useAppSelector(state => state.game.game?.settings)

    const selectSubmission = useAppSelector(state => state.player.player?.current_submission)
    const selectStory = useAppSelector(state => state.player.player?.current_story)

    let isSolo = useAppSelector(selectIsSolo);

    const submissionIndex = selectSubmission?.index as number


    const {t} = useTranslation(['common'])


    function partMap(index: number, numSwitches: number) {
        if (index === 0) {
            return t('label.beginning', {ns: 'common'})
        } else if (index === numSwitches - 1) {
            return t('label.end', {ns: 'common'})
        } else {
            return `${t('label.part', {ns: 'common'})} ${numberString(index + 1)}`
        }
    }

    function getBody() {
        if (submissionIndex === 0) {
            return isSolo ? bodiesSolo[0] : bodies[0]
        } else if (submissionIndex === ((selectGameSettings?.num_switches ?? 3) - 1)) {
            return isSolo ? bodiesSolo[2] : bodies[2]
        } else {
            return isSolo ? bodiesSolo[1] : bodies[1]
        }
    }

    function getHeader() {
        return partMap(submissionIndex, selectGameSettings?.num_switches ?? 3)
    }

    return <GamePage titles={[getHeader()]} hideAvatar>
        <StoryTransitions timeToStartRef={timeToStartRef} fast={selectGameSettings?.game_mode === GameMode.solo_mode}>
            <StoryTransitionRow
                text={getBody()(submissionIndex, selectGameSettings?.num_switches ?? 3, selectStory?.meta.index ?? 0)}/>

            <StoryTransitionRow
                text={isSolo ? body.charactersSolo(selectStory?.characters) : body.characters(selectStory?.characters)}/>

            {(selectGameSettings?.game_mode === GameMode.solo_mode || selectGameSettings?.forced_prompts) &&
                <StoryTransitionRow
                    text={body.prompt(selectSubmission, selectGameSettings.multi_prompt || selectGameSettings.game_mode === GameMode.solo_mode)}/>}

            {!isSolo && <StoryTransitionRow text={body.mature(selectStory?.settings.mature ? "18+" : "ALL AGES")}/>}

        </StoryTransitions>


        {/*</div>*/}
    </GamePage>
}

export default StoryTransition