import React from "react"

type ButtonProps = {
    handleClick: () => void
    bigText: string
    smallText: string
}

const GameModeButton: React.FunctionComponent<ButtonProps> = (props) => {


    return <div className={"w-full flex flex-col items-stretch"}>

        <button className={"p-1 text-4xl"} onClick={props.handleClick}>
            <div>{props.bigText}</div>
        </button>
        <div className={"p-1 text-4xl bg-night-primary ml-8 flex flex-row justify-start shadow-basic border-[1px] border-black"}>
            <div className={"text-lg pl-4 text-left"}>{props.smallText}</div>
        </div>
    </div>
}

export default GameModeButton