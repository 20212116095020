import {useState} from "react";
import StoryVoteComponent from "./story-vote-component";
import Page from "../../../common/page/page";
import {StoryVote} from "../../../../types";
import {useAppSelector} from "../../../../redux/store";

type Props = {
    onVote: (voteId: number, storyId: number) => void
    onBack: () => void
    loading?: boolean
}

const StoryVoteManager = ({onVote, onBack, loading}: Props) => {

    const playerState = useAppSelector(state => state.player)
    // let votes = useRef(Object.entries(Gamers.me?.votes))
    // let pops = useRef(Gamers.awards?.filter(a => votes.current.get(a.id) === ""));
    // let nextVote = useRef(Gamers.me?.votes?.find(v => v.story_id === 0) as StoryVote);

    const [votes, setVotes] = useState(new Map<number, number>())

    // const [award, setAward] = useState(pops.current ? pops.current.pop() : undefined)
    function nextVote() {
        return playerState.player?.votes?.find(v => v.story_id === 0)
    }

    function handleVote(voteId: number, storyId: number) {

        onVote(voteId, storyId)
        // setAward(pops.current ? pops.current.pop() : undefined)
    }

    function toggleVote(storyId?: number) {
        const nv = nextVote()
        if (nv) {
            if (storyId) {
                setVotes(v => new Map(v.set(nv.id, storyId)))
            } else {
                setVotes(v => {
                    v.delete(nv.id)
                    return new Map(v)
                })
            }
        }
    }

    let nv = nextVote()
    // if (award) {
    //     comp = <StoryVote onBack={() => console.log("back")} onVote={handleVote} award={award}/>
    // } else {
    //     comp = <Page>Finished voting!</Page>
    // }
    return nv ?
        <StoryVoteComponent currentVote={votes.get(nv.id)} vote={nv as StoryVote} onBack={onBack} onVote={handleVote}
                            toggleVote={toggleVote} loading={loading}/>
        : <Page>what did I do wrong</Page>
    // return comps && comps[voteIndex] ? comps[voteIndex] : <Page>what did I do wrong</Page>
}


export default StoryVoteManager