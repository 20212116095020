import React, {useState} from "react";

type Props = {
    onClick: () => void

    states: string[]

    resetPeriod?: number
    waitPeriod?: number
    // classname?: string
    disabled?: boolean
}

const StateButton: React.FunctionComponent<Props> = (props) => {


    const [index, setIndex] = useState<number>(0)
    const [disabled, setDisabled] = useState<boolean>(false)

    let resetTimer: NodeJS.Timeout | undefined = undefined

    // let waitTimer: NodeJS.Timeout | undefined = undefined

    function handleClick() {
        if (resetTimer !== undefined) {
            clearTimeout(resetTimer)
        }

        if (index === props.states.length - 1) {
            props.onClick()
        } else {
            setIndex(idx => idx + 1)
            resetTimer = setTimeout(() => {
                setIndex(0)
            }, props.resetPeriod ?? 2000)

            setDisabled(true)
            setTimeout(() => {
                setDisabled(false)
            }, props.waitPeriod ?? 250)

        }
    }

    return <button disabled={disabled || props.disabled}
                   className={"flex-1 flex flex-col justify-center items-center leading-none text-center"}
                   onClick={handleClick}>{props.states[index]}</button>

}

export default StateButton