import React, {useCallback, useEffect} from "react";
import {MenuContext} from "../../contexts";
import Name from "./name/name";
import Help from "./help/help";
import {PlayerDetails, PlayerId, Room} from "../../types";
import SelectGame from "./select-game/select-game";
import EnterGameCode from "./enter-game/enter-game-code";
import Contact from "./contact/contact";
import Main from "./main/main";
import Terms from "./terms/terms";
import SupportUs from "./supportus/supportus";
import Privacy from "./privacy/privacy";
import Blog from "./blog/blog";
import {CreateGame} from "./create-game/create-game";
import GameManager from "../game/game";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {
    clearRoom,
    handleBack,
    handleNewPage,
    resetPages,
    updatePlayerDetails,
    updatePlayerId
} from "../../redux/slices/menu";
import CodeOfConduct from "./code-of-conduct/contact";
import {useNavigate} from "react-router-dom";
import Zines from "./zines/zines";
import Admin from "./admin/admin";
import Thankyou from "./thankyou/thankyou";
import Drawing from "../game/drawing/drawing";
import Supporter from "./supporter/supporter";
import BugReport from "./bug-report/bug-report";

export enum PageNumber {
    Main,
    Name,
    NameSubmit,
    Help,
    GameSelect,
    GameEnterCode,
    GameCreate,
    Game,
    Lock,
    Contact,
    Terms,
    Privacy,
    About,
    Blog,
    SupportUs,
    GameEnterCodeAudience,
    PublicGames,
    CodeOfConduct,
    SupporterBadge,
    StoryBook,
    Zines,
    StoryBookView,
    Admin,
    ThankYou,
    Drawing,
    BugReport
}

interface Props {
    initialPage?: PageNumber
}


const Menu: React.FunctionComponent<Props> = ({initialPage}: Props) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const page = useAppSelector(state => state.menu.page)
    const playerDetails = useAppSelector(state => state.menu.playerDetails)
    const playerId = useAppSelector(state => state.menu.playerId)

    const setPage = useCallback((page: PageNumber) => {
        dispatch(handleNewPage(page))

    }, [dispatch])

    const back = useCallback(() => {
        switch (page) {
            case PageNumber.About:
            case PageNumber.Blog:
            case PageNumber.CodeOfConduct:
            case PageNumber.Contact:
            case PageNumber.Help:
            case PageNumber.Privacy:
            case PageNumber.Terms:
            case PageNumber.SupportUs:
            case PageNumber.SupporterBadge:
            case PageNumber.Name:
            case PageNumber.Zines:
            case PageNumber.Admin:
            case PageNumber.BugReport:
                dispatch(resetPages())
                navigate("/")
                break
            default:
                dispatch(handleBack())
                break
        }


    }, [dispatch, navigate, page])

    useEffect(() => {
        if (initialPage) {
            setPage(initialPage)
        }
    }, [initialPage, setPage])


    const handleBackspace = useCallback((event: KeyboardEvent) => {
        if (event.target instanceof Element) {
            if (event.target.nodeName === 'INPUT') {
                return
            } else if (event.target.nodeName === 'TEXTAREA') {
                return;
            }
        }

        if (event.code === 'Backspace' && page !== PageNumber.Game) {
            back()
        }

    }, [back, page])


    useEffect(() => {

        window.addEventListener('keydown', handleBackspace)

        return () => {
            window.removeEventListener('keydown', handleBackspace)
        }
    }, [handleBackspace])


    function setPlayerDetails(playerDetails: PlayerDetails) {
        dispatch(updatePlayerDetails(playerDetails))
    }

    let comp;

    function handleRoomJoin(room: Room, player: PlayerId) {
        dispatch(updatePlayerId({
            ...player,
            room_id: room.id,
            room_code: room.code
        }))
        setPage(PageNumber.Game)

        if (player.secret) {
            localStorage.setItem("playerSecret", player.secret)
        }
    }

    function handlePlayAgain(delRoom: boolean) {
        dispatch(resetPages())
        if (delRoom) {
            dispatch(clearRoom)
        }

    }


    switch (page) {

        case PageNumber.Main:
            comp = <Main
                onSubmit={() => {
                    if (playerDetails === undefined || playerDetails.name.length === 0) {
                        setPage(PageNumber.NameSubmit)
                    } else {
                        setPage(PageNumber.GameSelect)
                    }
                }}
                onName={() => setPage(PageNumber.Name)}
            />
            break
        case PageNumber.Name:
            comp = <Name player={playerDetails} onPlayerSubmit={(p) => {
                navigate("/")
                setPage(PageNumber.Main)
                setPlayerDetails({
                    name: p.name, meta: {
                        theme: p.meta.theme,
                        sprite_id: p.meta.sprite_id,
                        mature: p.meta.mature,
                        accessibility: p.meta.accessibility,
                        accessibility2: p.meta.accessibility2
                    }
                })
            }}/>
            break
        case PageNumber.NameSubmit:
            comp = <Name player={playerDetails} onPlayerSubmit={(p) => {

                setPage(PageNumber.GameSelect)
                setPlayerDetails({
                    name: p.name, meta: {
                        theme: p.meta.theme,
                        sprite_id: p.meta.sprite_id,
                        mature: p.meta.mature,
                        accessibility: p.meta.accessibility,
                        accessibility2: p.meta.accessibility2
                    }
                })
            }}/>
            break
        case PageNumber.Help:
            comp = <Help about={false}/>
            break
        case PageNumber.About:
            comp = <Help about={true}/>
            break
        case PageNumber.Contact:
            comp = <Contact/>
            break
        case PageNumber.SupportUs:
            comp = <SupportUs/>
            break
        case PageNumber.CodeOfConduct:
            comp = <CodeOfConduct/>
            break
        case PageNumber.Terms:
            comp = <Terms/>
            break
        case PageNumber.GameEnterCode:
            comp = <EnterGameCode onRoomJoin={handleRoomJoin}/>
            break
        case PageNumber.GameEnterCodeAudience:
            comp = <EnterGameCode onRoomJoin={handleRoomJoin} audience={true}/>
            break
        case PageNumber.GameCreate:
            comp = <CreateGame onCreate={room => handleRoomJoin(room, room.host)}/>
            break
        case PageNumber.GameSelect:

            comp = <SelectGame onEnterAudience={() => setPage(PageNumber.GameEnterCodeAudience)}
                               onCreateGame={() => setPage(PageNumber.GameCreate)}
                               onEnterGame={() => setPage(PageNumber.GameEnterCode)}/>
            break
        case PageNumber.Game:
            comp = <GameManager playerId={playerId as PlayerId} playAgain={handlePlayAgain}
                                goAway={(text) => {
                                    setPage(PageNumber.Main)
                                }}/>
            break
        case PageNumber.Privacy:
            comp = <Privacy/>
            break
        case PageNumber.Blog:
            comp = <Blog/>
            break
        case PageNumber.SupporterBadge:
            comp = <Supporter/>
            break
        case PageNumber.Zines:
            comp = <Zines/>
            break
        case PageNumber.Admin:
            comp = <Admin/>
            break
        case PageNumber.ThankYou:
            comp = <Thankyou/>
            break
        case PageNumber.Drawing:
            comp = <Drawing/>
            break
        case PageNumber.BugReport:
            comp = <BugReport/>
            break
        default:
            comp = <Main
                onSubmit={() => {
                    if (playerDetails === undefined) {
                        setPage(PageNumber.NameSubmit)
                    } else {
                        setPage(PageNumber.GameSelect)
                    }
                }}
                onName={() => setPage(PageNumber.Name)}
            />
    }

    return <MenuContext.Provider
        value={{onBack: back, setPage: setPage}}>
        {comp}
    </MenuContext.Provider>
}

export default Menu