import React from "react";
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";

export let blog12Title = "Entry #12 - CAUTION! WE BLEW UP ON TIKTOK."

export let blog12Author = "Posted by Michael / Sunday June 27 2021"

export let blog12Content = <Body>
    <BodyText>Hi everyone.... oh wow, there are so many of you. So Zee made a post on TikTok expecting about 300 people
        to see
        it. Then 200,000 people saw it instead. Holy crap.</BodyText>

    <BodyText>What happens when 20,000 people use a service that has only really been tested with a maximum amount of
        players
        of about... 40 at a time? This weekend happens that's what.</BodyText>

    <BodyText>There have been some bugs reported this evening. We are sorry! We did not expect literally thousands of
        you to
        come to our website all at once and the bugs are directly related to how many people used the website
        concurrently. We
        know you want to play! And we want you to play. But we *MAY* have to throw the website into maintenence tomorrow
        if the bug fix Michael just pushed through didn't work one hundred percent. If we need to, that will be around
        3PM EST. Do not start any games at this time if you see a warning on the page. </BodyText>

    <BodyText>The absolute worst possible scenario we're trying to avoid is having people lose their stories. That's the
        worst.
        We've heard some people lost theirs tonight. If you remember the room code, you can email us and we can send you
        your stories. Please keep the room code handy. We'll post another blog entry when we're more stable - but for
        now we *might* be unstable. And maintenence mode might be coming tomorrow - in which case we're sorry but it
        needs to happen so that games work for everyone. Thank you SO MUCH! for your interest in Frantic Fanfic. We're
        two people with a dream to make people laugh and have fun with friends. If you're not having fun - neither are
        we LOL. </BodyText>

    <BodyText>EDIT: We just finished trying to restore some games for players but unfortunately due to the quick fixes
        implemented tonight because of the high amount of traffic it appears that some games that were in progress
        between 12:00am EST and 12:30am EST (at the time of the fix upload) have become corrupted and the stories are
        unrecoverable. We are very sorry
        for this. Again, thousands of people have accessed the site this evening and we were unprepared to handle the
        sheer amount of you all playing at once. In the future, we will make it clear when the website needs to be under
        maintenance and block people from creating games when the website is suspected to be unstable.</BodyText>

    <BodyText>We hope that the players that were affected by this bug will be willing to give Frantic Fanfic another
        shot once
        we have all these growing pains sorted out. Thank you for playing today!</BodyText>

    <BodyText>Zee and Michael</BodyText>

</Body>

export let blog = {
    title: blog12Title,
    author: blog12Author,
    content: blog12Content
}