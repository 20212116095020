import React from "react";
import "./styles.css"
import {useAppSelector} from "../../../../redux/store";
import GamePage from "../../game-page/game-page";
import {selectIsSolo} from "../../../../redux/selectors";

type Props = {
    onBack: () => void
}


let soloStats = new Set(['total_words', 'average_part_length'])

let labels = new Map([
    ['total_words', 'total words written'],
    ['average_part_length', 'average part length'],
    ['top_character_writer', 'writer with most chosen characters'],
    ['bottom_character_writer', 'writer with the least chosen characters (womp waaa)'],
    ['most_reacted', 'most reacted writer'],
    ['most_reactor', 'writer who likes clicking the react button the most'],
    ['least_reactor', 'most stingy player'],
    ['most_loved', 'most loved writer'],
    ['most_words', 'writer with the most to say'],
    ['least_words', 'writer who got to the point']
]);

let audience_labels = new Map([
    ['audience_count', 'audience members'],
    ['audience_reacts', 'audience total reacts']
])

let counts = new Map([
    ['top_character_writer', 'top_character_count'],
    ['bottom_character_writer', 'bottom_character_count'],
    ['most_reacted', 'most_reacted_count'],
    ['most_reactor', 'most_reactor_count'],
    ['least_reactor', 'least_reactor_count'],
])

const StoryStats = ({onBack}: Props) => {

    const gameState = useAppSelector(state => state.game)

    const isSolo = useAppSelector(selectIsSolo)

    const stats = gameState.game?.game_stats


    const metaMap = gameState.game?.game_stats?.meta ? new Map(Object.entries(gameState.game?.game_stats?.meta)) : new Map()

    const audienceMetaMap = gameState.game?.game_stats?.audience_stats ? new Map(Object.entries(gameState.game?.game_stats?.audience_stats)) : new Map()


    function handleBack() {
        onBack()
    }


    return <GamePage onBack={handleBack} titles={["GAME STATS"]}>
        {stats ?
            <div className={"centered-story-results2-stats"}>
                <div className={"story-stats2-container2"}>
                    <div className={"story-results2-row2"}>
                        <div className={"story-results2-header story-stats-label-column"}>Category</div>
                        <div className={"story-results2-header story-stats-result-column"}>Result</div>
                    </div>

                    {Object.keys(stats.meta).filter(v => (!isSolo && labels.has(v)) || (isSolo && soloStats.has(v))).map(v => {

                        return <div key={"stats-" + v} className={"story-stats-row"}>
                            <div className={"story-stats-label-column"}>
                                {labels.get(v)}
                            </div>
                            <div className={"story-stats-result-column"}>
                                {metaMap.get(v)} {counts.has(v) ? ' - ' + metaMap.get(counts.get(v)) : ''}
                            </div>

                        </div>
                    })}


                </div>
                {!isSolo ?
                    <><br/>
                        <div className={"story-stats2-container2"}>

                            <div className={"story-results2-row2"}>
                                <div className={"story-results2-header story-stats-label-column"}>All character
                                    submissions
                                </div>
                                <div className={"story-results2-header story-stats-result-column"}>Player</div>
                            </div>
                            {Object.entries(stats.meta.selected).map(v =>
                                <div key={"stats-" + v} className={"story-stats-row"}>
                                    <div className={"story-stats-label-column"}>
                                        {v[1].join(', ')}
                                    </div>
                                    <div className={"story-stats-result-column"}>
                                        {v[0]}
                                    </div>

                                </div>)}
                        </div>
                    </> : ''}


                {!isSolo ? <>
                    <br/>
                    <div className={"story-stats2-container2"}>

                        <div className={"story-results2-row2"}>
                            <div className={"story-results2-header story-stats-label-column"}>Audience Stats
                            </div>
                            <div className={"story-results2-header story-stats-result-column"}>Result</div>
                        </div>
                        {Object.keys(stats.audience_stats).filter(v => audience_labels.has(v)).map(v =>
                            <div key={"stats-" + v} className={"story-stats-row"}>
                                <div className={"story-stats-label-column"}>
                                    {audience_labels.get(v)}
                                </div>
                                <div className={"story-stats-result-column"}>
                                    {audienceMetaMap.get(v)} {counts.has(v) ? ' - ' + audienceMetaMap.get(counts.get(v)) : ''}
                                </div>

                            </div>)}
                    </div>
                </> : ''}
            </div>
            : ''}

    </GamePage>
}

export default StoryStats