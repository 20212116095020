import React, {useState} from "react";
import {StorySubmission} from "../../../types";
import Waitable from "../../common/waitable/waitable";
import {GameStatus} from "../game";
import {useAppSelector} from "../../../redux/store";
import GameFormPage from "../game-page/game-form-page";
import {PrevSubmission2} from "../../common/prev-submission/prev-submission";


const StoryCensor = () => {

    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)
    let flatMap: StorySubmission[] | undefined = gameState.game?.stories?.flatMap(s => s.submissions).filter(s => s.meta && s.meta.flagged && s.meta.flagged.length > 0);

    const [censored, setCensored] = useState(new Set<number>())

    function handleToggle(checked: boolean, id: number) {
        setCensored(prevState => {
            if (checked) {
                prevState.add(id)
            } else {
                prevState.delete(id)
            }
            return new Set(prevState)
        })
    }

    function gameSubmit() {

        return {
            body: {
                vip_action: {
                    censor_submission: Array.from(censored),
                }
            },
            code: "submission/censor"
        }
    }


    return <Waitable playerActionHandler={() => {
    }} waitingFor={[GameStatus.GameStatusPostCensoring]} waitHeader={"Waiting for censoring"}>
        <GameFormPage
            titles={["Moderation screen"]}
            subHeaders={["Choose to censor or keep parts other players have flagged.", "Wait until other players have finished their titles."]}
            gameSubmit={gameSubmit}>
            <div className={"prev-story divide-y divide-gray-200"}>
                {flatMap && flatMap.length > 0 ?
                    flatMap?.map<React.ReactNode>(s => <PrevSubmission2 key={"flagsubm-" + s.id}
                                                                        canFlag={playerState.player?.id === gameState.game?.host_id}
                                                                        playerId={playerState.player?.id as number}
                                                                        onToggle={(b) => handleToggle(b, s.id)}
                                                                        submission={s}/>)
                    : ''}
            </div>
        </GameFormPage>
    </Waitable>

}

export default StoryCensor