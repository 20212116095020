import {useTranslation} from "react-i18next"


type Props = {
    onBack: () => void
}

const BackButton = ({onBack}: Props) => {

    const {t} = useTranslation('common')

    function handleClick() {
        onBack()
    }

    return <button className={"text-xl self-stretch basis-24 common-shadow"}
                   onClick={handleClick}>{t('button.back')}</button>

}

export default BackButton