import React from "react";
import {quickStartList} from './quick-start'
import {body as support} from './supportus'
import StyledHeader from "../components/common/styled-header/styled-header";
import BodyTitle from "../components/common/body-title/body-title";
import BodyText from "../components/common/body-text/body-text";
import Body from "../components/common/body/body";
import BodyList, {BodyListItem} from "../components/common/body-list/body-list";


export const body = {
    about: <Body>

        <StyledHeader header={"IT'S FANFIC WITH FRIENDS!"}
                      subHeader={"A creative writing party game where friends furiously write each other’s\n" +
                          "            fanfiction."}/>
        {/*<u><i>IT'S FANFIC WITH FRIENDS!</i> </u>*/}

        {/*<BodyText>A brand spankin’ new creative writing party game where friends furiously write each other’s*/}
        {/*    fanfiction:*/}
        {/*    Frantic Fanfic.</BodyText>*/}

        <BodyText>This game takes inspiration from the classic “pass the story” with a nerdy pop-culture twist.
            Frantic Fanfic is a fandom-frenzied supercharged mess of exposition, rising action, falling action,
            resolution
            and everything in-between!</BodyText>

        <BodyTitle>DOESN'T THAT SOUND LIKE FUN</BodyTitle>


        <BodyText>Here's some testimonials if that didn't already convince you!! These are from our game testers and are
            totally not made up:</BodyText>
        <BodyText>
            <BodyText>"This is incredible"</BodyText>

            <BodyText>"This is exactly the kind of game you'd want to play at 9pm at a con party..."</BodyText>

            <BodyText>"This is someone's favourite game and they just don't know it yet... And that someone might be
                me??"</BodyText>
        </BodyText>


        <BodyTitle><span className={"uppercase"}>that's great but, Why is this a thing?</span></BodyTitle>

        <BodyText>Frantic Fanfic is the child of @zeekayart and @michaelangsten. We made this game because we were
            looking for
            a
            project to do together that would test our skills. Zee loved fanfiction as a teenager and Michael wanted a
            coding project to work on that could help him learn about some of the things that were "magic" to him
            before.
            After working through everything he became a magic master sorcerer man. Now we have a cool website that we
            can show our friends and be like "Look at this silly thing we made, isn't it silly?!" and they'll be like
            "Yeah
            that
            sure is silly".</BodyText>
        <BodyText>The real origin story is the pandemic lockdown happened around March 13th 2020. And Zee got really sad
            because
            she couldn't see her friends in person anymore. So she took the fun party idea "5 minute presentations" and
            tweaked it a bit. Instead of everyone coming to the party with a presentation already made, they all had one
            hour to make their presentation. Also, they had to submit random topics and those would be distributed to
            other
            people, from which they had to choose one to make a presentation on. And that party ran and it was
            fun.</BodyText>

        <BodyText>But the next time it ran, someone wanted to take the randomness even further, so the person that wrote
            the
            presentation wouldn't even be presenting it!! It would be someone else entirely!! Woah!! Very random. Anyway
            that was fun too. And then it ran for a third time. Also fun.</BodyText>
        <BodyText>But Zee wanted to change it up a bit. So she came up with an idea to use writing and characters
            instead of
            presentations on random things... Frantic Fanfic. And it was very bare-bones, using google docs and a
            spreadsheet to shuffle the characters and the stories, using a phone as a timer between parts. But it
            worked!
            And people loved it! So Zee was content doing that. And she was ready to run another one with the same
            setup.
            But then in the back of her head, late december 2020, she was like... Holy crapoli. This could be a website.
            This could be a thing for... Everyone!!</BodyText>
        <BodyText>Zee furiously outlined each page of the website/app on her large sheets of newsprint that she used for
            life
            drawing. Michael took a look at the sheets. He said it could work. They could definitely do this. And so,
            they
            did.</BodyText>
        <BodyText>Frantic Fanfic arose from a desire to have friends laughing and being creative together. There are so
            many
            games
            out there that rely on the game itself being funny. Now with frantic Fanfic, only your imagination is the
            limit!
            Oh, and the time limits too... Sometimes the best thing for writers block is some arbitrary tick tok of a
            clock.
            Sometimes.</BodyText>

        <BodyText>Please, enjoy this game with the people that are precious to you. We certainly have.</BodyText>

        <BodyText>- Zee and Michael</BodyText>

        {support.content}


    </Body>,


    // ABOUT ABOVE.    HOWTOPLAY BELOW.


    howToPlay: (
        <Body>
            <StyledHeader header={"STREAMER GUIDE"}
                          subHeader={<div style={{textAlign: "center"}}>&gt;&gt;&gt;<a target="_blank"
                                                                                       rel={"noreferrer"}
                                                                                       href="https://twitter.com/franticfanfic/status/1394519874538508288">GUIDE
                              FOR STREAMERS + CUSTOM LAYOUT ASSET</a>&lt;&lt;&lt;</div>}/>

            <StyledHeader header={"Quick start guide"}
                          subHeader={<p>Frantic Fanfic is a round-robin style story game that is like exquisite corpse
                              but for
                              fanfiction.</p>
                          }/>

            <div className={"quick-start-ol text-base leading-normal"}>
                {quickStartList(undefined, true)}
            </div>

            <StyledHeader header={"FREQUENTLY ASKED QUESTIONS"} subHeader={"Use ctrl+f to find an answer."}/>
            {/*<u><b> <i> FREQUENTLY ASKED QUESTIONS</i></b></u>*/}

            <BodyList>
                <BodyListItem>Q: How do I join a game after it starts?!</BodyListItem>

                <BodyListItem>A: You can't, the only way to join is as an audience member. You can vote but not
                    write.</BodyListItem>
            </BodyList>
            <BodyList>
                <BodyListItem>Q: How do I start the game, the book isn't lighting up!</BodyListItem>

                <BodyListItem>A: You need at least two people to play the multiplayer modes, and you need three players
                    total to play a game
                    where
                    someone actually "wins" the game. Two player voting doesn't work. To start the game you need to
                    click the book that's flashing with the appropriate amount of players in the lobby.
                </BodyListItem>
            </BodyList>
            <BodyList>
                <BodyListItem>Q: How do I access the super secret character!</BodyListItem>

                <BodyListItem>A: Buy our zine and head to the character bio pages! It's on the last page, that is the
                    code you
                    need to use. Or if you purchase supporter, the character is unlocked for you. You cannot unlock the
                    secret character via the code on mobile, it is desktop only.
                </BodyListItem>
            </BodyList>
            <BodyList>
                <BodyListItem>Q: Why aren't you putting out updates every week!</BodyListItem>

                <BodyListItem>A: We made this game in our spare time and it is not our full time job. Everything you see
                    here is done after work hours.
                </BodyListItem>
            </BodyList>

            <BodyText>That's all you need to know! The game will prompt you on what to do as you go,
                but here's the step-by-step guide for people who like
                to read:</BodyText>
            <br/>
            <StyledHeader header={"HOW TO PLAY FRANTIC FANFIC"} subHeader={"The long version."}/>
            {/*<p><u><b>HOW TO PLAY FRANTIC FANFIC: THE LONG VERSION</b> </u></p>*/}

            <BodyTitle>GETTING STARTED!</BodyTitle>
            <BodyList>
                <BodyListItem>Input your name, choose an avatar, verify your
                    age.</BodyListItem>
                <br/>
                <BodyListItem>Hit [start].</BodyListItem>
                <br/>
                <BodyListItem>Hit [create game] and input the settings for your game.</BodyListItem>
                <br/>
                <BodyListItem>If you are not the VIP, hit [join game] and enter the game code.</BodyListItem>
                <br/>
                <BodyListItem>Want to be part of the audience? Hit [join audience].</BodyListItem>
                <br/>
                <BodyListItem>Wait in the lobby for everyone to show up.
                    <br/>
                </BodyListItem>
                <br/>
                <BodyListItem>The VIP needs to click the flashing book [the sacred tome of fanfiction] to
                    begin! Players are locked in and cannot be added after this. </BodyListItem>

            </BodyList>
            <BodyTitle> CHARACTER POOL SUBMISSION </BodyTitle>
            <BodyList>
                <BodyListItem>Write characters. These are submitted to the character pool.
                    <br/>
                    Note: if there is a same spelling duplicate, this will be blocked and you will need to
                    choose another character.
                </BodyListItem>
                <br/>
                <BodyListItem>Hit [submit] when you’re happy with the characters you wrote.
                </BodyListItem>
                <br/>
                <BodyListItem>Characters are shuffled.
                    <br/>
                </BodyListItem>
            </BodyList>
            <br/>
            <BodyTitle>FANFIC SETUP!</BodyTitle>
            <ul>
                <li>Pick characters to write about in your fanfic from the selection.</li>
                <br/>
                <li>You will read their story when writing is complete!
                </li>
                <br/>
                <li>Set the maturity rating so fellow writers know how far you are comfortable with taking this
                    fanfic. This will display in the header as [18+] for adult language or [all ages] for a tamer
                    experience. If you are under 18 years old, you CANNOT be in a room with stories rated 18+.
                </li>
                <br/>
                <li>Hit [select!] when you are happy with your choices.</li>
                <br/>
                <li>When everyone has selected, the writing phase starts!</li>
            </ul>
            <br/>
            <BodyTitle>STORY START!</BodyTitle>
            <ul>
                <li>Begin writing! The header shows the story status.
                </li>
                <br/>
                <li>"FANFIC #:" corresponds to the order in which the player joined the lobby. If you joined second,
                    the story you started would be "FANFIC #: 2".
                </li>
                <br/>
                <li>The current part of the story you are on and maturity ratings are also displayed here.
                </li>
                <br/>
                <li> There’s a character limit to prevent bots attacking… but you probably won’t hit it. Probably.
                </li>
                <br/>
                <li>There's a time limit! The time limit varies from the setting the VIP picked. Lightning is the
                    speediest, Frantic still fast but not so fast, Brisk is a slower pace and Leisurely is the most
                    chill. Sonic is a challenge for only the speediest of writers.
                </li>
                <br/>
                <li>The timer ticks down in real time, so you know exactly how many seconds you have left.
                </li>
                <br/>
                <li>Stumped on where to take your story next? You can make use of the prompt button when clicked. This
                    button gives you a new fanfic trope or suggestion. This is available if optional prompts are chosen.
                </li>
            </ul>
            <br/>
            <BodyTitle>CONTINUE THAT FIC! </BodyTitle>
            <ul>
                <li>When timer has finished "PART 1", the game will save and prepare to
                    swap fanfics with other writers randomly.
                </li>
                <br/>
                <li>The fanfiction that you began will be passed on to a friend at the same time, another friend’s
                    story will be passed to you. You now need to write the next part of the new story.
                </li>
                <br/>
                <li>When the timer strikes zero, another save and quick intermission takes place.
                </li>
                <br/>
                <li>Depending on how many parts the VIP chose on the settings page, this section can continue switching.
                    In the basic game, there are only two switches and three parts written.
                </li>
            </ul>

            <br/>
            <BodyTitle>FINISH IT!</BodyTitle>
            <ul>
                <li> Write the ending to another randomly shuffled fanfic! Create the most satisfying ending you can
                    come up with to the story displayed on your screen.
                </li>
                <br/>
                <li>Tick tock!! Time is up! But this time, the stories are complete! A congratulations graphic will
                    play.
                    You did it! You finally did it!
                </li>
                <br/>
                <li>Here’s where you get the opportunity to read your full story to yourself (and see where the others
                    took it) and bequeath it with the most fitting title you can. You don't have to read the whole thing
                    at this point, you
                    can just title it whatever.
                </li>
                <br/>
                <li>Woah! You have a full fanfic with a slammin’ title! Time to read!
                </li>
            </ul>
            <br/>
            <BodyTitle> READING TIME!</BodyTitle>
            <ul>
                <li>Open up a chat app like smishcord™ or skmype™ (or... maybe you're playing in the same room??) and
                    take turns reading your beautiful fanfics to your
                    beautiful friends in the tone that fits your story the best.
                </li>
                <br/>
                <li>Now for an arbitrary voting system to see who “won” the fanfic battle based on some award
                    categories I pulled out of my butt... but really, you’re all winners. You’re all stars.
                </li>
            </ul>
            <br/>
            <BodyTitle>BUT SOME OF US ARE MORE WINNERS... THAN OTHERS.</BodyTitle>
            <ul>
                <li>Time to smash those react buttons! As you read the fanfics, you can react to the parts you liked the
                    best with as many emotes as you like. The more reacts you give a part, the more points that part
                    will
                    be awarded. To prevent cheesing the game, you do need to react at least once to other stories in
                    order to proceed.
                </li>
                <br/>
                <li>When the reading and reacting is done, we move on to the bonus round. One bonus vote category is
                    always the same [your personal favourite], and the other bonus vote is randomly generated from a
                    list of awards. Bonus award votes are worth points, but only the award winners will display on the
                    leaderboard.
                </li>
                <br/>
                <li>To keep things fair, you cannot react for the part you wrote or give bonus points to the story you
                    started.
                </li>
                <br/>
                <li>There are both fanfic winners and writer winners!
                </li>
                <br/>
                <li>After everyone has voted, you’ll be able to see the leaderboard and how well you did! But weren't
                    the real points the fanfics we made along the way?
                </li>
                <br/>

            </ul>

            <BodyTitle>POST GAME </BodyTitle>

            <ul>
                <li>On the voting results screen you can click on your fics and save them by downloading them as a text
                    file or image. We also have a Twitter auto-filler but you need to add the image yourself as we don't
                    have permission to do that. This export page is live for 24 hours, after that it's gone.

                </li>
                <br></br>
                <li>We added a [GAME STATS] button so you can check out some neat tidbits about the game you just
                    played.

                </li>
                <br/>
                <li>That's it!! Wanna play again?
                </li>
                <br/>
                <li>Oh yeah! Tell a friend about our game if you thought it was fun!!!
                </li>
                <br/>
                <li>Also, do you…</li>
                <br/>
                <ul>
                    <li>Know someone who wants to write about our game? Or you ARE someone who wants to write about our
                        game?
                    </li>
                    <br/>
                    <li>Want to tell us about a bug that keeps crashing your sessions?
                    </li>
                    <br/>
                    <li>Know how to make this game even better?</li>

                    <br/>
                    <li>Send us an email! franticfanficgame [at] gmail [dot] com.
                    </li>
                </ul>


            </ul>
        </Body>)
}

