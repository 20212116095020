import {useState} from "react";
import StoryResults from "./story-results";
import StoryStats from "./story-stats/story-stats";

const GameResults = () => {

    const [page, setPage] = useState(0)

    function handleGameStats() {
        setPage(1)
    }

    function handleBack() {
        setPage(0)
    }


    return page === 0 ?
        <StoryResults onGameStats={handleGameStats}/>

        : <StoryStats onBack={handleBack}/>


}

export default GameResults