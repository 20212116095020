import React, {FunctionComponent, useState} from "react";
import "./styles.css"
import {Story} from "../../../../types";
import {constants} from "../../../../constants";
import {BoxyNumbers} from "../../../common/boxy-numbers/boxy-numbers";

type Props = {
    selected: Map<number, boolean>
    setSelected: (id: number, selected: boolean) => void
    partNumber: number
    story?: Story
}

type PPP = {
    id: string
    label: string | JSX.Element
    setSelected: (selected: boolean) => void
    selected?: boolean
    disabled?: boolean
}

const emojis = constants.emojis//["🙏", "🌶️", "👀", "🧠", "💩", "🤪", "❤️", "🔥", "😂", "⭐"]


const BBB: FunctionComponent<PPP> = ({id, label, setSelected, selected, disabled}) => {

    const [toggled, setToggled] = useState<boolean>(false)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            setSelected(true)
            setToggled(true)
        } else {
            setSelected(false)
            setToggled(false)

        }
    }

    return <><input className={"vote-row-buttons-input"} disabled={disabled} onChange={handleChange} id={id}
                    checked={selected ?? false}
                    type="checkbox"/>
        <label htmlFor={id} className={label === "❤️" ? "bbb-label bbb-label-red" : "bbb-label"}>
            {label}
            <span className={toggled ? "bbb-label-fancy" : "bbb-label-nofancy"}
                  style={{position: "absolute"}}>{label}</span></label></>
}

const VoteRow = ({partNumber, selected, setSelected}: Props) => {

    // Part 1 VOTE
    return <div className={"vote-row-buttons"}>
        <div className={"vote-row-buttons-label"}>Part {partNumber} REACTS:</div>
        {emojis.map((v, idx) => <BBB key={"bbb-" + idx}
                                     selected={selected.get(idx)} setSelected={s => setSelected(idx, s)}
                                     id={partNumber + "-" + idx} label={v}/>)}

        ― <span style={{alignSelf: "stretch"}}/> <BoxyNumbers
        value={selected.size > 0 ? Array.from(selected.values()).map(v => v ? 100 as number : 0 as number).reduce((prev, cur) => prev + cur) : 0}
        length={3}/>
    </div>


}

export default VoteRow