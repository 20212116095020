import React from "react";
import BodyTitle from "../../components/common/body-title/body-title";
import BodyText from "../../components/common/body-text/body-text";
import Body from "../../components/common/body/body";
import BodyList, {BodyListItem} from "../../components/common/body-list/body-list";

export let blogTitle = "September 8th! IT'S BEEN ANOTHER MONTH!!"

export let blogAuthor = "Posted by Michael / Wednesday Sep 09 2021"

export let blogContent = <Body>
    <BodyTitle>THE BIG ONE!!!</BodyTitle>
    <BodyText>
        Public Lobbies!!!!!! Public Lobbies is here!! Oh my gosh!! We finally have public lobbies. We’re so
        excited for this update. It took us a month-ish because it’s hard and we were busy… y’know with summer
        stuff, life stuff. But now we have Public Lobbies. I feel like we say this every time but it’s our most
        requested feature, to play with people online that you don’t know… yet. Because sometimes you don’t want
        to ask your friends to play the game! And sometimes you just want to meet new people who are into the
        same or similar things as you. And that’s what we hope Public Lobbies will accomplish.</BodyText>

    {/*&nbsp;&nbsp;&nbsp;&nbsp;<u>*****To use public lobbies*****</u>*/}
    <BodyTitle>*****To use public lobbies*****</BodyTitle>
    <BodyText>Click on “START” and select “PUBLIC LOBBIES”. You will then see a list of all the Public Lobbies that
        are currently live. If you click on the Lobby Button you’ll be able to see the settings that the VIP
        selected and also a note from the VIP.</BodyText>

    <BodyText>Public Lobbies are different from private games in a few ways.</BodyText>

    <BodyList>
        <BodyListItem>They expire within 60 minutes of the game being created to avoid spamming.</BodyListItem>
        <br/>
        <BodyListItem>Moderation is locked in this mode, the VIP is always able to moderate the players.</BodyListItem>
        <br/>
        <BodyListItem>Public Lobbies include a short message from the creator of the game so they can specify any fandoms they
            are interested in link to private voice chat channels. Please treat all Frantic Fanfic players with
            respect in Public Lobbies and in private VC channels.
        </BodyListItem>
        <br/>
        <BodyListItem> The lobby can be named by the VIP any name they wish, but these fields are also under our word filter
            so
            please be nice :).
        </BodyListItem>
        <br/>
        <BodyListItem>Any players under 18 cannot join or see lobbies which are specified to be mature.</BodyListItem>
    </BodyList>


    <BodyText> If 60 minutes passes and no players join your lobby the game will close and you will need to make a new
        one.</BodyText>

    <BodyText> We’re really not sure how popular they’ll be! But if you do have a public lobby going and no one has
        joined it, you can definitely post in our Discord in the “Looking for Group” channel and hopefully find
        someone to fic with :). You do need to be 18+ to join our Discord, however!</BodyText>

    <BodyTitle>Bug Fixes and Small Changes… NOT AS EXCITING BUT STILL IMPORTANT!</BodyTitle>

    <BodyList>
        <BodyListItem>Michael fixed an issue with reconnecting to games. That’s fantastic. We love Michael. Thank you for
            doing that, Michael.
        </BodyListItem>
        <br/>
        <BodyListItem>Michael did some more IOS/Mac fixes. In the past it’s been pretty hard to troubleshoot for environments
            that we don’t personally own... but Michael has a Mac now so we’re able to test! Woohoo!
        </BodyListItem>
        <br/>
        <BodyListItem>This is actually really cute! If you’re streaming or playing in a big party and people don’t want to
            play but they want to be in the audience… The audience gets a little counter now during games at the top
            of the screen! Have someone join the audience to check it out :).
        </BodyListItem>
        <br/>
        <BodyListItem>This was more rare of an occurrence because usually players don’t leave the game once it’s started, but
            we fixed an issue relating to story number if players left before the game started. What happened in the
            previous version was if there were six players and one dropped out, if the dropout player was the fifth
            player the stories would be labelled as “one two three four six” instead of “one two three four five” as
            the game still recognized that player as the sixth player, not the fifth. We fixed that! No more
            confusion! Only HAPPINESS!
        </BodyListItem>
        <br/>
        <BodyListItem>We had a slight issue with the story image screenshots that really bugged Zee but now it’s fixed! If you
            noticed it, there was a weird line on the side and Michael was able to fix that with this release. Thank
            GOODNESS! Because Zee absolutely HATED that!!!
        </BodyListItem>
        <br/>
        <BodyListItem>There were also a bunch of miscellaneous bug fixes… but also we’re not perfect so if you have any bugs
            that you run into please tell us about them! Where you experienced the bug, what device you were using,
            what happened when you tried to do the thing that you wanted to do and what actually happened… Those are
            all super useful to us trying to figure out what the heck is wrong! If you just email us saying “It’s
            broken” that doesn’t really help LOL. But ya we’ll follow up anyway.
        </BodyListItem>
        <br/>


    </BodyList>

    <BodyTitle>Thanks</BodyTitle>

    <BodyText>As always we’re Zee and Michael and we have MERCH!!!!!!!!!!!!!! And a KO-FI!!!!!! If you want to support
        Frantic Fanfic they’re both really good options and we EXTREMELY REALLY APPRECIATE IT! Because running a
        website and doin all that stuff is lots of work and money and also we appreciate it. Did we mention we
        appreciate it? Links to the Ko-Fi and Square site below! Thank you!!!!!!!!!!!!!</BodyText>

    <BodyText><a href={"https://ko-fi.com/franticfanfic"}>ko-fi.com/franticfanfic</a></BodyText>
    <BodyText><a href={"https://franticfanfic.square.site"}>franticfanfic.square.site</a></BodyText>

    <BodyTitle>Interviews</BodyTitle>

    <BodyText>OH YEAH! We did two interview! Here are the links to them: Also, if you want to interview us, let us know! We
        love doin em!</BodyText>
    <BodyList>
        <BodyListItem><a href={"https://www.digitaltrends.com/gaming/frantic-fanfic-interview/"}>Digital trends</a></BodyListItem>
        <BodyListItem><a href={"https://theonetruepodcast.buzzsprout.com/"}>The One True Podcast</a></BodyListItem>
    </BodyList>

</Body>


export let blog = {
    title: blogTitle,
    author: blogAuthor,
    content: blogContent
}