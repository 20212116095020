import React, {useCallback, useRef, useState} from "react";
import moment from "moment";
import Waitable from "../../common/waitable/waitable";
import {useAppSelector} from "../../../redux/store";
import {GameStatus} from "../game";
import {useTranslation} from "react-i18next";
import useInterval from "../../common/use-interval/use-interval";
import StoryTransition from "./story-transition";
import StoryTime from "./story-time";


const tickInterval = 200;


export enum StoryState {
    TRANSITION,
    WRITING,
    SAVING
}

const Story = () => {
    const {t} = useTranslation('game')


    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)

    const player = playerState.player
    const submissionMeta = gameState.game?.submission

    const start = submissionMeta?.reading_start ?? Number.MAX_SAFE_INTEGER
    const end = player?.submission?.submission_end ?? Number.MAX_SAFE_INTEGER

    const timeToStart = useRef<number>(start - moment.now())
    const timeToEnd = useRef<number>(end - moment.now())

    const [storyState, setStoryState] = useState<StoryState>(StoryState.TRANSITION)


    function correctStoryState() {
        if (timeToEnd.current < 0) {
            return StoryState.SAVING
        } else if (timeToStart.current < 0) {
            return StoryState.WRITING
        } else {
            return StoryState.TRANSITION
        }
    }

    const handleStoryState = useCallback(() => {
        const now = moment.now()
        timeToStart.current = start - now
        timeToEnd.current = end - now

        setStoryState(correctStoryState())
    }, [end, start])

    useInterval(handleStoryState, tickInterval)


    return <Waitable waitingFor={[GameStatus.GameStatusPostStoryWrite]} waitHeader={t('storyTime.headers.waiting')}>
        {storyState === StoryState.TRANSITION ?
            <StoryTransition timeToStartRef={timeToStart}/> :
            // <StoryTransition timeToStartRef={timeToStart}/>}
            <StoryTime storyState={storyState} timeRemainingRef={timeToEnd}/>}
    </Waitable>


}
export default Story