import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Config, DefaultGameSettings, TwitchStreamer} from "../../types";

export interface MetaState {
    loading: boolean
    error?: string
    connected: boolean
    count?: number

    config?: Config
    streamers?: TwitchStreamer[]
    settings?: DefaultGameSettings

}

const initialMetaState: MetaState = {
    loading: false,
    connected: false,
    error: undefined
}

const metaSlice = createSlice({
    name: "metaSlice",
    initialState: initialMetaState,
    reducers: {
        updateError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },

        updateLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        updateCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload
        },
        updateConnected: (state, action: PayloadAction<boolean>) => {
            state.connected = action.payload
        },
        updateConfig: (state, action: PayloadAction<Config>) => {
            state.config = action.payload
        },
        updateStreamers: (state, action: PayloadAction<TwitchStreamer[]>) => {
            state.streamers = action.payload
        },

        updateDefaultGameSettings: (state, action: PayloadAction<DefaultGameSettings>) => {
            state.settings = action.payload
        }

    }
})

export const {
    updateConnected,
    updateError,
    updateLoading,
    updateConfig,
    updateStreamers,
    updateDefaultGameSettings,
    updateCount
} = metaSlice.actions
export const metaReducer = metaSlice.reducer
