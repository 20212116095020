import {privacy} from "../../../text/privacy";
import MenuPage from "../menu-page/menu-page";
import { useTranslation } from "react-i18next";


const Privacy = () => {

    const {t} = useTranslation('main', {keyPrefix: 'privacy'})

    return <MenuPage titles={[t('headers.default')]}>
        <div className={"m-4 normal-case text-base font-comic"}>
            {privacy}
        </div>
    </MenuPage>
}

export default Privacy