import React, { useEffect, useState } from "react";
import "./styles.css";

export type PickerOption<T> = {
    value: T;
    labels?: string[];
    isCustom?: boolean;
    customLabel?: string;
};

type Props<T> = {
    value: T;
    onSelect: (val: T) => void;
    options: PickerOption<T>[];
    customPlaceholder?: string;
    leftButton?: string;
    rightButton?: string;
};

type CustomOptionProps = {
    value: number;
    setValue: (val: number) => void;
    min?: number;
    max?: number;
    placeholder?: string;
    label?: string;
};

const CustomOption = ({ value, setValue, placeholder, label = "CUSTOM" }: CustomOptionProps) => {
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Remove non-numeric characters and leading zeros
        const cleanValue = e.target.value.replace(/[^0-9]/g, "").replace(/^0+(?=\d)/, "");
        let newValue = parseInt(cleanValue) || 0;

        setValue(newValue);
        e.target.value = newValue.toString();
    };

    return (
        <div className="flex-col">
            <label htmlFor="customValue">{label}</label>
            <input
                id="customValue"
                type="text"
                value={value}
                placeholder={placeholder || "Enter value..."}
                className="w-full text-center"
                onChange={handleInput}
            />
        </div>
    );
};

export const Picker = ({
                           value,
                           onSelect,
                           options,
                           customPlaceholder,
                           leftButton = '<',
                           rightButton = '>'
                       }: Props<number>) => {
    const [index, setIndex] = useState<number>(0);
    const [isCustomActive, setIsCustomActive] = useState(false);

    useEffect(() => {
        // Find the custom option index
        const customIndex = options.findIndex(opt => opt.isCustom);

        // Find matching non-custom option index
        const standardIndex = options.findIndex(opt => !opt.isCustom && opt.value === value);

        // Set index based on whether custom is active
        if (isCustomActive && customIndex !== -1) {
            setIndex(customIndex);
        } else if (standardIndex !== -1) {
            setIndex(standardIndex);
        } else if (customIndex !== -1) {
            // If no standard option matches and we have a custom option, activate it
            setIndex(customIndex);
            setIsCustomActive(true);
        } else {
            // Fallback to first option
            setIndex(0);
            setIsCustomActive(false);
        }
    }, [value, options, isCustomActive]);

    const handleNavigation = (direction: -1 | 1) => {
        const newIndex = (((index + direction) % options.length) + options.length) % options.length;
        setIsCustomActive(options[newIndex].isCustom ?? false);
        onSelect(options[newIndex].value);
    };

    const renderOption = (option: PickerOption<number>) => {
        if (option.isCustom) {
            return (
                <CustomOption
                    value={value}
                    setValue={(newValue) => {
                        setIsCustomActive(true);
                        onSelect(newValue);
                    }}
                    placeholder={customPlaceholder}
                    label={option.customLabel}
                />
            );
        }

        if (option.labels) {
            return option.labels.map(label => (
                <div key={`label-${label}`} className="w-full break-words">
                    {label}
                </div>
            ));
        }

        return <div className="w-full break-words">{value}</div>;
    };

    return (
        <div className="picker-container">
            <button
                className="picker-container-arrow"
                onClick={() => handleNavigation(-1)}
                disabled={options.length < 2}
                type="button"
                aria-label="Previous option"
            >
                {leftButton}
            </button>

            <div className="picker-container-label">
                {options[index] && renderOption(options[index])}
            </div>

            <button
                className="picker-container-arrow"
                onClick={() => handleNavigation(1)}
                disabled={options.length < 2}
                type="button"
                aria-label="Next option"
            >
                {rightButton}
            </button>
        </div>
    );
};

export default Picker;