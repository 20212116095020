import React from "react";
import StoryListCard from "./story-list-card";
import "./styles.css"
import {GameStatus} from "../../game";
import {useAppSelector} from "../../../../redux/store";
import GameFormPage from "../../game-page/game-form-page";
import {useTranslation} from "react-i18next";

type Props = {
    // onClick: (story: Story) => void
    onClick: (storyIndex: number) => void
    onVote: () => void
}


const StoryList = ({onClick, onVote}: Props) => {

    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)

    const {t} = useTranslation('game')

    function handleClick(storyIndex: number) {
        onClick(storyIndex)
    }

    function handleVote() {
        onVote()
    }

    function ready() {
        return playerState.player?.status === GameStatus.GameStatusVoting
    }


    function subheaders() {
        const lines = []
        if (playerState.player?.data.audience) {
            lines.push(t('storyList.subheaders.audience1'))
            lines.push(t('storyList.subheaders.audience2'))
            lines.push(t('storyList.subheaders.audience3'))
        } else {
            lines.push(t('storyList.subheaders.slam'))
            lines.push(t('storyList.subheaders.read'))
            lines.push(t('storyList.subheaders.proceed'))

        }

        lines.push(t('storyList.subheaders.accelerate'))

        return lines
    }

    return <GameFormPage ready={ready()}
                         readyFlash
                         countdownThreshold={120}
                         subHeaders={subheaders()}
                         titles={[t('storyList.headers.default')]}
                         submit={handleVote}
                         submitText={gameState.game?.players.length === 2 && !playerState.player?.data.audience ? t('storyList.footers.proceed') : t('storyList.footers.proceed')}>
        <div className={"story-list-container"}>
            {gameState.game?.stories?.map<React.ReactNode>((s, idx) => <StoryListCard
                key={"storylistcard-" + s.id}
                onClick={() => handleClick(idx)}
                story={s}/>)}

        </div>

    </GameFormPage>
}

export default StoryList