import React from "react";


import {useAppSelector} from "./redux/store";

import "./styles.css"

type Props = {
    hideConnected?: boolean
    leftLabel?: string
}
const AppBottom: React.FunctionComponent<Props> = props => {

    const connected = useAppSelector(state => state.meta.connected)

    return <div className={"hidden z-50 md:flex flex-row-reverse justify-between mx-4 h-5"}>

        {connected !== undefined ? <div
            className={props.hideConnected ? "app-bottom-label hidden" : "app-bottom-label"}>{connected ? 'player connected' : 'player disconnected'}</div> : ''}
        {props.leftLabel !== undefined ? <div
            className={"app-bottom-label"}>{props.leftLabel}</div> : ''}
    </div>

}

export default AppBottom