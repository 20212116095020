import React from "react";
import AppTop from "../../../app-top";
import AppBottom from "../../../app-bottom";

type Props = {
    roomCode?: string
    extraLabel?: string
    connected?: boolean
    leftLabel?: string

    hideConnected?: boolean
    children?: React.ReactNode
}


const PageContainer: React.FunctionComponent<Props> = props => {


    return <div className={"app"}>
        <AppTop roomCode={props.roomCode} extraLabel={props.extraLabel}/>
        <div className={"flex-grow relative flex flex-col"}>
            {props.children}
        </div>

        <AppBottom hideConnected={props.hideConnected} leftLabel={props.leftLabel}/>
    </div>
}

export default PageContainer