type Props = {
    children: React.ReactNode
    space?: number
}
const Body: React.FC<Props> = (props) => {
    let space = props.space ?? 4

    return <div className={`mx-4 space-y-${space} text-base leading-normal`}>{props.children}</div>
}

export default Body