import crown2 from '../../../images/crown2.png'

import React from "react";
import "./styles.css"
import {Player} from "../../../types";
import {GameStatus} from "../../game/game";
import {config} from "../../../constants";
import {useAppSelector} from "../../../redux/store";


type Props = {
    player?: Player
    closed?: boolean

    onClick: (playerId: number) => void
    checked: boolean
}


const AvatarLobby = ({player, closed, onClick, checked}: Props) => {

    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)


    function handleClick() {
        if (amHost() && player !== undefined) {
            onClick(player.id)
        }
    }

    function amHost() {
        return gameState.game?.host_id === playerState.player?.id
    }

    function isChecked() {
        return player !== undefined && checked
    }

    return <div className={"avatar-lobby-container"}>
        <div className={isChecked() ? "avatar-lobby avatar-lobby-checked" : "avatar-lobby"} onClick={handleClick}>
            {player && player.name ?
                <div className={player.data?.vip ? "avatar-lobby-square-vip" : "avatar-lobby-square"}>
                </div> : <div className={"avatar-lobby-square-transparent"}/>}
            <div className={"avatar-lobby-img-holder"}>
                {player && player.name ?
                    <img className={"avatar-lobby-img"}
                         src={config.avatarsSmall[player.meta ? player.meta.sprite_id : 0]}
                         alt={config.avatarsSmall[player.meta ? player.meta.sprite_id : 0]}/> : ''}
            </div>

            <div
                className={amHost() && player && player.id !== gameState.game?.host_id ? "avatar-lobby-clickable avatar-lobby-overlap" : "avatar-lobby-overlap"}>
                {(player && player.data?.vip && (player.status === GameStatus.GameStatusLobby)) &&
                    <img className={"h-1/2 w-auto"} style={{"transform": "translate(-1rem, -1rem)"}} src={crown2}
                         alt={crown2}/>}
            </div>


            {/*<div className={"avatar-lobby-name-holder"}>*/}
            {/*    {player && player.name ? <div*/}
            {/*            className={avatarName[player.data.connected ? 'connected' : 'disconnected']}>{player.name}</div> :*/}
            {/*        <div className={"avatar-lobby-name"}>{closed ? "CLOSED" : "OPEN"}</div>}*/}
            {/*</div>*/}

            <div className={"avatar-lobby-name-holder"}>
                {player && player.name ?
                    <div className={`avatar-lobby-name`}><span>{player.name}</span>{player.data.premium &&
                        <span className={"text-xs"}>&nbsp;⭐</span>}</div> :
                    <div className={"avatar-lobby-name"}>{closed ? "CLOSED" : "OPEN"}</div>}
            </div>
        </div>


    </div>
}

export default AvatarLobby