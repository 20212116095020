import React from "react";
import BodyTitle from "../../components/common/body-title/body-title";
import BodyText from "../../components/common/body-text/body-text";
import BodyList, {BodyListItem} from "../../components/common/body-list/body-list";
import Body from "../../components/common/body/body";

export let blogTitle = "Happy New Year"

export let blogAuthor = "Posted by Zee / Sunday January 9 2022"

export let blogContent = <Body>
    <BodyTitle>ACCOUNTS</BodyTitle>
    <BodyText>
        You can login to Frantic Fanfic with Twitter/Discord now.
    </BodyText>

    <BodyText>A few reasons for this:</BodyText>

    <BodyList>
        <BodyListItem>We needed a way to give supporters of the game a secure way to access additional
            features.</BodyListItem>
        <BodyListItem>We wanted to give users the ability to track how many stories they’ve written and maybe other fun
            stats later.</BodyListItem>
        <BodyListItem>We want to be able to prevent trolling in public lobbies in the future.</BodyListItem>
    </BodyList>

    <BodyText>With logging in you can rank up as you write! There’s 6 ranks in total and the more stories you're a part
        of, the
        higher
        your rank goes. </BodyText>

    <BodyTitle>GIVING COOL STUFF TO SUPPORTERS OF FRANTIC FANFIC</BodyTitle>


    <BodyText>Have we told y’all how much we love people who support our game? Because this update is mostly for them.
        Yes,
        Frantic
        Fanfic is still free to play! But the people who kicked us some bucks to our Ko-fi or bought a zine or some
        merch…
        YEAH!
        This update is 90% about you! You didn’t have to. But you did. And we really appreciate that. Here’s how you get
        the cool stuff!</BodyText>
    <BodyText bold>Upgrade your account to "Supporter" status if you supported us in 2021:</BodyText>

    <BodyList ordered>
        <BodyListItem>Login to franticfanfic.com with your Discord or Twitter.</BodyListItem>
        <BodyListItem>Send an email to franticfanficgame@gmail.com with the title "Supporter Account", using the email
            address
            that you sent a Ko-fi with/bought merch with and
            include:</BodyListItem>
        <BodyList>
            <BodyListItem>The Discord name or Twitter name you signed into the site with.</BodyListItem>
            <BodyListItem>How you supported (ko-fi, merch, zine) so we can cross-check!</BodyListItem>
        </BodyList>
    </BodyList>
    <BodyText>We’ll set your account to the Supporter status as fast as we can :)</BodyText>
    <BodyText><b>To upgrade to Supporter status in 2022:</b> Log in on the "Profile" page. Navigate to the "Become a
        Supporter"
        page. Click on the “Become a Supporter” button. Pay $4.99 CDN. Refresh the page! Your account
        will now have Supporter status and all the additional features.</BodyText>
    <br/>
    <BodyText>So here’s the cool stuff!</BodyText>


    <BodyTitle>★PROMPT PACKS★</BodyTitle>
    <BodyText>Supporters of Frantic Fanfic can use all the prompt packs! We came up with hundreds of new prompts and
        organized them by category so you can add or subtract categories at will. We also added a bunch to the basic
        prompt pack with this
        update too, there's over 400 basic prompts now! So everybody gets something.
    </BodyText>

    <BodyTitle>THEMES</BodyTitle>
    <BodyText>People said they wanted more site themes because the bubblegum pink colour hurt their eyes.
        Understandable! The first theme (noir) is free. Winter is Michael’s favourite. I
        like the 90s theme but my eyes are already fried hahaha.</BodyText>

    <BodyTitle>ZINE</BodyTitle>
    <BodyText>Digital version of all 82 pages of the zine on site! A record of why and how
        this
        game came to be with some cute comics and supporting art from us! BTW you can still buy the physical copy of the
        zine
        if
        you want, it’s signed by both of us!</BodyText>

    <BodyTitle>AVATARS</BodyTitle>
    <BodyText>The special avatar PLAT is unlocked for supporters and more alternate costume avatars will be rolled out
        with the
        next updates. Zee is doing a series of 5 or 6 avatars inspired by shoujo manga tropes that she hopes y’all
        will
        like! They’ll be available as they’re finished up.</BodyText>


    <BodyTitle>NO ADS</BodyTitle>
    <BodyText>An ad-free experience for the signed-in supporter user. </BodyText>


    <BodyTitle>OTHER CHANGES - FORCED PROMPTS</BodyTitle>

    <BodyText>We added forced/optional prompts as a setting.
        Forced prompts displays a word or phrase that you have to incorporate in your part or overall story. If forced
        is selected, it gets rid of the "NEED A PROMPT?" optional prompt button. This was previously only available in
        solo play but we've brought it to the party modes!</BodyText>

    <BodyTitle>OTHER CHANGES - EASIER READING</BodyTitle>
    <BodyText>We tried to make things easier to read with this update. Specifically the Settings screen which I was
        having a
        hard
        time looking at and the Game Mode screen. We're also experimenting with Comic Sans replacing FixedSys in areas
        with a lot of text. Hope it helps!</BodyText>

    <BodyTitle>EMAILS ABOUT GAME CHANGES</BodyTitle>
    <BodyText> We’ve gotten a few emails about how to update the game for the better!
        We LOVE all the feedback we get. And it’s super valuable!
        But please do keep in mind we’re two people working on this game in our spare time and when we work to update
        the
        game we have an upside down pyramid of work.</BodyText>
    <BodyText>
        <BodyText>A Rank: Is everything working correctly? NO? Fix it fix it fix it fix it!!!</BodyText>
        <BodyText>B Rank: Are we updating something that doesn’t make sense currently?</BodyText>
        <BodyText>C Rank: Are we adding features / play modes that we deem are important and are currently on our
            roadmap?</BodyText>
        <BodyText>D Rank: Everything else.</BodyText>
    </BodyText>

    <BodyText>We spend a lot of time (more than people would guess) in those top two ranks. iOS 15… that threw a big ol’
        wrench
        in our Christmas holiday plans
        BUT we do have a lot of new things we want to do with the game still. So for example, an idea to put italics and
        bolding in
        the
        writing section is AWESOME, and we would love to do that, but we scoped it out and it would take a lot of time
        that
        we have currently allotted to other things that we want to do, like making a brand new game mode about original
        character creation.</BodyText>
    <BodyText>So yes we want to do it, no we’re not sure if or when we can get to it. Sometimes we get excited about
        things and
        say
        “omg
        yesss we gotta do that” and then it’s much harder than expected or other things take up way more time. Then
        there’s
        also dinner and taking the dog out… y’know, life stuff. BUT the more feedback we get, the more weight we have to
        those specific things. So don’t stop sending them in! (...Other than fully translating the game because we have
        no
        plans
        to do that within the next few years unless we're suddenly millionaires due to how much the game changes and we
        don’t know different languages ourselves so it would be
        really,
        really hard to do.)</BodyText>

    <BodyTitle>ALSO… WE'RE WORKING ON IT</BodyTitle>
    <BodyText>
        <BodyText>We’re going to update public lobbies to be better. We’re not sure exactly how yet, but it’ll be better
            in the
            future. It’s high on our priority list! Drop us a line on our Discord if you have ideas because we’re still
            determining the best way to do it.</BodyText>

        <BodyText>We’re working on a feature where you can save stories to your account but it’s not fleshed out yet.
            Unsure if
            we’ll
            release it but it’s a cool idea.</BodyText>
    </BodyText>

    <BodyTitle>AND… WE DECIDED TO NOT CHANGE THE REACT STRUCTURE YET</BodyTitle>
    <BodyText>We’re trying to make the react section more balanced but can’t figure out a way to make voting for other
        stories
        more desirable. The main problem is if someone is aiming to win, they’ll vote as little as possible. We had the
        idea
        to balance voting based on points given to one player (say they have 10,000 points total to give away) and they
        only
        clicked 10 times, each vote would be worth 1000 points. How reacts work right now is each click of a react is
        100
        points and each part can have 700 total. This seems well and good but we’re having some difficulty explaining
        that
        and displaying it in a super simple way that isn't a pie chart. And also, if someone was going to cheese the
        system here they could just give each player the
        same amount of points nullifying their points given. We don’t want to commit to change anything until we know
        for
        sure it’s worth changing.</BodyText>
    <BodyText>This game really isn’t about the points, it’s about the silly things you write! But we do recognize
        that
        THE BEST STORY should win. If you have any ideas about this, we’re all ears! We want to steer away from any
        “negative” point structure or dislikes as that’s jerky and not fun. But yeah, the best way to determine if the
        best
        story wins! That’s what we’re looking for.</BodyText>

    <BodyTitle>BUG FIXES</BodyTitle>
    <BodyText>Michael was hard at work fixing a bunch of bugs over the break. In particular he fixed an annoying one
        where if
        you
        were kicked from the game (due to being disconnected before the game started for any reason) it didn’t show that
        and
        it just showed negative time like you were in the shadow realm or something. Nah he fixed that. And the whole
        iOS
        thing. And cutting off words in the middle of the title. Good job Michael!</BodyText>

    <BodyTitle>THAT’S ALL</BodyTitle>
    <BodyText>
        <BodyText>Woah, big update! Hope y’all have a great 2022. Email us and let us know if there are any bugs as
            always, with a
            huge update like this there's bound to be something that slips through. </BodyText>

        <BodyText>Zee and Michael</BodyText>
    </BodyText>


</Body>

export let blog = {
    title: blogTitle,
    author: blogAuthor,
    content: blogContent
}