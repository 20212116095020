import React from "react";
import {Story} from "../../../types";
import "./styles.css"
import AvatarHeader from "../avatar-header/avatar-header";
import {SafeJoin} from "../../../util";
import {PrevSubmissionRead} from "../prev-submission/prev-submission";

type Props = {
    story: Story
    multiPrompt?: boolean
}


const CanvasViewSolo = ({story, multiPrompt}: Props) => {


    const headerNoPlayer = <AvatarHeader tall
                                         titlesJsx={[
                                             <span
                                                 className={"font-bold underline"}>{story.meta.title}</span>, <>characters: {SafeJoin(story.characters?.filter(c => c.selected).map<React.ReactNode>(c =>
                                                 <i key={"char-" + c.id}>{c.name}</i>), ', ')} </>]}/>


    return <div className={"canvas-view-container"}>
        <div id={"canvas-" + story.id} className={"canvas-view-book"}>
            {headerNoPlayer}
            <div className={"canvas-page-body"}>
                <div className={"canvas-story-view-container"}>
                    {story.submissions.map<React.ReactNode>((s, idx) => {
                        return <div key={"canvas-submission-" + s.id}>
                            <div className={"canvas-story-view-submission"}>
                                {/*{s.content}*/}
                                <PrevSubmissionRead submission={s} isCensored={s.meta.censored}/>
                                {multiPrompt || s.index === story.submissions.length - 1 ?
                                    <div className={"vote-row-buttons italic mt-2"}
                                    >prompt: {s.meta.prompt}</div> : ''}

                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>

}

export default CanvasViewSolo