import React, {useState} from "react";
import "./styles.css"
import {Story} from "../../../types";
import RoomAPI from "../../../RoomAPI";
import StoryView from "./story-view";
import Leaderboard from "./leaderboard";
import {useAppSelector} from "../../../redux/store";
import {selectIsSolo} from "../../../redux/selectors";
import SoloLeaderboard from "./solo-leaderboard";
import Page from "../../common/page/page";
import {useTranslation} from "react-i18next";


type ResultsProps = {
    onGameStats: () => void
}


export function storyToText(story: Story) {
    let text = ''

    if (story) {
        text += `TITLE: ${story.meta.title}\n`
        text += `FEATURING THE CHARACTERS: ${story.characters?.filter(c => c.selected).map(c => c.name).join(', ')}\n`
        text += `WRITERS: ${story.submissions.map(s => s.player.name).join(', ')}\n`
        text += `${story.settings.mature ? 'RATED 18+' : 'RATED ALL AGES'}\n`
        text += story.submissions.map(s => s.meta.censored ? '■TEXT REMOVED■' : s.content).join(`\n${'-'.repeat(20)}\n`) + '\n'
        text += '\n'
        text += "Fanfic created with #FranticFanfic."
    }

    return text

}

const StoryResults = ({onGameStats}: ResultsProps) => {

    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)
    let isSolo = useAppSelector(selectIsSolo);

    const [listView, setListView] = useState(-1)

    const stories = gameState.game?.stories?.slice().sort((a, b) => b.meta.points - a.meta.points)


    const {t} = useTranslation('game')

    // function handleTwitter(story: number, title: string, char1: string, char2: string) {
    //     const template2 = `https://twitter.com/intent/tweet?url=https%3A%2F%2Ffranticfanfic.com%2Fview%2F${value.shortcut}&text=Check%20out%20this%20%23FranticFanfic%20%27${title}%27%20featuring%20the%20characters%20${char1}%20and%20${char2}!`
    //     window.open(template2, "_blank")
    // }

    async function handlePermalink(story: Story) {
        // download("abc.txt", story.submissions.map(s => s.content).join('\n'))
        RoomAPI.ShareStory(story.id).then(value => {
            let url = `https://franticfanfic.com/view/${value.shortcut}`
            window.open(url, "_blank")
        })
    }


    function storyView(idx: number) {
        if (stories) {
            return <StoryView
                subheaders={[t('storyResults.subheaders.saveImg'), t('storyResults.subheaders.saveTxt'),
                    t('storyResults.subheaders.tweet'), t('storyResults.subheaders.attach')]}
                me={playerState.player} story={stories[idx]} onBack={() => setListView(-1)}
                onPermalink={handlePermalink} onShare={() => {
            }}/>
        } else {
            return <Page>Oh dear you broke it</Page>
        }
    }


    return listView === -1 ?
        (isSolo ? <SoloLeaderboard onGameStats={onGameStats}/> :
            <Leaderboard onGameStats={onGameStats} onStoryClick={(idx) => setListView(idx)}/>) : storyView(listView)
}

export default StoryResults