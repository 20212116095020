type Props = {
    text: string
    visible: boolean
    grow?: boolean
}


const StoryTransitionNumber: React.FC<Props> = (props) => {

    return <div
        className={`${props.grow && 'flex-grow'} text-center text-7xl bg-gray-drk shadow-basic px-8 py-4 my-1 ${props.visible ? 'visible' : 'invisible'}`}>
        {props.text}
    </div>
}

export default StoryTransitionNumber