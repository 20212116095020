import {body} from "../../../text/contact"
import MenuPage from "../menu-page/menu-page";
import {useTranslation} from "react-i18next";


const Contact = () => {

    const {t} = useTranslation('main')

    return (<MenuPage titles={[t('contact.headers.default')]}
                      subHeaders={[t('contact.subheaders.default')]}>
        <div className={"normal-case m-4 text-lg font-comic"}>
            {body.content}
        </div>
    </MenuPage>)
}

export default Contact