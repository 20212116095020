import React from "react";
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";
import BodyTitle from "../../components/common/body-title/body-title";
import BodyList, {BodyListItem} from "../../components/common/body-list/body-list";

export let blogXTitle = "Entry #7 - New modes/settings"

export let blogXAuthor = "Posted by Michael / Monday May 17 2021"

export let blogXContent = <Body>
    <BodyText>
        Hi all, after we wrapped up the last of the big backend changes last week we are now able to have some fun with
        adding some new
        settings, modes and features. As always we're happy to see everyone
        enjoying the game! Hopefully these new settings will add some spice to your gameplay! These are smaller updates
        to how the game is played, not as intense as what we're looking at for the future but we felt they would be
        pretty fun and are some of the top suggestions given to us.
    </BodyText>

    <BodyTitle>New features:</BodyTitle>
    <BodyList>
        <BodyListItem>NEW GAME MODE: OTP. This is the One True Pair edition of the game. If you don't want any character
            shuffling and just want to start writing about two specific
            characters that your friends just have to DEAL WITH, now you can! You choose two characters and that's it!
            No shuffle-o-matic involved. You just need to choose your maturity rating and away you go!
        </BodyListItem>
        <br/>

        <BodyListItem>NEW GAME MODE: Self Insert. You only need to come up with four characters in this mode. Your
            player name is automatically added and shuffled
            to the other players as your fifth character!
        </BodyListItem>
        <br/>

        <BodyListItem>NEW SETTING: Writers can now be hidden when reading and reacting to stories. If you're into that
            whole secrecy thing!!
        </BodyListItem>
        <br/>

        <BodyListItem>NEW SETTING: If you like smaller games, you can restrict the maximum number of players for your
            lobby to block additional people from joining!
        </BodyListItem>

    </BodyList>

    <BodyText>
        Please let us know at @franticfanfic on twitter if you have any suggestions for more new stuff!
    </BodyText>

    <BodyText>
        Thank you so much to our game testers, MajesticMaehem, Superrodan and stillvisions for helping us out on short
        notice!
    </BodyText>

    <BodyText>- Michael and Zee</BodyText>

</Body>

export let blog = {
    title: blogXTitle,
    author: blogXAuthor,
    content: blogXContent
}