import React from "react";
import Body from "../../components/common/body/body";
import BodyTitle from "../../components/common/body-title/body-title";
import BodyText from "../../components/common/body-text/body-text";

export let blogTitle = ""

export let blogAuthor = "Posted by Zee / Saturday April 13 2024"

export let blogContent = <Body space={0}>
    <BodyTitle>ENAMEL PINS HAPPENING! 2024</BodyTitle>
    <p>
        🪱 FINALLY!!! MAKING!!!
        <br/>
        🪱 ENAMEL PINS FOR FRANTIC FANFIC!!!
    </p>

    <BodyText>
        ---------------------------------------------------------
        <br/>
        ⭐ BUT which characters will become pins?!
        <br/>
        ⭐ that's for YOU to decide!!
        <br/>
        ⭐ <a href={"https://strawpoll.com/7rnzmje6xyO"}>VOTE HERE! </a>
    </BodyText>



    <BodyText>
        ---------------------------------------------------------
        <br/>
        ✅ YOU CAN ALSO PILE EXTRA VOTES ON OUR TIKTOK <a
        href={" https://www.tiktok.com/@franticfanfic/video/7357444357177314566"}>HERE!</a> </BodyText>
    <BodyText>✅ ONLY COMMENTS COUNT FROM UNIQUE ACCOUNTS, "LIKES" DON'T ADD MORE VOTES. </BodyText>
    <BodyText> ✅ SHARE THE VIDEO WITH FRIENDS (AND COMMENT!) TO GUARANTEE YOUR FAVE CHARACTER GETS PICKED AS A PIN!</BodyText>

    ---------------------------------------------------------

    <BodyText> 🍅 we are DEFINITELY making a wormman 🪱 pin and a buffpire pin, as those two characters are our unofficial
        mascots. but for the rest... PLEASE VOTE!! 🥰</BodyText>
    <BodyText>🍅 the character drawings will be altered and cleaned up slightly for the enamel pin process - but we want
        to keep the sketchy style as much as possible 🩷!</BodyText>
    <BodyText>🍅 we're looking to do 6 character pins for the kickstarter with the possibility of doing 10 total (using
        stretch goals!) + bonus stickers if it goes well!</BodyText>

    <br/>
    <BodyText> GOOD LUCK & thanks for playing!</BodyText>

    <BodyText> - zee and michael </BodyText>

</Body>

export let blog = {
    title: blogTitle,
    author: blogAuthor,
    content: blogContent
}