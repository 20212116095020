import React from "react";
import {useAppSelector} from "../../../redux/store";
import {useAuth0} from "@auth0/auth0-react";
import {User} from "../../../types";
import {useUser} from "../../menu/auth/is_loggedin";
import {useTranslation} from "react-i18next";
import Avatar6 from "../avatar6/avatar6";

function rank(user?: User) {
    if (!user) {
        return "n/a"
    }

    if (user.rank >= 250) {
        return "👑 S CLASS"
    } else if (user.rank >= 100) {
        return "⭐⭐⭐⭐⭐"
    } else if (user.rank >= 50) {
        return "⭐⭐⭐⭐"
    } else if (user.rank >= 25) {
        return "⭐⭐⭐"
    } else if (user.rank >= 10) {
        return "⭐⭐"
    } else {
        return "⭐"
    }
}


const AvatarProfile2: React.FunctionComponent = () => {

    let playerDetails = useAppSelector(state => state.menu.playerDetails);
    const {isAuthenticated, isLoading} = useAuth0()


    let user = useUser()


    const {t} = useTranslation('main')


    function rankName(user?: User) {
        if (!user) {
            return ""
        }
        if (user.rank >= 250) {
            return t('body.rank5')
        } else if (user.rank >= 100) {
            return t('body.rank4')
        } else if (user.rank >= 50) {
            return t('body.rank3')
        } else if (user.rank >= 25) {
            return t('body.rank2')
        } else if (user.rank >= 10) {
            return t('body.rank1')
        } else {
            return t('body.rank0')
        }
    }

    return <div
        className={`mx-2 flex flex-row relative z-10 mb-4 md:mx-12`}>
        <div className={"flex-grow z-30 flex justify-center items-center select-none"}>
            {(playerDetails || user !== undefined) && <Avatar6 spriteId={playerDetails?.meta.sprite_id ?? 0} name={playerDetails?.name} bigText/>}

            {(user !== undefined || isLoading || isAuthenticated) && <div className={"flex-grow"}/>}
        </div>

        {(user !== undefined || isLoading || isAuthenticated) &&

            <div className={"absolute h-full w-full flex flex-row justify-end"}>
                <div className={"absolute bg-gray-drk h-4/5 top-[20%] w-3/4 z-10 flex flex-col shadow-basic"}>
                    <div className={"self-stretch h-8 bg-black"}/>
                </div>

                <div className={"h-[192px] w-[192px]"}/>

                {user === undefined ? <div className={"flex-grow z-20 h-4/5 self-end flex flex-col space-y-2"}>
                        <div
                            className={"text-white bg-black self-start text-2xl h-8 middle flex justify-center items-center"}>{"Loading..."}</div>

                    </div> :
                    <div className={"flex-grow z-20 h-4/5 self-end flex flex-col space-y-2"}>
                        <div
                            className={"text-white bg-black self-start text-2xl h-8 middle flex justify-center items-center"}>{user.connection === 'twitter' ? '@' : ''}{user.name !== undefined ? user.name.substring(0, 16) : 'Guest'}</div>
                        <div className={"flex-grow flex flex-col justify-around"}>
                            <div
                                className={"text-white bg-black self-start text-base h-6 flex justify-center items-center px-2"}>{t('body.supporterStatus')}: {user.premium ? t('body.supporterActive') : t('body.supporterNone')}</div>
                            <div
                                className={"text-sm text-white bg-black self-start sm:text-base h-14 flex flex-col justify-around items-start px-2 "}>
                                <div className={"leading-none"}>rank: {rank(user)}</div>
                                <div className={"leading-none"}>{rankName(user)}</div>
                            </div>
                        </div>
                    </div>}
            </div>
        }
    </div>
}

export default AvatarProfile2