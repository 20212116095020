import React from 'react';
import ReactDOM from 'react-dom';
import './styles.css';
import './styles-mobile.css';
import './styles-tablet.css';

import reportWebVitals from './reportWebVitals';
import AppContainer from "./app-container";
import {store} from "./redux/store";
import {Provider} from 'react-redux'
import "./index.css"
import {BrowserRouter} from "react-router-dom";
import {Auth0Provider} from "@auth0/auth0-react";
import {config} from "./constants";
import './i18n';
import i18n from "./i18n";

declare global {
    interface Window {
        adsbygoogle: { [key: string]: unknown }[]
        aiptag: { [key: string]: unknown }[]
    }
}

// const domain = c;
// const clientId = ;
// const clientSecret = ;

// Modal.setAppElement('#root')

ReactDOM.render(<React.StrictMode>
        <Provider store={store}>
            <Auth0Provider domain={config.auth0Domain} clientId={config.auth0ClientId}
                           authorizationParams={{
                               redirect_uri: window.location.origin,
                               audience: "franticfanfic.com",
                               scope: "openid profile email"
                           }}
            >
                <BrowserRouter>
                    <AppContainer/>
                </BrowserRouter>
            </Auth0Provider>
        </Provider>

    </React.StrictMode>,
    document.getElementById('root')
);

i18n.loadLanguages('en')

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();