import React from "react";
import BodyTitle from "../../components/common/body-title/body-title";
import BodyText from "../../components/common/body-text/body-text";

export let blogTitle = "Be My Valentine... <3"

export let blogAuthor = "Posted by Zee / Feb 09 2022"

export let blogContent = <div className={"mx-7"}>
    <BodyTitle>SUPPORTER AVATARS</BodyTitle>

    <BodyText>
        We've given all supporters some alternate avatars! They're in greyscale as a tribute to Japanese manga. There's
        seven in total, with alternates for Buffpire, Ghost, Kitsune, Potato, Reapr, Skellybts and Wormman! We hope you
        enjoy them in addition to Plat, your prompt packs, themes, no ads on the site and the zine :&gt;.
    </BodyText>

    <BodyTitle>SITE CLEANUP</BodyTitle>
    
    <BodyText>
        We're still in the middle of some site cleanup, we want to make things easy to read. So we're doing some font
        changes across several pages.
    </BodyText>

    <BodyTitle>AUDIENCE FIX</BodyTitle>

    
    <BodyText>Viewers weren't able to join the audience past the lobby screen. We've made it so they can join at any time until
        the voting period! At that point the game locks because it would be unfair to join later on.</BodyText>

    <BodyTitle>CHARACTER MATURITY RATING</BodyTitle>

    <BodyText>On recommendation from a group of players playing Self Insert mode, we have added the option to specify that
        individual characters can be flagged with All Ages on the character writing screen.</BodyText>

    <BodyText>Example: If a player had their name added automatically with Self Insert but they were uncomfortable with being
        written about in mature situations (violence, harsh language, etc.) in a game marked as Mature by the VIP, they
        can
        use this option to guide other players in their writing.</BodyText>

    <BodyText>When the player selects "All Ages" for the character, (let's say the character's name is "Steve") that character
        is
        now bound to an All Ages maturity rating. If "Steve" was shuffled to another player and the player wanted to use
        "Steve", the story would be locked to "All Ages" automatically, no matter the maturity rating selected by the
        other
        characters in the story.</BodyText>

    <BodyText>This setting does not show up in All Ages games because by default all characters should be written at an All
        Ages
        level.</BodyText>

    <BodyTitle>BUG FIXES</BodyTitle>

    <BodyText> The forced prompts weren't visible on the reading screen. Oops! We've added those now. So now you can have a
        reason
        why your part was so messy. Of course. It was the prompt! It's always the prompt.</BodyText>

    <BodyText>Reacts weren't showing during stories. Fixed!</BodyText>

    <BodyText>People could react and vote for their own parts or stories. Again. Oh boy... Michael has fixed that again.</BodyText>

    <BodyText>The room code didn't stay hidden after hiding it, that's not great for streamers! We fixed it.</BodyText>

    <BodyTitle>THAT'S ALL FOR NOW</BodyTitle>

    <BodyText>Enjoy the latest update!! We've been working hard on a grant proposal so hopefully that all goes well. Don't
        forget
        to join the Discord if you're over 18 or follow us on TIKTOK for more updates :D!</BodyText>

    <BodyText>The Frantic Fanfic Team</BodyText>
</div>

export let blog = {
    title: blogTitle,
    author: blogAuthor,
    content: blogContent
}