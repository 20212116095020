import {useTranslation} from "react-i18next";
import {usePremium} from "../auth/is_loggedin";
import LinkButton from "../link-button/link-button";

const Supporter: React.FC = () => {
    const premium = usePremium()
    const {t} = useTranslation('main')

    return !premium ? <LinkButton buttonText={t('profile.body.supporterPurchase')} path={"/supporter"} small/>
        : <div className={"fake-button p-2 text-2xl"}>{t('profile.body.supporterThanks')}</div>
};

export default Supporter