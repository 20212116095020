import React, {useContext} from "react";
import {useAppSelector} from "../../../redux/store";
import FormPage from "../../common/formpage/formpage";
import AvatarHeader from "../../common/avatar-header/avatar-header";
import {Props} from "./menu-page";
import {MenuContext} from "../../../contexts";

type FormProps = Props & {
    submit: () => void
    ready?: boolean

    submitText?: string
    loadingText?: string
}

const MenuFormPage: React.FunctionComponent<FormProps> = props => {

    const menu = useContext(MenuContext)

    const playerDetails = useAppSelector(state => state.menu.playerDetails)

    const metaState = useAppSelector(state => state.meta)

    function handleSubmit() {
        if (props.submit) {
            props.submit()
        }
    }


    function handleBack() {
        menu.onBack()
    }


    return <FormPage
        header={<AvatarHeader hideAvatar={props.hideAvatar} titles={props.titles} titlesJsx={props.titlesJsx}
                              avatarName={playerDetails?.name}
                              avatarSpriteId={playerDetails?.meta.sprite_id}/>}

        subHeaderJsx={props.subHeaderJsx}
        subHeaders={props.subHeaders}

        loadingText={props.loadingText}
        submitText={props.submitText}
        onBack={handleBack}
        error={metaState.error}
        onSubmit={handleSubmit}
        connected={metaState.connected}
        hideConnected={true}
        ready={props.ready}
        loading={metaState.loading}>
        {props.children}
    </FormPage>

}

export default MenuFormPage