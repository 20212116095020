import MenuPage from "../menu-page/menu-page";


import final_00 from "../../../images/zine/final_00.gif"
import final_01 from "../../../images/zine/final_01.gif"
import final_02 from "../../../images/zine/final_02.gif"
import final_03 from "../../../images/zine/final_03.gif"
import final_04 from "../../../images/zine/final_04.gif"
import final_05 from "../../../images/zine/final_05.gif"
import final_06 from "../../../images/zine/final_06.gif"
import final_07 from "../../../images/zine/final_07.gif"
import final_08 from "../../../images/zine/final_08.gif"
import final_09 from "../../../images/zine/final_09.gif"
import final_10 from "../../../images/zine/final_10.gif"
import final_11 from "../../../images/zine/final_11.gif"
import final_12 from "../../../images/zine/final_12.gif"
import final_13 from "../../../images/zine/final_13.gif"
import final_14 from "../../../images/zine/final_14.gif"
import final_15 from "../../../images/zine/final_15.gif"
import final_16 from "../../../images/zine/final_16.gif"
import final_17 from "../../../images/zine/final_17.gif"
import final_18 from "../../../images/zine/final_18.gif"
import final_19 from "../../../images/zine/final_19.gif"
import final_20 from "../../../images/zine/final_20.gif"
import final_21 from "../../../images/zine/final_21.gif"
import final_22 from "../../../images/zine/final_22.gif"
import final_23 from "../../../images/zine/final_23.gif"
import final_24 from "../../../images/zine/final_24.gif"
import final_25 from "../../../images/zine/final_25.gif"
import final_26 from "../../../images/zine/final_26.gif"
import final_27 from "../../../images/zine/final_27.gif"
import final_28 from "../../../images/zine/final_28.gif"
import final_29 from "../../../images/zine/final_29.gif"
import final_30 from "../../../images/zine/final_30.gif"
import final_31 from "../../../images/zine/final_31.gif"
import final_32 from "../../../images/zine/final_32.gif"
import final_33 from "../../../images/zine/final_33.gif"
import final_34 from "../../../images/zine/final_34.gif"
import final_35 from "../../../images/zine/final_35.gif"
import final_36 from "../../../images/zine/final_36.gif"
import final_37 from "../../../images/zine/final_37.gif"
import final_38 from "../../../images/zine/final_38.gif"
import final_39 from "../../../images/zine/final_39.gif"
import final_40 from "../../../images/zine/final_40.gif"
import final_41 from "../../../images/zine/final_41.gif"
import final_42 from "../../../images/zine/final_42.gif"
import final_43 from "../../../images/zine/final_43.gif"
import final_44 from "../../../images/zine/final_44.gif"
import final_45 from "../../../images/zine/final_45.gif"
import final_46 from "../../../images/zine/final_46.gif"
import final_47 from "../../../images/zine/final_47.gif"
import final_48 from "../../../images/zine/final_48.gif"
import final_49 from "../../../images/zine/final_49.gif"
import final_50 from "../../../images/zine/final_50.gif"
import final_51 from "../../../images/zine/final_51.gif"
import final_52 from "../../../images/zine/final_52.gif"
import final_53 from "../../../images/zine/final_53.gif"
import final_54 from "../../../images/zine/final_54.gif"
import final_55 from "../../../images/zine/final_55.gif"
import final_56 from "../../../images/zine/final_56.gif"
import final_57 from "../../../images/zine/final_57.gif"
import final_58 from "../../../images/zine/final_58.gif"
import final_59 from "../../../images/zine/final_59.gif"
import final_60 from "../../../images/zine/final_60.gif"
import final_61 from "../../../images/zine/final_61.gif"
import final_62 from "../../../images/zine/final_62.gif"
import final_63 from "../../../images/zine/final_63.gif"
import final_64 from "../../../images/zine/final_64.gif"
import final_65 from "../../../images/zine/final_65.gif"
import final_66 from "../../../images/zine/final_66.gif"
import final_67 from "../../../images/zine/final_67.gif"
import final_68 from "../../../images/zine/final_68.gif"
import final_69 from "../../../images/zine/final_69.gif"
import final_70 from "../../../images/zine/final_70.gif"
import final_71 from "../../../images/zine/final_71.gif"
import final_72 from "../../../images/zine/final_72.gif"
import final_73 from "../../../images/zine/final_73.gif"
import final_74 from "../../../images/zine/final_74.gif"
import final_75 from "../../../images/zine/final_75.gif"
import final_76 from "../../../images/zine/final_76.gif"
import final_77 from "../../../images/zine/final_77.gif"
import final_78 from "../../../images/zine/final_78.gif"
import final_79 from "../../../images/zine/final_79.gif"
import final_80 from "../../../images/zine/final_80.gif"
import final_81 from "../../../images/zine/final_81.gif"
import {useState} from "react";
import {constants} from "../../../constants";
import {useUser} from "../auth/is_loggedin";


function mapping(page: number) {

    switch (page) {
        case 0:
            return final_00
        case 1:
            return final_01
        case 2:
            return final_02
        case 3:
            return final_03
        case 4:
            return final_04
        case 5:
            return final_05
        case 6:
            return final_06
        case 7:
            return final_07
        case 8:
            return final_08
        case 9:
            return final_09
        case 10:
            return final_10
        case 11:
            return final_11
        case 12:
            return final_12
        case 13:
            return final_13
        case 14:
            return final_14
        case 15:
            return final_15
        case 16:
            return final_16
        case 17:
            return final_17
        case 18:
            return final_18
        case 19:
            return final_19
        case 20:
            return final_20
        case 21:
            return final_21
        case 22:
            return final_22
        case 23:
            return final_23
        case 24:
            return final_24
        case 25:
            return final_25
        case 26:
            return final_26
        case 27:
            return final_27
        case 28:
            return final_28
        case 29:
            return final_29
        case 30:
            return final_30
        case 31:
            return final_31
        case 32:
            return final_32
        case 33:
            return final_33
        case 34:
            return final_34
        case 35:
            return final_35
        case 36:
            return final_36
        case 37:
            return final_37
        case 38:
            return final_38
        case 39:
            return final_39
        case 40:
            return final_40
        case 41:
            return final_41
        case 42:
            return final_42
        case 43:
            return final_43
        case 44:
            return final_44
        case 45:
            return final_45
        case 46:
            return final_46
        case 47:
            return final_47
        case 48:
            return final_48
        case 49:
            return final_49
        case 50:
            return final_50
        case 51:
            return final_51
        case 52:
            return final_52
        case 53:
            return final_53
        case 54:
            return final_54
        case 55:
            return final_55
        case 56:
            return final_56
        case 57:
            return final_57
        case 58:
            return final_58
        case 59:
            return final_59
        case 60:
            return final_60
        case 61:
            return final_61
        case 62:
            return final_62
        case 63:
            return final_63
        case 64:
            return final_64
        case 65:
            return final_65
        case 66:
            return final_66
        case 67:
            return final_67
        case 68:
            return final_68
        case 69:
            return final_69
        case 70:
            return final_70
        case 71:
            return final_71
        case 72:
            return final_72
        case 73:
            return final_73
        case 74:
            return final_74
        case 75:
            return final_75
        case 76:
            return final_76
        case 77:
            return final_77
        case 78:
            return final_78
        case 79:
            return final_79
        case 80:
            return final_80
        case 81:
            return final_81
    }

}


const Zines = () => {

    const [page, setPage] = useState(0)

    const user = useUser()


    function premiumContent() {
        return <div className={"flex-grow flex justify-center items-center flex-col h-full pb-2"}>
            <div className={"mx-2 mt-8 flex-grow min-h-0 border-black border-8"}>
                <img src={mapping(page)} className={"object-contain h-full"} alt={""}/>
            </div>

            <div className={"my-2 flex flex-row text-2xl space-x-1"}>
                <button className={"px-2"} disabled={page <= 0}
                        onClick={() => setPage(0)}>{constants.leftButton}{constants.leftButton}</button>
                <button className={"px-2"} disabled={page <= 0}
                        onClick={() => setPage(p => Math.max(0, p - 1))}>{constants.leftButton}</button>
                <div className={"px-2"}>Page {page + 1}/{82}</div>
                <button className={"px-2"} disabled={page >= 81}
                        onClick={() => setPage(p => Math.min(81, p + 1))}>{constants.rightButton}</button>
                <button className={"px-2"} disabled={page >= 81}
                        onClick={() => setPage(81)}>{constants.rightButton}{constants.rightButton}</button>
            </div>
        </div>
    }


    return <MenuPage titles={["the frantic fanfic zine"]} subHeaders={["82 pages of who what when where and why!"]}
                     hideAvatar>
        {user?.premium ? premiumContent() : ''}


    </MenuPage>
}

export default Zines