import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FranticFanficUser, PlayerDetails, PlayerId, User} from "../../types";
import {ColourScheme} from "../../types2";
import {PageNumber} from "../../components/menu/menu";

export interface MenuState {
    playerDetails?: PlayerDetails
    playerId?: PlayerId // after joining game but before websocket connection
    page: PageNumber
    pageHistory: PageNumber[]

    hiddenCode: boolean
    hiddenSubheaders: boolean
    user?: User
}

const initialMenuState: MenuState = {
    pageHistory: [],
    page: 0,
    hiddenCode: false,
    hiddenSubheaders: false
}


const menuSlice = createSlice({
    name: "menuSlice",
    initialState: initialMenuState,
    reducers: {
        updateTheme: (state, action: PayloadAction<ColourScheme>) => {
            if (state.playerDetails) {
                state.playerDetails.meta.theme = action.payload
            }
        },
        updateUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload
        },
        updateFranticFanficUser: (state, action: PayloadAction<FranticFanficUser>) => {
            if (state.user) {
                state.user.rank = action.payload.rank
                state.user.user_id = action.payload.user_id
            }
        },
        updatePlayerDetails: (state, action: PayloadAction<PlayerDetails>) => {
            state.playerDetails = action.payload
        },
        updatePlayerId: (state, action: PayloadAction<PlayerId>) => {
            state.playerId = {...state.playerDetails, ...action.payload}
        },
        clearRoom: (state) => {
            state.playerId = undefined
        },
        handleBack: (state) => {
            const prev = state.pageHistory.pop()
            if (prev !== undefined) {
                state.page = prev
            } else {
                state.page = PageNumber.Main
            }
        },
        handleNewPage: (state, action: PayloadAction<PageNumber>) => {
            state.pageHistory.push(state.page)
            state.page = action.payload
        },
        resetPages: (state) => {
            state.pageHistory = []
            state.page = PageNumber.Main
        },
        updateHiddenCode: (state, action: PayloadAction<boolean>) => {
            state.hiddenCode = action.payload
        },
        updateHiddenSubheaders: (state, action: PayloadAction<boolean>) => {
            state.hiddenSubheaders = action.payload
        },
    }
})

export const {
    updatePlayerDetails,
    updatePlayerId,
    handleNewPage,
    handleBack,
    clearRoom,
    resetPages,
    updateTheme,
    updateHiddenCode,
    updateUser,
    updateFranticFanficUser,
    updateHiddenSubheaders
} = menuSlice.actions
export const menuReducer = menuSlice.reducer