import Konva from "konva";
import {useEffect, useRef, useState} from "react";
import {Layer, Line, Stage} from "react-konva";
import GamePage from "../game-page/game-page";


type LineProp = {
    tool: string
    points: number[]
}

const Drawing: React.FC = () => {


    const [tool, setTool] = useState('pen');
    const [lines, setLines] = useState<LineProp[]>([]);
    const isDrawing = useRef(false);

    const parentRef = useRef<HTMLDivElement>(null);

    const [height, setHeight] = useState<number>()
    const [width, setWidth] = useState<number>()

    useEffect(() => {

        if (parentRef.current) {

            let parentHeight = parentRef.current.offsetHeight;
            setHeight(parentHeight)
            let parentWidth = parentRef.current.offsetWidth;
            setWidth(parentWidth)

        }

    }, [parentRef]);

    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
        isDrawing.current = true;
        const stage = e.target.getStage()
        if (!stage) {
            return
        }
        const pos = stage.getPointerPosition()
        if (pos) {
            setLines([...lines, {tool, points: [pos.x, pos.y]}]);
        }

    };

    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
        // no drawing - skipping
        if (!isDrawing.current) {
            return;
        }
        const stage = e.target.getStage();
        if (!stage) {
            return
        }

        const point = stage?.getPointerPosition();
        if (point) {
            let lastLine = lines[lines.length - 1];
            // add point
            lastLine.points = lastLine.points.concat([point.x, point.y]);

            // replace last
            lines.splice(lines.length - 1, 1, lastLine);
            setLines(lines.concat());
        }

    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };

    return (
        <GamePage>
            <div className={"h-full w-full flex flex-col"}>
                <select
                    value={tool}
                    onChange={(e) => {
                        setTool(e.target.value);
                    }}>
                    <option value="pen">Pen</option>
                    <option value="eraser">Eraser</option>
                </select>
                <div className={"flex-grow"} ref={parentRef}>
                    <Stage

                        width={width}
                        height={height}
                        onMouseLeave={handleMouseUp}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}>
                        <Layer>
                            {lines.map((line, i) => (
                                <Line
                                    key={i}
                                    points={line.points}
                                    stroke="#df4b26"
                                    strokeWidth={5}
                                    tension={0.5}
                                    lineCap="round"
                                    globalCompositeOperation={
                                        line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                    }
                                />
                            ))}
                        </Layer>
                    </Stage>
                </div>


            </div>

        </GamePage>
    );
}

export default Drawing