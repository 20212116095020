import React, {useCallback, useEffect} from "react";
import RoomAPI from "../../../RoomAPI";
import "./styles.css"
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {updateStreamers} from "../../../redux/slices/meta";


const Streamer = React.memo(() => {

    const dispatch = useAppDispatch()

    const selectStreamers = useAppSelector(state => state.meta.streamers)

    // const [streamers, setStreamers] = useState<TwitchStreamer[]>()


    const update = useCallback(() => {
        RoomAPI.GetTwitchUsers().then(s => dispatch(updateStreamers(s)))
    }, [dispatch])

    useEffect(() => {
        // if (!config.dontTwitch) {
        update()
        const interval = setInterval(() => update(), 300000)

        return () => {
            clearInterval(interval)
        }

    }, [update])

    function handleTwitchClick() {
        window.open("https://www.twitch.tv/directory/game/Frantic%20Fanfic", "_blank")
    }

    return <div id={"streamer-container"} className={"hidden self-end flex-col md:flex"}>
        {/*<img style={{backgroundColor: "white", alignSelf: "flex-end"}} className={"streamer-img"}*/}
        {/*     src={twitch64} alt={"twitch"}/>*/}
        <div onClick={handleTwitchClick} className={"live-on-twitch streamer-name"}>live on <br/>twitch:
        </div>
        {selectStreamers?.map(s => <div key={"ttv-" + s.display_name} className={"streamer-single"}>
                <a rel="noreferrer" target={"_blank"} href={`https://twitch.tv/${s.login}`}><img
                    className={"streamer-img"}
                    src={s.profile_image_url}
                    alt={s.display_name}/></a>
                <span className={"streamer-name"}>{s.display_name}</span>
            </div>
        )}
    </div>

})

export default Streamer