import React, {useState} from 'react';
import RoomAPI from "../../../RoomAPI";
import {PlayerId, Room} from "../../../types";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import MenuFormPage from "../menu-page/menu-form-page";
import {updateError, updateLoading} from "../../../redux/slices/meta";
import {useToken} from "../../../authToken";
import {useUser} from "../auth/is_loggedin";
import {updateHiddenCode} from "../../../redux/slices/menu";
import {useTranslation} from 'react-i18next';

type Props = {
    onRoomJoin: (room: Room, player: PlayerId) => void
    audience?: boolean
}

const EnterGameCode = ({onRoomJoin, audience}: Props) => {
    const lastRoomCode = localStorage.getItem("roomCode");


    // const {isAuthenticated, getAccessTokenSilently} = useAuth0();

    const {t} = useTranslation(['main', 'common'])

    const user = useUser()

    const token = useToken()
    const dispatch = useAppDispatch()
    const playerDetails = useAppSelector(state => state.menu.playerDetails)

    // const roomDetails = useContext(RoomContext)

    const [roomCode, setRoomCode] = useState<string>(lastRoomCode ? lastRoomCode : '')
    const [codeHidden, setCodeHidden] = useState(false)


    async function handleJoin() {
        if (!playerDetails) {
            alert("PLAYER NOT SET UP PROPERLY")
            return
        }
        if (!roomCode) {
            alert("WHERE IS YOUR ROOMID")
            return
        }
        try {
            dispatch(updateLoading(true))
            let roomPlayer
            if (audience) {
                roomPlayer = await RoomAPI.JoinRoomAudience(roomCode, {
                    meta: playerDetails.meta,
                    user_id: user?.auth0Id,
                    name: playerDetails.name,
                    secret: playerDetails.secret,
                }, token).catch(reason => {
                    dispatch(updateError(JSON.stringify(reason.response.data.trim())))
                });

            } else {
                roomPlayer = await RoomAPI.JoinRoom(roomCode, {
                    meta: playerDetails.meta,
                    user_id: user?.auth0Id,
                    name: playerDetails.name,
                    secret: playerDetails.secret,
                }, token)
                    .catch(reason => {
                        dispatch(updateError(JSON.stringify(reason.response.data.trim())))
                    });
            }

            if (roomPlayer) {
                let room = await RoomAPI.GetRoom(roomCode);
                onRoomJoin(room, roomPlayer)
            }
        } catch (err) {
            alert("FAILED TO JOIN LOBBY: " + err)
        } finally {
            dispatch(updateLoading(false))
        }
    }

    function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
        setCodeHidden(event.target.checked)
        dispatch(updateHiddenCode(event.target.checked))
    }


    return <MenuFormPage titles={[t('enterGame.headers.default')]} subHeaders={[t('enterGame.subheaders.default')]}
                         submitText={t('button.join', {ns: 'common'})}
                         ready={true} submit={handleJoin}>
        <div className={"flex-grow flex flex-col justify-center items-center"}>
            {lastRoomCode && <div className={"margin-b-4 text-lg"}>({t('enterGame.body.autoPopulates')})</div>}
            <input className={"text-4xl leading-snug bg-gray-drk shadow-basic"} type={codeHidden ? 'password' : 'text'}
                   minLength={4} maxLength={4}
                   size={4}
                   placeholder={t('label.code', {ns: 'common'})} defaultValue={lastRoomCode ?? undefined}
                   onChange={e => setRoomCode(e.target.value)}/>
            <label className={"text-xl mt-2 select-none"}>
                {t('enterGame.body.hideCode')}
                <input className={"ml-2 scale-150"} checked={codeHidden}
                       onChange={handleCheck} type={"checkbox"}/>
            </label>
        </div>
    </MenuFormPage>

}

export default EnterGameCode