type Props = {
    leftLabel: string

    bottom?: JSX.Element
    children?: React.ReactNode
}

const NameRow: React.FC<Props> = (props) => {


    return <div className={"self-stretch flex flex-col "}>
        <div className={"self-stretch flex justify-center items-center"}>
            <label className={"w-1/3 text-right text-2xl"}>{props.leftLabel}</label>
            <div className={"w-2/3 mr-2 ml-2"}>
                {props.children}
            </div>
        </div>
        {props.bottom}
    </div>
}

export default NameRow