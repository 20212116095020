import React from "react";
import {Game, Player, PlayerAction} from "./types";
import {PageNumber} from "./components/menu/menu";


// export type RoomContextDetails = {
//     playerDetails?: PlayerDetails
//     playerId?: PlayerId
//     room?: Room
//     serverConfig?: Config
// }

export type MenuContextDetails = {
    onBack: () => void
    setPage: (page: PageNumber) => void
}


export const MenuContext: React.Context<MenuContextDetails> = React.createContext({
    onBack: () => {
    },
    setPage: (page: PageNumber) => {

    }

})
// export const RoomContext: React.Context<RoomContextDetails> = React.createContext({})


export type GameDetails = {
    player?: Player
    game?: Game

}


export type GameConnection = {
    submit: (action: PlayerAction, noLoading?: boolean) => void
    // loading?: boolean
    // error?: string
    // connected?: boolean
}

// export type GameDetails = {
//     playAgain?: (delRoom: boolean) => void
//
//     goaway?: boolean
//     attempts?: number
//     connected?: boolean
//     loading?: boolean
//     error?: string
//     gameId?: number
//     players?: Player[]
//     settings?: GameSettings
//     status?: number
//     host?: number
//     submission_index?: number
//     submission_start?: number
//     submission_end?: number
//     completed_stories?: Story[]
//     votes?: StoryVote[]
//     awards?: Award[]
//     countdown?: Countdown
//
//     me?: Player
//     characters?: Character[]
//     current_story?: Story
//     current_submission?: StorySubmission
//     stats?: GameStats
// }

export const GameContext: React.Context<GameConnection> = React.createContext({
    submit: (action) => {
    }
})