import React from "react";

type Props = {
    value: number
    length?: number
}

export const BoxyNumbers = ({value, length}: Props) => {

    function prettify() {
        let s = value.toString().padStart(length ?? 10, "0")

        return (<div className="flex flex-row self-center items-center space-x-0.5">
            {s.split('').map<React.ReactNode>((c, idx) => <div key={'vc-digit-' + idx}
                                                               className={"bg-black leading-none text-classic-secondary"}>{c}</div>)}
        </div>)
    }


    return <div className={"self-center"}>{prettify()}</div>
}