import "./styles.css"
import React, {useContext, useEffect, useState} from "react";
import {PlayerAction, Story} from "../../../../types";
import VoteRow from "../vote-row/vote-row";
import {GameStatus} from "../../game";
import {config} from "../../../../constants";
import {useAppSelector} from "../../../../redux/store";
import GameFormPage from "../../game-page/game-form-page";
import {GameContext} from "../../../../contexts";
import {makeNice, SafeJoin} from "../../../../util";
import {useTranslation} from "react-i18next";
import {PrevSubmission2} from "../../../common/prev-submission/prev-submission";

type Props = {
    onBack?: () => void
    storyIndex: number
}

const StoryView = ({storyIndex, onBack}: Props) => {

    const {submit} = useContext(GameContext)

    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)

    // @ts-ignore
    const story = gameState.game?.stories[storyIndex] as Story

    const {t} = useTranslation(['game', 'common'])

    const [selected, setSelected] = useState(new Map<number, Map<number, boolean>>())

    // Handle reactions
    useEffect(() => {
        let reactions = playerState.player?.reactions
        let reaction = reactions?.find(r => r.story_id === story.id)
        if (!reaction || !reaction.meta) {
            return
        }

        if (reaction.meta.submission_reactions) {
            let map = new Map<number, Map<number, boolean>>()

            for (let i = 0; i < reaction.meta.submission_reactions.length; i++) {
                map.set(i, new Map(reaction.meta.submission_reactions[i].map(r => [r, true])))
            }
            setSelected(map)

        }

    }, [playerState.player?.reactions, story, setSelected])

    function gameSubmit() {
        console.log("handling submit")

        let reactionId = playerState.player?.reactions?.find(r => r.story_id === story.id)

        let numSwitches = gameState.game?.settings.num_switches ?? 3

        // let submissionReaction: number[][] = Array.from(selected.entries()).map(m => Array.from(m.entries()).filter(v => v[1]).map(v => v[0]))

        let submissionReactions = []
        for (let i = 0; i < numSwitches; i++) {
            let sr = selected.get(i) ?? new Map<number, boolean>();
            submissionReactions.push(Array.from(sr.entries()).filter(v => v[1]).map(v => v[0]))
        }


        let playerAction: PlayerAction = {
            code: "story/react",
            body: {
                reaction: {
                    id: reactionId ? reactionId.id : -1,
                    meta: {
                        submission_reactions: submissionReactions,
                    }
                }
            },
        }
        // submit(playerAction)

        return playerAction
    }

    function overrideSubmit() {
        submit(gameSubmit())
        if (onBack) {
            onBack()
        }
    }

    function handleSelection(idx: number, id: number, s: boolean) {


        setSelected(prevState => {
            let map = prevState.get(idx) ?? new Map()
            if (!map) {
                console.log("weird index " + idx)
                return prevState
            }

            return new Map(prevState.set(idx, map.set(id, s)))
        })
    }

    function getSelection(idx: number) {
        return selected.get(idx) ?? new Map()
    }


    function handleToggle(submissionId: number, censored: boolean) {
        let playerAction: PlayerAction = {
            code: "submission/censor2",
            body: {
                vip_action: {
                    censor: {
                        [submissionId]: censored
                    }
                }

            },
            // game_id: gameState.gameId as string,
        }
        submit(playerAction)
    }


    function titlesJsx() {
        const list = [
            <span className={"underline font-bold break-words"}>{story.meta.title}</span>,
            <>{t('label.characters', {ns: 'common'})}: {SafeJoin(story.characters?.filter(c => c.selected).map<React.ReactNode>(c =>
                <span className={"italic break-words"}
                      key={"char-" + c.id}>{c.name}</span>), ', ')} </>]

        if (!gameState.game?.settings.hide_authors) {
            list.push(<>{t('label.writers', {ns: 'common'})}: {makeNice(story.submissions.map<React.ReactNode>(s => <i
                key={"player-" + s.id}>{s.player.name}</i>))} </>)
        }

        list.push(<>Rated {story.settings.mature ? t('label.eighteenPlus', {ns: 'common'}) : t('label.allAges', {ns: 'common'})}</>)

        return list
    }

    function alreadyReacted() {
        return (playerState.player?.data.reaction_index && playerState.player?.data.reaction_index > story.meta.index) || playerState.player?.status === GameStatus.GameStatusVoting || playerState.player?.status === GameStatus.GameStatusPostReading

    }

    function submitText() {
        return alreadyReacted() ? t('storyView.footers.reactSaved') : t('storyView.footers.react')
    }

    function ready() {
        let count = 0
        selected.forEach(s1 => {
            s1.forEach((k, v) => {
                if (k) {
                    count += 1
                }
            })

        })
        return count
    }

    return <GameFormPage
        countdownThreshold={120}
        readyFlash={!alreadyReacted()}
        onBack={onBack}
        subHeaders={[t('storyView.subheaders.default1'), t('storyView.subheaders.default2'), t('storyView.subheaders.default3')]}
        submitText={submitText()}

        submit={overrideSubmit}
        ready={ready() > 0}
        titlesJsx={titlesJsx()}>

        <div className={"story-view-container"}>
            {story.submissions.map((s, idx) => {
                return <div key={s.id} className={"story-view-submission"}>
                    <PrevSubmission2 isCensored={s.meta.censored} playerId={playerState.player?.id as number}
                                     submission={s}
                                     canFlag={playerState.player?.id === gameState.game?.host_id}
                                     onToggle={(b) => handleToggle(s.id, b)}/>
                    <hr/>
                    {gameState.game?.settings.forced_prompts ?
                        <div className={"vote-row-buttons italic mt-2"}>prompt: {s.meta.prompt}</div> : ''}
                    {(gameState.game?.players && gameState.game.players.length > 1) && !config.canReactToSelf && s.player_id === playerState.player?.id ?
                        <div className={"vote-row-buttons italic divide-y-0"}>{t('storyView.body.cantReact')}</div> :
                        <VoteRow partNumber={idx + 1}
                                 setSelected={(id, selected1) => handleSelection(idx, id, selected1)}
                                 selected={getSelection(idx)}/>}
                </div>
            })}
        </div>
    </GameFormPage>
}

export default StoryView