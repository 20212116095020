import React from "react";

export const terms = <>
    <p>These terms of service outline the rules and regulations for the use of Franticfanfic.com (the “Website”). We
        provide the online entertainment service of playing the creative writing game Frantic Fanfic as well as
        supporting content surrounding Frantic Fanfic including but not limited to blogging, merchandise and other
        promotional materials.</p>

    <p>By accessing this Website we assume you accept these terms of service in full. Do not continue to use
        Franticfanfic.com if you do not accept all of the terms of service stated.</p>

    <p>The following terminology applies to these terms of service, privacy statement and disclaimer notice and any or
        all agreements: "client", "you" and "your" refers to you, the person accessing this website and accepting the
        company's terms of service. "The company", “Frantic Fanfic”, “Franticfanfic.com”, "ourselves", "we", "our" and
        "us", refers to our company. "party", "parties", or "us", refers to both the client and ourselves, or either the
        client or ourselves.
    </p>

    <p>
        Your use of the Website and any other online platform we provide (collectively, the “Platform”), including,
        without limitation, all content, information, text, questions, comments, ideas, graphics, images, tools, and
        software programs contained in the Website and related materials, is governed by these Terms and Conditions and
        is subject to arbitration (as set forth below). By visiting and using the Platform you acknowledge that you have
        read the Terms and Conditions (“Terms”) and that you accept and agree without limitation or qualification to be
        bound by the terms hereof. We reserve the right, in Our sole discretion, to change these Terms and Conditions
        from time to time. You are responsible for periodically checking our Terms and Conditions and Privacy Policy to
        appraise yourself of any changes. Your continued use of Our Platform constitutes your wish to be bound by these
        Terms and Conditions. If you do not wish to be bound to these Terms and Conditions (or any revisions to them),
        you must immediately discontinue use of the Website, Platform, and/or Services and cancel any account you may
        have with Us. Violation of any of these Terms may result in the suspension of Our Services to you, in accordance
        with these Terms.
    </p>

    <p style={{fontWeight: "bold"}}>
        BY USING THE WEBSITE OR BY CLICKING TO ACCEPT THE TERMS AND CONDITIONS, YOU ACCEPT AND AGREE TO BE BOUND AND
        COMPLY WITH THESE TERMS AND CONDITIONS FOR SERVICE PROVIDERS AND OUR PRIVACY POLICY, FOUND AT <a
        href="https://www.franticfanfic.com/privacy">https://www.franticfanfic.com/privacy</a> INCORPORATED HEREIN BY
        REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE
        THE WEBSITE.
    </p>

    <h2>1. <u>Access</u></h2>

    <p>In order to access Franticfanfic.com you certify that you are at least the age of majority (denoted on
        Franticfanfic.com as 18+ but that varies depending on the age of majority in your region) or you have reviewed
        this agreement with a parent or guardian and they consent to you using this website. Our Platform is intended
        for use and governed / interpreted by residents of Canada in all Provinces and Territories. In using this
        Website you are subject to the jurisdiction of the courts in this location.</p>

    <h2>2. <u>Proprietary Rights</u></h2>

    <p>
        All materials created by franticfanfic.com including but not limited to code, artwork, design, style, software,
        name, graphics, avatars, avatar names, gameplay, user interface, effects, animations, are the property of
        Frantic Fanfic. Frantic Fanfic’s intellectual property is protected by applicable copyrights. Users are
        forbidden from infringing on such rights and may not copy, distribute, reproduce or transmit the materials for
        their own purposes without express permission or agreement from franticfanfic.com except for personal use.
    </p>

    <h2>3. <u>License to Use</u></h2>

    <p>
        Unless otherwise stated, Frantic Fanfic and/or its licensors own the intellectual property rights for all
        material on Franticfanfic.com. All intellectual property rights are reserved. You may view and/or print pages
        from Franticfanfic.com for your own personal use subject to restrictions set in these terms of service.
    </p>

    <p>You Must Not:</p>

    <ol>
        <li>Republish material from Franticfanfic.com.</li>
        <li>Sell, rent or sub-license material from Franticfanfic.com.</li>
        <li>Reproduce, duplicate or copy material from Franticfanfic.com.</li>
        <li>Redistribute content from Franticfanfic.com (unless such content is specifically made for redistribution).
        </li>
    </ol>


    <p><u>Unauthorized Use of the Website</u>: You agree to not gain or
        attempt to gain unauthorized access to any computer,
        network, or server that is connected to Our Platform. You further agree to not transmit, upload, email, post or
        otherwise make available through the Platform: (a) any junk mail, spam, or unsolicited email or bulletin board
        postings; (b) any unlawful, harassing, libelous, abusive, threatening, violent, pornographic, hurtful,
        defamatory, vulgar, obscene, racist, extremist, harmful, or otherwise objectionable material of any kind; (c)
        any information or material that infringes a third-party right, including but not limited to intellectual
        property rights and/or privacy rights; (d) any software viruses, Trojan horses, worms, or any other malicious
        application or code; or (d) any information or material which may constitute or encourage conduct that is a
        criminal offence, a civil wrong, or otherwise violates any applicable law, rules, or regulation.
    </p>

    <h2>4. <u>User Content</u></h2>


    <p>Certain parts of this website offer the opportunity for users to post and exchange opinions, information,
        material and data ('User Content') in areas of the website. Frantic Fanfic does not screen, edit, publish or
        review User Content prior to their appearance on the website and User Content does not reflect the views or
        opinions of Franticfanfic.com, its agents or affiliates.</p>

    <p>User Content reflects the views and opinions of the persons who posts such views or opinions. To the extent
        permitted by applicable laws Franticfanfic.com shall not be responsible or liable for the User Content or for
        any loss cost, liability, damages or expenses caused and or suffered as a result of any use of and/or posting of
        and/or appearance of the User Content on this website.</p>

    <p>Franticfanfic.com reserves the right to monitor all User Content and to remove any User Content which it
        considers in its sole and absolute discretion to be inappropriate, offensive or otherwise in breach of these
        Terms of Service. Franticfanfic.com also reserves the right to investigate all such breaches and suspend or
        terminate an account on suspicion of violations.</p>

    <p>You warrant and represent that:</p>
    <ol>
        <li>You are entitled to post the User Content on our website and have all necessary licenses and consents to do
            so; The
            User Content does not infringe any intellectual property right, including without limitation copyright,
            patent or
            trademark, or other proprietary right of any third party;
        </li>
        <li>The User Content does not contain any defamatory, libelous, offensive, indecent or otherwise unlawful
            material or
            material which is an invasion of privacy or which violates rights of any third party including but not
            limited to
            copyright, IP, etc.
        </li>
        <li>The User Content will not be used to solicit or promote business or custom or present commercial activities
            or
            unlawful activity.
        </li>
        <li>You hereby grant to Franticfanfic.com a non-exclusive royalty-free license to use, reproduce, edit and
            authorize
            others to use, reproduce and edit any of your User Content in any and all forms, formats or media.
        </li>
    </ol>


    <p>Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness
        or
        accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is
        kept up to date.</p>


    <h2>5. <u>Disclaimer of Warranties</u></h2>
    <p>YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED
        THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED
        THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF
        ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT
        BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
    <p>NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
        AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY WARRANTY,
        REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY,
        CURRENCY, OR AVAILABILITY OF THE WEBSITE OR ITS CONTENTS. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY
        NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
        PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS REPRESENT OR WARRANT THAT THE WEBSITE,
        ITS CONTENT, OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE,
        ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT
        AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
    <p>WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE
        WEBSITE WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR USE
        OF THE WEBSITE AND YOUR COMPUTER, INTERNET, AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL
        NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
        OVERLOADING, FLOODING, MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER
        TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER
        PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE
        WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>


    <h2>6. <u>Limitation on Liability</u></h2>

    <p>
        EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL THE COMPANY NOR ITS PARENT,
        SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
        CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE,
        NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY
        DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO,
        PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
        ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
        NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD
        REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE WEBSITE,
        ANY LINKED WEBSITES OR SUCH OTHER THIRD-PARTY WEBSITES, NOR ANY WEBSITE CONTENT, MATERIALS, POSTING, OR
        INFORMATION THEREON EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW. Minors are only authorized to
        use the all ages rooms, we accept no liability for minors accessing mature rooms without authorization.
    </p>

    <h2>7. <u>Indemnification</u></h2>

    <p>
        To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless the
        Company, its parent, subsidiaries, affiliates, and their respective directors, officers, employees, agents,
        service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims,
        liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable lawyers’ fees)
        arising out of or relating to your breach of these Terms and Conditions or your use of the Website, including,
        but not limited to, your User Submissions, third-party sites, any use of the Website's content, services, and
        products other than as expressly authorized in these Terms and Conditions.
    </p>


    <h2>8. <u>Governing Law and Choice of Forum</u></h2>

    <p>The Website and these Terms and Conditions will be governed by and construed in accordance with the laws of the
        Province of Ontario and the federal laws of Canada applicable therein, without giving effect to any choice or
        conflict of law provision, principle, or rule (whether of the laws of the Province of Ontario or any other
        jurisdiction) and notwithstanding your domicile, residence, or physical location. </p>
    <p>Any action or proceeding arising out of or relating to this Website and under these Terms and Conditions will be
        instituted in the courts of the Province of Ontario and/or the Federal Court of Canada, and each party
        irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding. You waive any
        and all objections to the exercise of jurisdiction over you by such courts and to the venue of such courts.</p>


    <h2>9. <u>Arbitration</u></h2>

    <p>
        You hereby expressly waive any and all claims you may have, now or in the future, arising out of or relating to
        this Website, the Company, any and all contracts you enter into with the Company, and any and all of the
        Company’s products and services.
    </p>


    <p>
        To the extent that you attempt to assert any such claim, you hereby expressly agree to present such claim only
        through binding arbitration to occur in the Province of Ontario. You further agree to and do hereby waive any
        right to class arbitration and agree, instead, to conduct an arbitration related solely to any individual claims
        you and/or any entity related to you asserts against the Company. To the fullest extent permissible by law, you
        further agree that you shall be responsible for all costs associated with initiating the arbitration and for the
        administration of the arbitration.
    </p>
    <h2>10. <u>Copyright Issues</u></h2>

    <p>
        Frantic Fanfic respects intellectual property rights of others and will respond to notices of alleged copyright
        infringement. If you would like to submit a claim of copyright infringement, please contact us at
        franticfanficgame@gmail.com.
    </p>

    <p>
        We reserve the right to remove any content that is alleged to be infringing at our sole and absolute discretion.
    </p>

    <h2>11. <u>Termination and Access Restriction</u></h2>

    <p>
        The Company reserves the right, in its sole discretion, to terminate your access to the Website and the related
        services or any portion thereof at any time, without notice. To the maximum extent permitted by law, and you
        hereby consent to resolve any and all disputes arising under or related to this Website or the Terms and
        Conditions pursuant to the Arbitration Clause above. Use of the Website is unauthorized in any jurisdiction that
        does not give effect to all provisions of these Terms and Conditions, including, without limitation, this
        section.
    </p>
    <h2>12. <u>No Joint Venture or Other Relationship</u></h2>

    <p>
        You agree that no joint venture, partnership, employment, or agency relationship exists between you and the
        Company as a result of this agreement or use of the Website. The Company’s performance of this agreement is
        subject to existing laws and legal process, and nothing contained in this agreement is in derogation of the
        Company’s right to comply with governmental, court, and law enforcement requests or requirements relating to
        your use of the Website or information provided to or gathered by the Company with respect to such use. If any
        part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but
        not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or
        unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches
        the intent of the original provision and the remainder of the agreement shall continue in effect.
    </p>
    <h2>13. <u>Waiver</u></h2>

    <p>
        No failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from these Terms
        and Conditions operates, or may be construed, as a waiver thereof. No single or partial exercise of any right,
        remedy, power, or privilege hereunder precludes any other or further exercise thereof or the exercise of any
        other right, remedy, power, or privilege.
    </p>
    <h2>14. <u>Severability</u></h2>

    <p>
        If any term or provision of these Terms and Conditions is invalid, illegal, or unenforceable in any
        jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of
        these Terms and Conditions or invalidate or render unenforceable such term or provision in any other
        jurisdiction.
    </p>

    <h2>15. <u>Entire Agreement</u></h2>
    <p>The Terms and Conditions and our Privacy Policy constitute the sole and entire agreement between you and
        Franticfanfic.com regarding the Website and supersedes all prior and contemporaneous understandings, agreements,
        representations and warranties, both written and oral, regarding such subject matter. </p>
    <h2>16. <u>Reporting and Contact</u></h2>

    <p>
        This website is operated by Franticfanfic.com. Should you become aware of misuse of the website including
        libelous or defamatory conduct, you must report it to the Company at franticfanficgame@gmail.com.
    </p>

    <p>
        All other feedback, comments, requests for technical support, and other communications relating to the Website
        should be directed to franticfanficgame@gmail.com.
    </p>


    <b>DATE:</b> April 30 2021
</>