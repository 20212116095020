import React from "react";
import './styles.css'
import PageContainer from "../page-container/page-container";
import FormFooter from "../form-footer/form-footer";
import {Props} from "../page/page";
import Subheader from "../subheader/subheader";


type FormProps = Props & {

    onSubmit: () => void

    loading?: boolean
    ready?: boolean

    submitText?: string

    loadingText?: string

    showSkip?: boolean
    hideConnected?: boolean
    readyFlash?: boolean
}


const FormPage: React.FunctionComponent<FormProps> = props => {

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        props.onSubmit()
    }

    
    return <PageContainer
        connected={props.connected}
        leftLabel={props.leftLabel}
        roomCode={props.roomCode}
        hideConnected={props.hideConnected}
        extraLabel={props.extraTopLabel}>
        <form className={"form-book"} onSubmit={handleSubmit}>
            {props.header}
            <Subheader subheaders={props.subHeaders}/>

            <div className={"basis-40 h-full flex-grow flex flex-col overflow-y-scroll overflow-x-hidden"}>
                {props.children}
            </div>
            <FormFooter readyFlash={props.readyFlash} ready={props.ready ?? true} loadingText={props.loadingText}
                        error={props.error}
                        countdown={props.countdown}
                        countdownThreshold={props.countdownThreshold}
                        loading={props.loading} onBack={props.onBack} onSubmit={handleSubmit}
                        submitText={props.submitText}/>
        </form>
    </PageContainer>;
}


export default FormPage