import React from "react";
import {constants} from "../../../constants";
import {Player} from "../../../types";

const emojis = constants.emojis//["🙏", "🌶️", "👀", "🧠", "💩", "🤪", <span style={{color: "red"}}>❤️</span>, "🔥", "😂", "⭐"]

type Props = {
    player?: Player
}
const ResultsAvatar = ({player}: Props) => {

    function topReacts() {
        if (player?.data.reactions) {
            let entries = Object.entries(player.data.reactions).sort((a, b) => b[1] - a[1]).slice(0, 3)
            return entries.map<React.ReactNode>(value => <span
                key={"emoji-" + value}>{emojis[parseInt(value[0])]}<sub>{value[1]}</sub></span>)
        }
    }

    return <div className={"results-big-reactions-container"}>
        <i>Top Reacts:</i>
        <div className={"results-big-reactions"}>{topReacts()}</div>
    </div>
}

export default ResultsAvatar