import React from "react";
import MenuPage from "../menu-page/menu-page";
import {Link} from "react-router-dom";
import {useUser} from "../auth/is_loggedin";
import header from '../../../images/supportheader.gif'
import footer from '../../../images/supportfooter.gif'

import {useTranslation} from "react-i18next";
import {config} from "../../../constants";
import LinkButton from "../link-button/link-button";
import Perk from "./perk";


const Supporter: React.FunctionComponent = () => {
    const user = useUser()

    const {t} = useTranslation('main')

    return <MenuPage titles={[t('supporter.headers.default')]}
                     subHeaders={[t('supporter.subheaders.default')]}>

        {user === undefined && <div className={"w-full p-2"}>
            <LinkButton small path={"/user"}
                        buttonText={"users have to be logged in to become supporters. click here to log in."}/>
        </div>
        }


        <div>
            <img src={header} alt={"support us styled-header"}/>
        </div>

        <div className={"flex flex-col flex-1 self-stretch m-4 space-y-8 justify-center"}>
            <div className={"flex flex-row justify-center space-x-8"}>
                <form action={config.stripeEndpoint} method="POST"
                      className={"w-2/5 flex flex-col items-end space-y-2 text-3xl"}>
                    <input type={"hidden"} id={"userId"} name={"userId"} value={user?.auth0Id}/>
                    <button disabled={user?.premium} className={"p-2"}
                            type="submit">{t('supporter.body.purchase')}</button>
                    <button disabled={user?.premium} className={"p-2"} type="submit">$4.99
                    </button>
                </form>

                <div
                    className={"w-3/5 flex flex-col text-base leading-none space-y-4 bg-night-primary pb-4 shadow-basic"}>
                    <div
                        className={"text-3xl text-white bg-black p-3 flex justify-center items-center"}>{t('supporter.body.perksTitle')}</div>

                    <Perk label={t('supporter.body.perk1')}/>
                    <Perk label={t('supporter.body.perk2')}/>
                    <Perk label={t('supporter.body.perk3')}/>
                    <Perk label={t('supporter.body.perk4')}/>
                    <Perk label={t('supporter.body.perk5')}/>
                </div>
            </div>

            {/*<div className={"fake-button text-left"}>*/}
            {/*    <p className={"font-bold"}>If you supported Frantic Fanfic in 2021:</p>*/}
            {/*    <br/>*/}

            {/*    <ol>*/}
            {/*        <li>Login to franticfanfic.com with your Discord or Twitter.</li>*/}
            {/*        <li>Email franticfanficgame@gmail.com with the email address that you sent a Ko-fi with/bought merch*/}
            {/*            with and*/}
            {/*            include:*/}
            {/*            <ul className={"pl-6"}>*/}
            {/*                <li>The Discord / Twitter name you signed into FF with.</li>*/}
            {/*                <li>How you supported the game (ko-fi, merch, zine)</li>*/}
            {/*            </ul>*/}


            {/*        </li>*/}
            {/*    </ol>*/}
            {/*    <br/>*/}
            {/*    <p>We’ll set your account to Supporter as fast as we can :)</p>*/}
            {/*</div>*/}
            <div className={"self-stretch flex flex-col self-center text-3xl"}>
                {user?.premium ?
                    <Link
                        className={"w-full bg-black cursor-pointer text-center italic no-underline text-black hover:text-black visited:text-black"}
                        to={"/zine"}>
                        <button
                            className={"w-full text-white bg-black text-xl py-2"}>{t('supporter.body.zine')}</button>
                    </Link> :
                    <Link
                        className={"opacity-50 w-full bg-black pointer-events-none text-center italic no-underline text-black hover:text-black visited:text-black"}
                        to={"#"}>
                        <button
                            className={"w-full text-white bg-black text-xl py-2"}>{t('supporter.body.zine')}</button>
                    </Link>}

            </div>

        </div>

        <div>
            <img src={footer} alt={"support us footer"}/>
        </div>

    </MenuPage>
}

export default Supporter