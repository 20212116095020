import {Countdown, Player, PlayerAction} from "../../../types";
import "./styles.css"
import Avatar4 from "../../common/avatar4/avatar";
import CountdownComponent from "../../common/countdown/countdown";
import React, {useState} from "react";
import CountdownComponent2 from "../../common/countdown2/countdown2";
import {GameStatus} from "../game";
import ClickableAvatar from "../../common/clickable-avatar/clickable-avatar";
import {useAppSelector} from "../../../redux/store";
import GamePage from "../game-page/game-page";

type Props = {
    header: string
    subHeader?: string
    players?: Player[]
    countdown?: Countdown

    onPlayerAction?: (playerAction: PlayerAction) => void
    loading?: boolean

    children?: React.ReactNode

}


const Waiting = ({header, players, countdown, onPlayerAction, loading, subHeader}: Props) => {

    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)

    const [clickedGuy, setClickedGuy] = useState<number>()

    const [clicky, setClicky] = useState(0)

    function amHost() {
        return gameState.game?.host_id === playerState.player?.id
    }

    function handleBoot() {
        if (onPlayerAction) {
            if (!clickedGuy) {
                console.log("undefined player")
                return
            }

            if (clicky === 0) {
                setClicky(1)
                return
            }

            setClicky(0)
            let playerAction = {
                body: {
                    "vip_action": {
                        "kick_player": clickedGuy
                    }
                },
                code: "game/vip",
            }
            onPlayerAction(playerAction)
            setClickedGuy(undefined)
        }
    }

    React.useEffect(() => {
        if (!clickedGuy) {
            setClicky(0)
        }
    }, [clickedGuy])

    function handleClick(player: Player) {
        setClickedGuy(prevState => prevState === player.id ? undefined : player.id)
    }

    function clickyText() {
        if (gameState.game?.status === GameStatus.GameStatusLobby || gameState.game?.status === GameStatus.GameStatusQuickStart || gameState.game?.status === GameStatus.GameStatusCharacters || gameState.game?.status === GameStatus.GameStatusStoryCreate) {
            return "kick player"
        } else if (gameState.game?.status === GameStatus.GameStatusStoryTitle || gameState.game?.status === GameStatus.GameStatusVoting || gameState.game?.status === GameStatus.GameStatusReacting) {
            return "skip player"
        }
    }

    return <GamePage titles={[header]} subHeaders={amHost() && subHeader ? [subHeader] : []}
                     footerJsx={countdown && countdown.limit !== 0 ? (amHost() ?
                         <CountdownComponent2 disabled={players?.length === 0 || !clickedGuy}
                                              btnText={clicky === 0 ? clickyText() : "are you sure?"} loading={loading}
                                              onClick={handleBoot} countdown={countdown}/> :
                         <CountdownComponent countdown={countdown}/>) : <></>}>

        <div className={"waiting-container"}>
            <div className={"waiting-avatars"}>

                {players?.map(p => amHost() ?

                    <ClickableAvatar key={p.id} player={p} onClick={handleClick}
                                     checked={clickedGuy === p.id}/> :
                    <Avatar4 key={p.id} name={p.name}
                             spriteId={p.meta.sprite_id}/>)}
            </div>
        </div>
    </GamePage>
}
export default Waiting