import MenuPage from "../menu-page/menu-page";
import {constants} from "../../../constants";
import header from "../../../images/supportheader.gif";


const Thankyou = () => {


    return <MenuPage titles={['thanks for supporting frantic fanfic']} hideAvatar>

        <div>
            <img src={header} alt={"support us styled-header"}/>
        </div>

        <div className={"flex-grow flex justify-center items-center flex-col space-y-4"}>
            <div className={"self-center flex flex-col m-4 justify-center text-2xl"}>
                <div className={"bg-gray-drk shadow-basic border-black border-[1px]"}>
                    <div className={"bg-black text-white text-center leading-loose"}>enjoy your features!</div>
                    <div className={"px-2"}>{constants.rightButton} PROMPT PACKS UNLOCKED.</div>
                    <div className={"px-2"}>{constants.rightButton} ALL THEMES UNLOCKED.</div>
                    <div className={"px-2"}>{constants.rightButton} THE FRANTIC FANFIC ZINE.</div>
                    <div className={"px-2"}>{constants.rightButton} SUPPORTER AVATARS (MORE TO COME).</div>
                    <div className={"px-2"}>{constants.rightButton} GUILT-FREE AD-FREE FRANTIC FANFIC!</div>
                </div>

            </div>

            <div className={"text-xl px-2 text-center"}>
                refresh the site for your features to take effect.
            </div>
            <div className={"text-xl px-2 text-center"}>follow us on twitter <a
                href={"https://twitter.com/franticfanfic"}>@FRANTICFANFIC</a> :)
            </div>
        </div>

    </MenuPage>
}

export default Thankyou