import InputProps from "./input";

type RadioInputProps = Omit<InputProps, "value"> & {
    checked: boolean
    name: string
    label: string
    value: string | ReadonlyArray<string> | number | undefined
    fontFamily?: string
    fontSize?: string
}

const RadioInput: React.FC<RadioInputProps> = (props) => {
    return <><input name={props.name} type="radio" id={props.id} value={props.value}
                    onChange={props.onChange} checked={props.checked}/><label  style={{fontFamily: props.fontFamily, fontSize: props.fontSize}}
        htmlFor={props.id}>{props.label}</label></>
}


export default RadioInput