import React from "react";
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";

export let blog8Title = "Entry #8 - Images and Twitter"

export let blog8Author = "Posted by Michael / Sunday May 23 2021"

export let blog8Content = <Body>
    <BodyText>Hi everyone!</BodyText>
    <BodyText>New feature: You can now download images of stories in addition to text documents. So you have that choice
        now!
        You don’t need to screencap anymore, we have a button for that. Neat huh?!</BodyText>
    <BodyText>New feature: On individual story pages after the game is over, you can export to Twitter pretty easily
        now, we
        have a button that will create tweet text for you. You just have to upload the picture yourself. Just make sure
        you have consent to share these stories from the contributing writers before you publish them anywhere!
    </BodyText>
    <BodyText>New feature: Game settings are now automatically remembered from the last game type you played if you were
        the
        VIP! We figured this would be a cute thing to add that just helped with the replayability experience so please
        enjoy.
    </BodyText>
    <BodyText>We keep thinking of cool ideas for this game. There’s so many things we can do and so many places we can
        go.
        We’re like “Oh yeah… we’re done now” and then we aren’t haha. Thanks to everyone who has played the game so far
        and extra special thanks to everyone who has told a friend about it &lt;3. We’re so thankful!
    </BodyText>
    <BodyText>Even MORE thanks to all of our Ko-Fi supporters, we’re 95% to our second character goal! Holy crap! Thanks
        again
        so much for the support there, helps us a ton with advertising and server costs so we really appreciate it.
    </BodyText>
    <BodyText>Zee and Michael
    </BodyText>
</Body>

export let blog = {
    title: blog8Title,
    author: blog8Author,
    content: blog8Content
}