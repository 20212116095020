import {body} from "../../../text/quick-start";
import {body as bodyotp} from "../../../text/quick-start-otp";
import {body as bodysi} from "../../../text/quick-start-selfinsert";
import {body as bodysolo1} from "../../../text/quick-start-solo";
import {body as bodysolo2} from "../../../text/quick-start-solo-random";
import {body as bodybt} from "../../../text/quick-start-broken-telephone";

import "./styles.css"
import {GameSettings} from "../../../types";
import React, {useContext} from "react";
import {GameMode} from "../../../types2";
import Waitable from "../../common/waitable/waitable";
import {GameStatus} from "../game";
import {useAppSelector} from "../../../redux/store";
import GameFormPage from "../game-page/game-form-page";
import {GameContext} from "../../../contexts";
import {useTranslation} from "react-i18next";


const QuickStart = () => {
    const {submit} = useContext(GameContext)

    const {t} = useTranslation("game", {keyPrefix: "quickStart"})

    const selectGameSettings = useAppSelector(state => state.game.game?.settings)

    // function handleClick(e: React.FormEvent<HTMLFormElement>) {
    //     e.preventDefault()
    //     onClick({code: "game/ready", body: {}})
    // }


    function gameSubmit() {
        return {code: "game/ready", body: {}}
    }

    function getHeader() {
        switch (selectGameSettings?.game_mode) {
            case GameMode.default:
                return t('headers.default')
            case GameMode.otp:
                return t('headers.otp')
            case GameMode.self_insert:
                return t('headers.selfInsert')
            case GameMode.broken_telephone:
                return t('headers.missingHistory')
            case GameMode.solo_mode:
                return selectGameSettings.self_shuffle ? t('headers.soloRandom') : t('headers.soloOtp')
            default:
                return t('headers.default')
        }
    }

    function getBody(settings: GameSettings) {
        switch (selectGameSettings?.game_mode) {
            case GameMode.default:
                return body.contentGOTIT(settings)
            case GameMode.otp:
                return bodyotp.contentGOTIT(settings)
            case GameMode.self_insert:
                return bodysi.contentGOTIT(settings)
            case GameMode.broken_telephone:
                return bodybt.contentGOTIT(settings)
            case GameMode.solo_mode:
                return selectGameSettings.self_shuffle ? bodysolo2.contentGOTIT(settings) : bodysolo1.contentGOTIT(settings)
            default:
                return body.contentGOTIT(settings)
        }
    }

    function getSubHeaders() {
        return [t('subheaders.default')]
    }

    return <Waitable playerActionHandler={submit} waitingFor={[GameStatus.GameStatusPostQuickStart]}
                     waitHeader={t("waiting")}>
        <GameFormPage
            readyFlash
            subHeaders={getSubHeaders()}
            submitText={t('footer.submit')} gameSubmit={gameSubmit}
            titles={[getHeader()]}>
            <div className={"p-4"}>
                {getBody(selectGameSettings as GameSettings)}
            </div>
        </GameFormPage>
    </Waitable>


}

export default QuickStart