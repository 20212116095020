import {useContext, useEffect, useRef, useState} from "react";
import StoryList from "./story-list/story-list";
import StoryView from "./story-view/story-view";
import StoryVoteManager from "./story-vote/story-vote-manager";
import Waitable from "../../common/waitable/waitable";
import {GameStatus} from "../game";
import {useAppSelector} from "../../../redux/store";
import {GameContext} from "../../../contexts";


const ReadingTime = () => {
    const {submit} = useContext(GameContext)

    const playerState = useAppSelector(state => state.player)

    const metaState = useAppSelector(state => state.meta)

    const [page, setPage] = useState(0)


    const story = useRef<number>()

    const submitted = useRef(false)

    useEffect(() => {
        if (page === 1 && !metaState.loading && submitted.current && !metaState.error) {
            submitted.current = false
            setPage(0)
        }
    }, [metaState.error, metaState.loading, page])

    useEffect(() => {
        if (playerState.player?.status === GameStatus.GameStatusResults || playerState.player?.status === GameStatus.GameStatusPostReading) {
            setPage(2)
        }
    }, [playerState.player?.status])

    function handleVote(voteId: number, storyId: number) {
        submitted.current = true
        submit({
            code: "story/vote", body: {
                "vote": {
                    "id": voteId,
                    "story_id": storyId,
                }
            }
        })
    }

    let comp
    switch (page) {
        case 0:
            comp = <StoryList onVote={() => setPage(2)} onClick={(s) => {
                // setStory(s)
                story.current = s
                setPage(1)
            }}/>
            break
        case 1:
            comp =
                <StoryView storyIndex={story.current as number}
                           onBack={() => setPage(0)}/>
            break
        case 2:

            // comp = <StoryVote award={Gamers.awards ? Gamers.awards[0] : undefined} onVote={handleVote} onBack={() => setPage(0)}/>
            comp = <Waitable playerActionHandler={submit}
                             waitingFor={[GameStatus.GameStatusPostReading]}
                             waitHeader={"waiting for voters.."} audienceOverride={true}>
                <StoryVoteManager loading={metaState.loading} onVote={handleVote} onBack={() => setPage(0)}/>
            </Waitable>
            break

    }

    return <>{comp}</>
    // return <Waitable playerActionHandler={onAction} waitingFor={PlayerStatus.PlayerStatusStoryReacting}
    //                  waitHeader={"waiting for voters.."}>{comp}</Waitable>
}
export default ReadingTime