import React from "react";
import "./styles.css"
import {config} from "../../../constants";

const paths = config.avatarsSmall
const specialPaths = config.avatarsSpecial
type Props = {
    spriteId: number,
    name?: string
    vip?: boolean
    noAnimate?: boolean
    special?: boolean

    onClick?: () => void
}

const Avatar4 = ({spriteId, name, noAnimate, special, onClick}: Props) => {

    let path = special ? specialPaths[spriteId] : paths[spriteId]


    return <div className={"avatar4-container"} onClick={onClick}>
        <div className={"avatar4"}>
            <div className={"avatar4-square"}/>
            <div className={"avatar4-img-holder"}>
                <img className={noAnimate ? "avatar4-img-no-bounce" : "avatar4-img"} src={path}
                     alt={path}/>
            </div>
        </div>

        {name ? <div className={"avatar4-name-holder"}>
            <div className={`avatar4-name`}>{name}</div>
        </div> : ''}

    </div>
}

export default Avatar4