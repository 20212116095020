import {Story} from "../../../../types";
import "./styles.css"
import Avatar4 from "../../../common/avatar4/avatar";
import StoryButton from "../../../common/story-button/story-button";
import {useAppSelector} from "../../../../redux/store";

type Props = {
    story: Story
    onClick: (story: Story) => void
}

const StoryListCard = ({story, onClick}: Props) => {

    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)

    function handleClick() {
        onClick(story)
    }


    return <div className={"story-list-card-container"}>
        <StoryButton onClick={handleClick} story={story}
                     disabled={playerState.player?.data.reaction_index !== undefined ? (story.meta.index > playerState.player?.data.reaction_index) : true}
                     hideAuthors={gameState.game?.settings?.hide_authors}/>

        <div className={"story-list-card-avatar"}>
            <Avatar4 name={story.captain.name} spriteId={story.captain.meta.sprite_id}/>
        </div>
    </div>
}

export default StoryListCard