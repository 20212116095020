import {MutableRefObject, useContext, useEffect, useRef, useState} from "react";
import {PlayerAction} from "../../../types";
import StateButton from "../../common/state-button/state-button";
import {useAppSelector} from "../../../redux/store";
import {GameMode} from "../../../types2";
import {GameContext} from "../../../contexts";

type Props = {
    timeRemainingRef: MutableRefObject<number>
    onPlayerAction: (playerAction: PlayerAction) => void

    done?: boolean
    onDone: () => void
}

const StoryFooter = ({done, onDone, timeRemainingRef}: Props) => {

    const {submit} = useContext(GameContext)

    const promptIndex = useRef(0)
    const bootSent = useRef(false)

    const playerState = useAppSelector(state => state.player.player)
    const gameState = useAppSelector(state => state.game.game)
    const metaState = useAppSelector(state => state.meta)

    const gameSettings = gameState?.settings

    const currentSubmission = playerState?.current_submission?.meta
    const gameHostId = gameState?.host_id

    const [promptValue, setPromptValue] = useState('need a prompt?')
    const [timeRemaining, setTimeRemaining] = useState<number>(timeRemainingRef.current)


    const paddedMinutes = Math.floor(timeRemaining / (1000 * 60)).toString().padStart(2, "0")
    const paddedSeconds = (Math.floor(timeRemaining / 1000) % 60).toString().padStart(2, "0")


    useEffect(() => {
        if ((gameSettings?.game_mode === GameMode.solo_mode || gameSettings?.forced_prompts) && currentSubmission?.prompt) {
            setPromptValue(currentSubmission.prompt)
        }
    }, [gameSettings?.forced_prompts, gameSettings?.game_mode, currentSubmission?.prompt])

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(timeRemainingRef.current)
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [timeRemainingRef])


    function handleBoot() {
        let playerAction = {
            body: {
                "vip_action": {
                    "skip_submission": true
                }
            },
            code: "game/vip",
        }
        submit(playerAction)
        bootSent.current = true
    }

    function handleDone() {
        onDone()
    }


    useEffect(() => {
        if (!metaState.loading && bootSent.current) {
            bootSent.current = false
        }

    }, [metaState.loading])

    function handleVip() {
        handleBoot()
    }

    // Mature and All Ages prompts are given 50/50 odds
    function handlePrompt() {
        if (playerState?.current_submission && playerState.current_submission.prompts) {
            let promptLength = playerState.current_submission.prompts.length
            setPromptValue(playerState.current_submission.prompts[promptIndex.current++ % promptLength])
        }

    }

    function disableSkip() {
        return (metaState.loading && bootSent.current) || timeRemaining <= 0
    }

    return <div className={"page-footer-story"}>
        {gameSettings?.game_mode !== GameMode.solo_mode ? <button className={"story-time-footer-prompt"}
                                                                  disabled={timeRemaining <= 0 || gameSettings?.forced_prompts}
                                                                  onClick={handlePrompt}>{promptValue}</button> : ''}


        <div className={"story-time-right-side"}>

            {(gameHostId === playerState?.id) && gameSettings?.game_mode !== GameMode.solo_mode ?
                <div className={"story-time-vip-button w-24"}>
                    <StateButton disabled={disableSkip()} onClick={handleVip}
                                 states={["force skip all", "are you sure?", "SKIP!"]}/>

                </div> : ''}

            <div className={"story-time-done-button"}>
                <button className={"story-time-vip-button-button"} disabled={timeRemaining <= 0 || metaState.loading}
                        onClick={handleDone}>{done ? "done! 🚩" : "i'm done!"}</button>
            </div>
            <div className={"story-time-footer-timer"}>
                <label className={"text-base"}>{timeRemaining <= 0 ? 'Time\'s up!' : "Timer"}</label>
                {/*<label className={"text-sm"}>{'Time\'s up!'}</label>*/}
                {timeRemaining <= 0 ? <strong>Saving...</strong> : timeRemaining < 10000 ?
                    <div className={"story-time-footer-warning"}>{`${paddedMinutes}:${paddedSeconds}`}</div> :
                    <div className={"story-time-footer-normal"}>{`${paddedMinutes}:${paddedSeconds}`}</div>}
            </div>
        </div>


    </div>

}

export default StoryFooter