import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";

export let blog4Title = "Entry #4 - Permalink Deprecation Announcement"

export let blog4Author = "Posted by Zee / Tuesday April 13th 2021"

export let blog4Content = <Body>
    <BodyText>Hi everyone! An announcement today for users who have played Frantic Fanfic in the past few weeks of
        launch. We're
        changing the sharing structure of stories due to a few reasons, but the biggest reason being we're just two
        people
        doing this game in our spare time and we want to have as much of our time back as possible, greedily enough
        haha.</BodyText>

    <BodyText>This announcement will hopefully not hurt your play of the game in general but it's something we've
        thought long and
        hard about with regards to very specific situations coming up needing a solve. An example of one of these
        situations
        would be… what if a user used someone's full name in a story and the user whose name was written was not
        comfortable
        with that. What happens then? They could always file a report and email us to take it down manually, but how
        scalable would that be if the game suddenly exploded in popularity? And so...</BodyText>

    <BodyText>To combat one-by-one takedown notices for harmful content like that we’ll be reversing our decision to
        publicly host
        all fanfics on a “permalink” when a user clicks the “permalink” button. We will no longer be archiving stories
        publicly for others to read.</BodyText>

    <BodyText>Instead, you can download your works and share them privately, and that decision to share/host is left up
        to the
        individual users. The content itself will be removed from the external portion of the site within 24 hours of
        clicking the “Export Story” button. This new "Export Story" button will export the story as a text
        file.</BodyText>

    <BodyText>We recognize that sharing fanfic is a big part of writing it! But as it stands, we’re a team of two doing
        this in
        our spare time and cannot dedicate the time to content review and takedowns if this game sparks and becomes more
        popular. We thought this was the best compromise so you can still spread your fics privately, but it's off the
        site
        after 24 hours. We also recognize the irony of calling that link the “permalink”, with this update it's more it
        should have said the "live on the site for two weeks link". But again, just two people, doing this in our spare
        time, we hope you can see the benefit to this to us and also to you. The writing and reading portion of the game
        is
        where we want to focus our work on the game. We also want to be able to create new characters and game modes!
        But if
        we're focused on doing takedowns all day... that's not going to be a great use of our time.</BodyText>

    <BodyText>For the players that have created those “permalinks” up to this point, we’re giving you one week to visit
        those
        “permalink” story pages and export them if you wish. After that, the links will stop working.</BodyText>

    <BodyText>We want to again thank everyone who took the time to play Frantic Fanfic since it launched. And we hope
        you'll
        continue playing and laughing!</BodyText>

    <BodyText>Zee and Michael</BodyText>

</Body>

export let blog = {
    title: blog4Title,
    author: blog4Author,
    content: blog4Content
}