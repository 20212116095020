import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {updateHiddenCode} from "../../../redux/slices/menu";
import {constants} from "../../../constants";
import "./room-code.css"
import {useState} from "react";

type Props = {
    hideOutline?: boolean

    minimize?: string
}

const RoomCode: React.FC<Props> = ({hideOutline}: Props) => {

    const codeHidden = useAppSelector(state => state.menu.hiddenCode)
    const roomCode = useAppSelector(state => state.menu.playerId?.room_code)

    const [hidden, setHidden] = useState(codeHidden)

    const dispatch = useAppDispatch()

    function handleClick() {
        dispatch(updateHiddenCode(!codeHidden))

        if (hideOutline) {
            setHidden(h => !h)
        }

    }

    function content() {
        if (roomCode) {
            return <label className={hideOutline ? 'app-top-roomLabel-no-border' : 'app-top-roomLabel'}
                          onClick={handleClick}><span

                className={"select-none text-center align-middle px-0"}>{!hidden && constants.key}</span><span
                className={"align-middle text-center"}>{hidden ? constants.littleTriangle : (codeHidden ? '****' : roomCode)}</span></label>
        } else {
            return <></>
        }
    }

    return content()
}

export default RoomCode