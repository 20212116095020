import ghost1 from './images/characters/00_ghost_1.png'
import ghost2 from './images/characters/00_ghost_2.png'
import demon1 from './images/characters/01_demon_1.png'
import raccoon1 from './images/characters/02_raccoon_1.png'
import trash1 from './images/characters/03_trash_1.png'
import dog1 from './images/characters/04_dog_1.png'
import spike1 from './images/characters/05_spike_1.png'
import armpit1 from './images/characters/06_armpit_1.png'
import cat1 from './images/characters/07_cat_1.png'
import rabbit1 from './images/characters/08_rabbit_1.png'
import potato1 from './images/characters/09_potato_1.png'
import potato2 from './images/characters/09_potato_2.png'
import buffpire1 from './images/characters/10_buffpire_1.png'
import buffpire2 from './images/characters/10_buffpire_2.png'
import burger1 from './images/characters/11_burger_1.png'
import dino1 from './images/characters/12_dino_1.png'
import kitsune1 from './images/characters/13_kitsune_1.png'
import kitsune2 from './images/characters/13_kitsune_2.png'
import protec1 from './images/characters/14_protec_1.png'
import ratty1 from './images/characters/15_ratty_1.png'
import reaper1 from './images/characters/16_reapr_1.png'
import reaper2 from './images/characters/16_reapr_2.png'
import skellybts1 from './images/characters/17_skellybts_1.png'
import skellybts2 from './images/characters/17_skellybts_2.png'
import teagor1 from './images/characters/18_teagor_1.png'
import wormman1 from './images/characters/19_wormman_1.png'
import wormman2 from './images/characters/19_wormman_2.png'
import hedgee1 from './images/characters/20_hedgee_1.png'
import opoosumc1 from './images/characters/21_opossum_1.png'
import poulet1 from './images/characters/22_poulet_1.png'
import plat1 from './images/characters/23_plat_1.png'


import ghost1med from './images/characters192/00_ghost_1.png'
import ghost2med from './images/characters192/00_ghost_2.png'
import demon1med from './images/characters192/01_demon_1.png'
import raccoon1med from './images/characters192/02_raccoon_1.png'
import trash1med from './images/characters192/03_trash_1.png'
import dog1med from './images/characters192/04_dog_1.png'
import spike1med from './images/characters192/05_spike_1.png'
import armpit1med from './images/characters192/06_armpit_1.png'
import cat1med from './images/characters192/07_cat_1.png'
import rabbit1med from './images/characters192/08_rabbit_1.png'
import potato1med from './images/characters192/09_potato_1.png'
import potato2med from './images/characters192/09_potato_2.png'
import buffpire1med from './images/characters192/10_buffpire_1.png'
import buffpire2med from './images/characters192/10_buffpire_2.png'
import burger1med from './images/characters192/11_burger_1.png'
import dino1med from './images/characters192/12_dino_1.png'
import kitsune1med from './images/characters192/13_kitsune_1.png'
import kitsune2med from './images/characters192/13_kitsune_2.png'
import protec1med from './images/characters192/14_protec_1.png'
import ratty1med from './images/characters192/15_ratty_1.png'
import reaper1med from './images/characters192/16_reapr_1.png'
import reaper2med from './images/characters192/16_reapr_2.png'
import skellybts1med from './images/characters192/17_skellybts_1.png'
import skellybts2med from './images/characters192/17_skellybts_2.png'
import teagor1med from './images/characters192/18_teagor_1.png'
import wormman1med from './images/characters192/19_wormman_1.png'
import wormman2med from './images/characters192/19_wormman_2.png'
import hedgee1med from './images/characters192/20_hedgee_1.png'
import opoosumc1med from './images/characters192/21_opossum_1.png'
import poulet1med from './images/characters192/22_poulet_1.png'
import plat1med from './images/characters192/23_plat_1.png'

import ghost1Small from './images/characters-small/00_ghost_1.png'
import ghost2Small from './images/characters-small/00_ghost_2.png'
import demon1Small from './images/characters-small/01_demon_1.png'
import raccoon1Small from './images/characters-small/02_raccoon_1.png'
import trash1Small from './images/characters-small/03_trash_1.png'
import dog1Small from './images/characters-small/04_dog_1.png'
import spike1Small from './images/characters-small/05_spike_1.png'
import armpit1Small from './images/characters-small/06_armpit_1.png'
import cat1Small from './images/characters-small/07_cat_1.png'
import rabbit1Small from './images/characters-small/08_rabbit_1.png'
import potato1Small from './images/characters-small/09_potato_1.png'
import potato2Small from './images/characters-small/09_potato_2.png'
import buffpire1Small from './images/characters-small/10_buffpire_1.png'
import buffpire2Small from './images/characters-small/10_buffpire_2.png'
import burger1Small from './images/characters-small/11_burger_1.png'
import dino1Small from './images/characters-small/12_dino_1.png'
import kitsune1Small from './images/characters-small/13_kitsune_1.png'
import kitsune2Small from './images/characters-small/13_kitsune_2.png'
import protec1Small from './images/characters-small/14_protec_1.png'
import ratty1Small from './images/characters-small/15_ratty_1.png'
import reaper1Small from './images/characters-small/16_reapr_1.png'
import reaper2Small from './images/characters-small/16_reapr_2.png'
import skellybts1Small from './images/characters-small/17_skellybts_1.png'
import skellybts2Small from './images/characters-small/17_skellybts_2.png'
import teagor1Small from './images/characters-small/18_teagor_1.png'
import wormman1Small from './images/characters-small/19_wormman_1.png'
import wormman2Small from './images/characters-small/19_wormman_2.png'
import hedgee1Small from './images/characters-small/20_hedgee_1.png'
import opoosumc1Small from './images/characters-small/21_opossum_1.png'
import poulet1Small from './images/characters-small/22_poulet_1.png'
import plat1Small from './images/characters-small/23_plat_1.png'

import ghost1XSmall from './images/characters-xsmall/00_ghost_1.png'
import ghost2XSmall from './images/characters-xsmall/00_ghost_2.png'
import demon1XSmall from './images/characters-xsmall/01_demon_1.png'
import raccoon1XSmall from './images/characters-xsmall/02_raccoon_1.png'
import trash1XSmall from './images/characters-xsmall/03_trash_1.png'
import dog1XSmall from './images/characters-xsmall/04_dog_1.png'
import spike1XSmall from './images/characters-xsmall/05_spike_1.png'
import armpit1XSmall from './images/characters-xsmall/06_armpit_1.png'
import cat1XSmall from './images/characters-xsmall/07_cat_1.png'
import rabbit1XSmall from './images/characters-xsmall/08_rabbit_1.png'
import potato1XSmall from './images/characters-xsmall/09_potato_1.png'
import potato2XSmall from './images/characters-xsmall/09_potato_2.png'
import buffpire1XSmall from './images/characters-xsmall/10_buffpire_1.png'
import buffpire2XSmall from './images/characters-xsmall/10_buffpire_2.png'
import burger1XSmall from './images/characters-xsmall/11_burger_1.png'
import dino1XSmall from './images/characters-xsmall/12_dino_1.png'
import kitsune1XSmall from './images/characters-xsmall/13_kitsune_1.png'
import kitsune2XSmall from './images/characters-xsmall/13_kitsune_2.png'
import protec1XSmall from './images/characters-xsmall/14_protec_1.png'
import ratty1XSmall from './images/characters-xsmall/15_ratty_1.png'
import reaper1XSmall from './images/characters-xsmall/16_reapr_1.png'
import reaper2XSmall from './images/characters-xsmall/16_reapr_2.png'
import skellybts1XSmall from './images/characters-xsmall/17_skellybts_1.png'
import skellybts2XSmall from './images/characters-xsmall/17_skellybts_2.png'
import teagor1XSmall from './images/characters-xsmall/18_teagor_1.png'
import wormman1XSmall from './images/characters-xsmall/19_wormman_1.png'
import wormman2XSmall from './images/characters-xsmall/19_wormman_2.png'
import hedgee1XSmall from './images/characters-xsmall/20_hedgee_1.png'
import opoosumc1XSmall from './images/characters-xsmall/21_opossum_1.png'
import poulet1XSmall from './images/characters-xsmall/22_poulet_1.png'
import plat1XSmall from './images/characters-xsmall/23_plat_1.png'


import React from "react";

import buffpireBig from './images/bigchar/10_buffpire_2.png'
import buffpire2Big from './images/bigchar/10_buffpire_3.png'
import burgerBig from './images/bigchar/11_burger_2.png'
import ghostBig from './images/bigchar/00_ghost_2.png'
import ghost2Big from './images/bigchar/00_ghost_3.png'
import demonBig from './images/bigchar/01_demon_2.png'
import raccoonBig from './images/bigchar/02_raccoon_2.png'
import trashBig from './images/bigchar/03_trash_2.png'
import dogBig from './images/bigchar/04_dog_2.png'
import guyBig from './images/bigchar/05_spike_2.png'
import armpitBig from './images/bigchar/06_armpit_2.png'
import catBig from './images/bigchar/07_cat_2.png'
import rabbitBig from './images/bigchar/08_rabbit_2.png'
import potatoBig from './images/bigchar/09_potato_2.png'
import potato2Big from './images/bigchar/09_potato_3.png'
import dinoBig from './images/bigchar/12_dino_2.png'
import kitsuneBig from './images/bigchar/13_kitsune_2.png'
import kitsune2Big from './images/bigchar/13_kitsune_3.png'
import protecBig from './images/bigchar/14_protec_2.png'
import rattyBig from './images/bigchar/15_ratty_2.png'
import reaprBig from './images/bigchar/16_reapr_2.png'
import reapr2Big from './images/bigchar/16_reapr_3.png'
import skellybtsBig from './images/bigchar/17_skellybts_2.png'
import skellybts2Big from './images/bigchar/17_skellybts_3.png'
import teagorBig from './images/bigchar/18_teagor_2.png'
import wormanBig from './images/bigchar/19_wormman_2.png'
import worman2Big from './images/bigchar/19_wormman_3.png'
import hedgeeBig from './images/bigchar/20_hedgie_2.png'
import opoosumcBig from './images/bigchar/21_opossum_2.png'
import pouuletBig from './images/bigchar/22_poulet_2.png'
import platBig from './images/bigchar/23_plat_2.png'

import dogpet from './images/characters/04_dog_2.png'
import {FontOptions} from "./types2";

export const headers = {
    Lobby: "the sacred hall of fanfic",
}

export const constants = {
    sonicSpeed: 10, // 10 sec
    lightningSpeed: 60, // 1 minute
    franticSpeed: 180, // 3 minute
    briskSpeed: 300, // 5 minute
    leisureSpeed: 600, // 10 min

    leftButton: '<',
    rightButton: '>',
    bigTriangle: '△',
    littleTriangle: '▸',
    littleTriangleDown: '▾',
    audience: '👥',
    key: "⚿",
    trophy: '🏆',
    star: '⭐',
    emojis: ["🙏", "💩", "🤪", <span className={"text-red-600"}>❤️</span>, "🔥", "😂", "⭐"],

    accessibleStyle: function (accessibility?: FontOptions): any {
        if (accessibility === FontOptions.DYSLEXIA) {
            return {textTransform: "none", fontFamily: "Open-Dyslexic"}
        }
        if (accessibility === FontOptions.COMIC_SANS) {
            return {textTransform: "none", fontFamily: "Comic Sans"}
        }
        return {}
    }
}

export const config = {
    // apiHost: "https://api.franticfanfic.com/",
    // wsHost: "wss://api.franticfanfic.com/ws",

    apiHost: process.env.REACT_APP_API_HOST,
    wsHost: process.env.REACT_APP_WS_HOST,

    // gameUrl: "ws://192.168.1.17:8081",
    numChoices: 2,
    numVotes: 1,

    minimumLobbySize: parseInt(process.env.REACT_APP_MIN_LOBBY_SIZE ?? '1'),

    canReactToSelf: process.env.REACT_APP_SELF_REACT === 'true',

    canCensorSelf: process.env.REACT_APP_SELF_CENSOR === 'true',

    dontTwitch: process.env.REACT_APP_DONT_TWITCH === 'true',

    doSonic: process.env.REACT_APP_DO_SONIC === 'true',

    maxSubmissionSize: 1000 * 2 * 7,

    longPaste: process.env.REACT_APP_LONG_PASTE === 'true',
    fastTransition: process.env.REACT_APP_FAST_TRANSITION === 'false',

    auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "dev-nz7-csgn.us.auth0.com",
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENTID ?? "X1mVAwn8EuIXaE11eqxUkqGNqXRYWWyX",
    auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? "franticfanfic.com",

    stripeEndpoint: process.env.REACT_APP_STRIPE_ENDPOINT,

    development: process.env.REACT_APP_DEVELOPMENT === 'true',


    pouletUrl: "https://store.steampowered.com/app/1618510/Poulet_Poulet/",

    avatarsXSmall: [ghost1XSmall, demon1XSmall, raccoon1XSmall, trash1XSmall, dog1XSmall, spike1XSmall, armpit1XSmall, cat1XSmall, rabbit1XSmall, potato1XSmall, buffpire1XSmall,
        burger1XSmall, dino1XSmall, kitsune1XSmall, protec1XSmall, ratty1XSmall, reaper1XSmall, skellybts1XSmall, teagor1XSmall, wormman1XSmall, hedgee1XSmall, opoosumc1XSmall,
        poulet1XSmall, plat1XSmall, ghost2XSmall, potato2XSmall, buffpire2XSmall, kitsune2XSmall, reaper2XSmall, skellybts2XSmall, wormman2XSmall],

    avatarsSmall: [ghost1Small, demon1Small, raccoon1Small, trash1Small, dog1Small, spike1Small, armpit1Small, cat1Small, rabbit1Small, potato1Small, buffpire1Small,
        burger1Small, dino1Small, kitsune1Small, protec1Small, ratty1Small, reaper1Small, skellybts1Small, teagor1Small, wormman1Small, hedgee1Small, opoosumc1Small,
        poulet1Small, plat1Small, ghost2Small, potato2Small, buffpire2Small, kitsune2Small, reaper2Small, skellybts2Small, wormman2Small],

    avatars: [ghost1, demon1, raccoon1, trash1, dog1, spike1, armpit1, cat1, rabbit1, potato1, buffpire1,
        burger1, dino1, kitsune1, protec1, ratty1, reaper1, skellybts1, teagor1, wormman1, hedgee1, opoosumc1,
        poulet1, plat1, ghost2, potato2, buffpire2, kitsune2, reaper2, skellybts2, wormman2],

    avatars192: [ghost1med, demon1med, raccoon1med, trash1med, dog1med, spike1med, armpit1med, cat1med, rabbit1med, potato1med, buffpire1med,
        burger1med, dino1med, kitsune1med, protec1med, ratty1med, reaper1med, skellybts1med, teagor1med, wormman1med, hedgee1med, opoosumc1med,
        poulet1med, plat1med, ghost2med, potato2med, buffpire2med, kitsune2med, reaper2med, skellybts2med, wormman2med],

    avatarsSpecial: [ghost1, demon1, raccoon1, trash1, dogpet, spike1, armpit1, cat1, rabbit1, potato1, buffpire1,
        burger1, dino1, kitsune1, protec1, ratty1, reaper1, skellybts1, teagor1, wormman1, hedgee1, opoosumc1,
        poulet1, plat1, ghost2, potato2, buffpire2, kitsune2, reaper2, skellybts2, wormman2],

    avatarsBig: [ghostBig, demonBig, raccoonBig, trashBig, dogBig, guyBig, armpitBig, catBig, rabbitBig, potatoBig,
        buffpireBig, burgerBig, dinoBig, kitsuneBig, protecBig, rattyBig, reaprBig, skellybtsBig, teagorBig,
        wormanBig, hedgeeBig, opoosumcBig, pouuletBig, platBig, ghost2Big, potato2Big, buffpire2Big, kitsune2Big, reapr2Big, skellybts2Big, worman2Big]

}
