import {StorySubmission} from "../../../types";
import {useAppSelector} from "../../../redux/store";
import React, {useEffect, useState} from "react";
import CensorButton from "../censor-button/censor-button";
import {constants} from "../../../constants";

type PrevSubmissionProp = {
    onToggle?: (b: boolean) => void
    submission: StorySubmission

    canFlag?: boolean
    playerId?: number
    isCensored?: boolean
}

export const PrevSubmission = ({onToggle, submission, canFlag, playerId}: PrevSubmissionProp) => {
    const gameState = useAppSelector(state => state.game)
    const accessibility = useAppSelector(state => state.player.player?.meta.accessibility2)

    const player = playerId as number
    const [flagged, setFlagged] = useState(new Set(submission?.meta?.flagged))

    const moderation = gameState.game?.settings?.moderation

    function handleChange(checked: boolean) {
        setFlagged(f => {
            if (checked) {
                f.add(player)
            } else {
                f.delete(player)
            }
            return new Set(f)
        })
        if (onToggle) {
            onToggle(checked)
        }
    }

    return <>
        <div style={constants.accessibleStyle(accessibility)}
             className={"break-words max-w-full"}>{flagged.has(player) ?
            <span>■TEXT REMOVED■</span> : submission.content}</div>
        {(canFlag) && onToggle && moderation ?
            <CensorButton index={submission.index} onChange={handleChange} checked={flagged.has(player)}/>
            : ''}</>
}

export const PrevSubmission2 = ({onToggle, submission, canFlag, isCensored}: PrevSubmissionProp) => {
    const gameState = useAppSelector(state => state.game)
    const accessibility = useAppSelector(state => state.player.player?.meta.accessibility2)

    const moderation = gameState.game?.settings?.moderation

    const [checked, setChecked] = useState(isCensored ?? false)


    function handleChange(checked: boolean) {
        setChecked(checked)

        if (onToggle) {
            onToggle(checked)
        }
    }

    return <div>
        <div style={constants.accessibleStyle(accessibility)}>{checked ?
            <span style={{fontFamily: "FSEX302, serif"}}>■TEXT REMOVED■</span> : submission.content}</div>
        {(canFlag) && onToggle && moderation ?
            <CensorButton index={submission.id} onChange={handleChange} checked={checked}/> : ''}

    </div>
}

export const PrevSubmissionRead = ({submission, isCensored}: PrevSubmissionProp) => {
    const accessibility = useAppSelector(state => state.player.player?.meta.accessibility2)

    const [censored, setCensored] = useState(isCensored)


    useEffect(() => {
        setCensored(isCensored)
    }, [isCensored])


    return <div>
        <div className={"break-words"} style={constants.accessibleStyle(accessibility)}>{censored ?
            <span className={"font-sans"}>■TEXT REMOVED■</span> : submission.content}</div>
    </div>
}