import {useAuth0} from "@auth0/auth0-react";

type Props = {
    displayText: string
    disabled: boolean
}

const LoginButton: React.FC<Props> = ({displayText, disabled}) => {
    const {loginWithPopup} = useAuth0();


    return <button disabled={disabled} className={"bg-black text-white px-4"} onClick={(e) => {
        e.preventDefault()
        loginWithPopup({}, {})
    }}>{displayText}</button>;
};

export default LoginButton