import React from "react";
import {Story} from "../../../types";
import "./styles.css"
import {constants} from "../../../constants";
import {SafeJoin} from "../../../util";


type Props = {
    onClick?: () => void
    story: Story
    disabled?: boolean
}
const emojis = constants.emojis//["🙏", "🌶️", "👀", "🧠", "💩", "🤪", <span style={{color: "red"}}>❤️</span>, "🔥", "😂", "⭐"]

const StoryButton2 = ({onClick, story, disabled}: Props) => {

    function handleClick() {
        if (onClick) {
            onClick()
        }
    }

    function reactions1(story: Story) {
        let entries = Object.entries(story.meta.reactions).sort((a, b) => b[1] - a[1])
        return entries.map<React.ReactNode>(value => <span
            key={"emoji-" + value}>{emojis[parseInt(value[0])]}<sub>{value[1]}</sub></span>)
    }

    function awards1(story: Story) {
        return story.meta.awards.map<React.ReactNode>(s => <span
            key={"trophy-" + s}>{constants.trophy + s + constants.trophy}</span>)
    }


    function storyTitle(story: Story) {
        return <div className={"story-button-content"}>
            <div className={"text-xl underline font-bold break-words"}>{story.meta.title}</div>
            <div>
                CHARACTERS: {SafeJoin(story.characters?.filter(c => c.selected).map<React.ReactNode>(c =>
                <span className={"italic"}
                      key={"char-" + c.id}>{c.name}</span>), ', ')}
            </div>

            <div>
                WRITERS: {SafeJoin(story.submissions?.map<React.ReactNode>(s =>
                <span className={"italic"}
                      key={"player-" + s.player_id + "-" + s.id}>{s.player.name}</span>), ', ')}
            </div>

            <div>
                {story.settings.mature ? 'rated 18+' : 'rated all ages'}
            </div>

        </div>
    }

    function storyWinnings(story: Story) {
        return <>
            {story.meta.awards && <><span className={"italic font-bold"}>AWARD WINNER:</span>
                {awards1(story)}<br/></>}
            {story.meta.reactions && <span><b style={{fontStyle: "italic"}}>REACTS:</b> {reactions1(story)} </span>}
        </>
    }

    return <div className={"story-button2-wrapper"}>
        {/*<div className={"story-results2-story-placing"}>#{idx + 1} - {story.meta.points}</div>*/}
        <div className={"story-button2-container"}>
            {onClick ? <button className={"story-button2"} disabled={disabled}
                               onClick={handleClick}>{storyTitle(story)}</button> :
                <div className={"story-button2 fake-button"}>{storyTitle(story)}</div>}

            <div className={"story-winnings"}>{storyWinnings(story)}</div>

            {/*<div className={"story-results2-title"}>{story.title}</div>*/}
        </div>
    </div>
}

export default StoryButton2