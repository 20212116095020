import {useTranslation} from "react-i18next";
import SquareRadio2 from "../square-radio/square-radio2";
import GameSettingsLabel from "../game-settings-label/game-settings-label";

type BaseProps<T> = {
    label: string
    subLabel?: string

    value: T

    disabled?: boolean
    radioSubheader?: string
    hideLabel ?: boolean

}

export type Option<T> = {
    value: T
    label?: string
}


type Props<T> = BaseProps<T> & {
    options: Option<T>[]

    onSelect: (val: string) => void
}

type BoolProps = BaseProps<boolean> & {

    onSelect: (val: boolean) => void

    noLabel?: string
    yesLabel?: string

}


const RadioButtons = <T extends number | string>(props: Props<T>) => {

    return <div className={"square-radio-group"}>
        {!props.hideLabel && <GameSettingsLabel label={props.label} subLabel={props.subLabel}/>}

        <div className={"create-game-button-container"}>
            <SquareRadio2 disabled={props.disabled} name={props.label.replace(' ', '-')} options={props.options}
                          subHeader={props.radioSubheader}
                          value={props.value} onSelect={props.onSelect}/>
        </div>
    </div>

}

export function YesNoRadioButtons(props: BoolProps) {

    const {t} = useTranslation('common')

    return <RadioButtons label={props.label} subLabel={props.subLabel}
                         options={[{label: props.noLabel ?? t('label.no'), value: 'false'}, {
                             label: props.yesLabel ?? t('label.yes'),
                             value: 'true'
                         }]}
                         disabled={props.disabled}
                         hideLabel={props.hideLabel}
                         value={props.value.toString()}
                         onSelect={val => props.onSelect(val === 'true')}/>

}

export default RadioButtons