import React, {Suspense, useEffect} from 'react';
import Menu, {PageNumber} from "./components/menu/menu";
import RoomAPI from "./RoomAPI";
import {useAppDispatch, useAppSelector} from "./redux/store";
import {updateConfig, updateCount} from "./redux/slices/meta";
import Loading from "./components/menu/loading/loading";
import {config} from "./constants";
import {updateUser} from "./redux/slices/menu";
import {useAuth0} from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import LogRocket from 'logrocket';

type Props = {
    initialPage?: PageNumber
}

const premiumClaim = 'https://franticfanfic.com/premium'
const connClaim = 'https://franticfanfic.com/conn'
const screenName = 'https://franticfanfic.com/screen_name'

const App = ({initialPage}: Props) => {

    // before we join a game
    const dispatch = useAppDispatch()
    const {isAuthenticated, getAccessTokenSilently, user} = useAuth0();
    const selectUser = useAppSelector(state => state.menu.user)

    useEffect(() => {
        let interval = setInterval(() => {
            RoomAPI.GetCount().then(count => dispatch(updateCount(count)))
        }, 30000)

        async function handleCount() {
            let count = await RoomAPI.IncrCount()
            await dispatch(updateCount(count))
        }

        handleCount().catch(console.error)

        return () => {
            clearInterval(interval)
        };
    }, [dispatch]);

    useEffect(() => {
        RoomAPI.GetConfig().then(c => {
            dispatch(updateConfig(c))
        })

    }, [dispatch])

    useEffect(() => {
        async function getUserMetadata() {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {audience: config.auth0Audience},
            });

            const decoded: any = jwtDecode(accessToken);

            return {premium: decoded[premiumClaim], conn: decoded[connClaim], name: decoded[screenName], id: user?.sub}
        }

        async function getFranticFanficUser() {
            const token = await getAccessTokenSilently()
            return await RoomAPI.GetUser(token)
        }

        async function both() {
            const {conn, name, premium, id} = await getUserMetadata()
            const {user_id, rank} = await getFranticFanficUser()

            dispatch(updateUser({
                user_id: user_id,
                name: name ?? user?.name,
                premium: premium,
                rank: rank,
                auth0Id: id ?? "",
                connection: conn
            }))

            if (id) {
                LogRocket.init('wkxouc/franticfanfic');
                LogRocket.identify(id, {
                    name: name,
                    premium: premium
                })
            }

        }

        if (isAuthenticated && selectUser?.auth0Id !== user?.sub) {
            both().catch(console.error)
        }


    }, [dispatch, getAccessTokenSilently, isAuthenticated, selectUser?.auth0Id, user?.name, user?.sub])


    return <Suspense fallback={<Loading/>}><Menu initialPage={initialPage}/></Suspense>


}

export default App;

