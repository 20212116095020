import React from "react";
import Body from "../../components/common/body/body";
import BodyTitle from "../../components/common/body-title/body-title";
import BodyText from "../../components/common/body-text/body-text";

export let blogTitle = "Merry Christmas"

export let blogAuthor = "Posted by Zee / Friday Dec 24 2021"

export let blogContent = <Body>
    <BodyTitle>Happy holidays to all! </BodyTitle>
    <BodyText>We're pushing a stability update that should fix some bugs users were experiencing.

        As usual if you see anything that looks wonky, email us at franticfanficgame@gmail.com.
        <br/>
        <br/>
        If you've enjoyed our game, make sure you tell your friends about all the weird fandom crack
        way-better-than-a-random-generator fanfic you've made on our site!
        That would be the best Christmas present to the dev team :). That or turning off adblock lol, gotta pay the
        bills ya know.
        <br/>
        <br/>
        Oh, and follow us on <a href={"https://www.tiktok.com/@franticfanfic"}>TikTok</a>!

    </BodyText>

    <BodyTitle>OMG made|nous feature!</BodyTitle>
    <BodyText>Wow!!! We were featured on <a
        href={"https://made-nous.ca/made-recommends/frantic-fanfic/"}>Made|Nous</a> as a
        great Canadian game! Thank you
        so much for the nomination and
        writeup, <a href={"https://twitter.com/indigodoyle"}>Indigo</a>! We're right beside FarCry 6 in the reccs LOL
        nice. </BodyText>

    <BodyTitle>We're still working on that big update!</BodyTitle>
    <BodyText>It's not done yet haha. but we're workin on it! New, useful features like the ability to log in and track
        how
        many stories you've written and the long-awaited points system update!
        <br/>
        <br/>
        We're also giving supporters of Frantic Fanfic some in-game gifts for being awesome people. The game will always
        be free to play, but supporters will get cosmetic and game-changing options like... new game theme colours,
        additional prompt packs, alternate character icons. Everyone who has already supported us on KO-FI will get one
        supporter badge free. If you want to get ahead of that...<a href={"https://ko-fi.com/franticfanfic"}>Ko-Fi</a>
        <br/><br/> But that's the next update.</BodyText>


    <BodyText bold>Thanks so much for playing!</BodyText>

    <BodyText>The Frantic Fanfic Dev Team. <br/>(Michael and Zee!)</BodyText>
</Body>

export let blog = {
    title: blogTitle,
    author: blogAuthor,
    content: blogContent
}