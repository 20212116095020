import {useAuth0} from "@auth0/auth0-react";

type Props = {
    displayText: string
    disabled: boolean
}

const LogoutButton: React.FC<Props> = ({displayText, disabled}) => {
    const {logout} = useAuth0();

    return (
        <button disabled={disabled} className={"px-1"} onClick={(e) => {
            e.preventDefault();
            logout({logoutParams: {returnTo: window.location.href}})
        }}>
            {displayText}
        </button>
    );
};

export default LogoutButton