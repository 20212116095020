type Props = {
    label: string

    onSelect: (checked: boolean) => void
    selected?: boolean

    disabled?: boolean
}

const CheckButton = (props: Props) => {
    return <div className={"flex-grow flex flex-row space-x-2"}>

        <label
            className={`flex-grow bg-gray-drk shadow-basic flex justify-center items-center ${props.disabled && 'opacity-50'}`}
            htmlFor={`${props.label}checkbox`}>{props.label}</label>

        <div className={"self-center"}>
            <input disabled={props.disabled} checked={props.selected} id={`${props.label}checkbox`}
                   onChange={e => props.onSelect(e.target.checked)}
                   className={"self-center align-middle scale-150"} type={"checkbox"}/>
        </div>

    </div>
}

export default CheckButton