import React from "react";
import Footer from "../footer/footer";
import './styles.css'
import PageContainer from "../page-container/page-container";
import {Countdown} from "../../../types";
import Subheader from "../subheader/subheader";


export type Props = {
    roomCode?: string
    onBack?: () => void

    footerLabel?: string
    footerJsx?: JSX.Element
    footer?: JSX.Element

    header?: JSX.Element
    headerLabel?: string

    subHeaderJsx?: JSX.Element
    subHeaders?: string[]

    hack?: JSX.Element[]
    connected?: boolean
    countdown?: Countdown
    countdownThreshold?: number

    leftLabel?: string
    extraTopLabel?: string
    error?: string

    hideConnected?: boolean
    children?: React.ReactNode


}


const Page: React.FunctionComponent<Props> = props => {


    return (
        <PageContainer
            connected={props.connected}
            leftLabel={props.leftLabel}
            roomCode={props.roomCode}
            hideConnected={props.hideConnected}
            extraLabel={props.extraTopLabel}>
            <div id={"book-container"} className={"mx-0 md:mx-4 flex flex-col justify-center flex-grow relative"}>
                <div id={"book"}
                     className={`shadow-none border-x-0 border-y-8 border-black mx-0 bg-gray-light flex-grow flex flex-col justify-center 
                      sm:border-x-8 sm:shadow-aesthetic`}>
                    {props.header}
                    <Subheader subheaders={props.subHeaders}/>
                    {props.subHeaderJsx ? <div className={"book-subheader"}>▸ {props.subHeaderJsx}</div> : ''}
                    <div className={"page-body"}>
                        {props.children}
                    </div>
                    {props.footer ? props.footer :
                        <Footer onBack={props.onBack} text={props.footerLabel} jsx={props.footerJsx}/>}
                </div>
                {props.hack ? props.hack : ''}
            </div>
        </PageContainer>);
}


export default Page