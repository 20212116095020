import {terms} from "../../../text/terms";
import MenuPage from "../menu-page/menu-page";
import {useTranslation} from "react-i18next";


const Terms = () => {

    const {t} = useTranslation('main', {keyPrefix: "terms"})

    return <MenuPage titles={[t('headers.default')]}>
        <div className={"m-4 text-base normal-case font-comic"}>
            {terms}
        </div>
    </MenuPage>
}

export default Terms