import React from "react";
import squarestore from "../../images/squarestore.png"
import lobbies from "../../images/lobbies3 (2).png"
import zine from "../../images/zine.webp"
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";
import BodyTitle from "../../components/common/body-title/body-title";
import BodyList, {BodyListItem} from "../../components/common/body-list/body-list";

export let blogTitle = "AUGUST 8th! IT'S BEEN A MONTH!!"

export let blogAuthor = "Posted by Michael / Wednesday Aug 08 2021"

export let blogContent = <Body>
    <BodyText>Omg it’s been a MONTH! Jeeze! We’ve been SO BUSY and didn’t want to submit these updates all piecemeal like… so
        here’s a big juicy update. ALSO WE HIT 100,000 PLAYERS THIS PAST WEEKEND THAT'S SO COOL WHAT?!? Thanks for
        playing!</BodyText>

    <BodyTitle>BYE BYE ETSY: HELLO SQUARE</BodyTitle>
    <BodyText>First update is we got banned on Etsy for… no reason at all and we’re not getting our account back. BOOO. So Zee
        set up a new account here: <a href={"https://franticfanfic.square.site"}>franticfanfic.square.site</a>. We also
        made a few different products as we had to do it all from scratch again… it was a whole thing. Sigh. Anyhoo, if
        you have any merch suggestions let us know! Our shop is
        99% drop shipping right now (as in we carry no stock and everything is made to order) but we’ll soon have
        physical zine copies and enamel pins :3. The 1% is a thank you sketch which Zee will draw and send to you! Snail
        mail style! Cute huh?!
    </BodyText>
    <BodyText>Here’s a screenie of that site:</BodyText>
    <a href={"https://franticfanfic.square.site"}><img width={"100%"} height={"auto"} src={squarestore} alt={squarestore}/></a>

    <BodyTitle>AUDIENCE!! AUDIENCE!! </BodyTitle>
    <BodyText>You can now join a game as an audience member! As an audience member you can influence the outcome of game by
        using reacts
        and ALSO choosing which stories deserve the coveted audience award! The audience award is only
        available when there’s at least one audience member in chat. This update will have a second part eventually, but
        for now let your chat know they can
        login as an audience member and vote it up :&gt;!
    </BodyText>

    <BodyTitle>A NEW MODE!</BodyTitle>
    <BodyText>New mode alert!! New mode alert!!! There’s a new mode! This one was suggested as a “broken telephone” type mode,
        but we decided it was less like a broken telephone and more like you’re missing pages out of a telephone book
        haha. It’s exactly like vanilla Frantic Fanfic but you only have access to the part written right before yours
        while writing. So it’s a bit chaotic… a bit mysterious... and a lot of fun! So try it out!</BodyText>

    <BodyTitle>TWO PLAYER MODE IS FIXED</BodyTitle>
    <BodyText>We fixed the ugly voting with two player mode… it wasn’t really making much sense so we got rid of it. Now you
        can play with two players! We do still recommend playing with four or more for the best experience, but two
        players is
        nice for a chill writing night.
    </BodyText>

    <BodyTitle>CENSOR CLEANED UP</BodyTitle>
    <BodyText>Our censor button in moderation mode has been cleaned up a bit - now it carries through to multiple
        submissions. If one person has censored a part, it will remain flagged and censored. Everyone else can view it
        if they are curious if they click on it, but it will still remain flagged to the VIP for their final look at the
        end of the writing
        portion of the game.
    </BodyText>

    <BodyTitle>LEAVING? SO SOON? THAT’S FINE, I’LL KICK YOU OUT!</BodyTitle>
    <BodyText>Isn’t it annoying leaving games hanging? Or AFK players being stuck in the lobby so you need to create a new
        game? Well annoying no more! New features! These are also helpful if you're streaming and people with bad
        usernames try to pop in. We have a slur filter on our usernames but you know, if people try to bypass that.</BodyText>
    <BodyList>
        <BodyListItem> The VIP can now kick players from the lobby screen.</BodyListItem>
        <BodyListItem> The VIP can also close the game.</BodyListItem>
        <BodyListItem> Players can now leave games of their own free will as well.</BodyListItem>
    </BodyList>
    <BodyText>Isn’t that nice?! We think that’s so nice.</BodyText>


    <BodyTitle>IPHONE FIXES</BodyTitle>
    <BodyText>We’ve heard of a few things going a bit wonky with Iphone players, kinda hard for us to test that since we don’t
        have a recent iPhone but we think we’ve fixed the issues (again) (hopefully!). Especially that weird react bug.
        That should be gone
        now. Let us know if you still see anything!
    </BodyText>

    <BodyTitle>ZINE!!!!</BodyTitle>
    <BodyText>Our zine is out and available to purchase on Ko-Fi! 82 pages of behind the scenes and fun for Frantic Fanfic!
        Included in one of the pages are the CLUES to access our secret character, which if you’ve seen Zee streaming
        she’s been using him :3. Plus all the proceeds go to funding the game and the servers and database stuff so
        that’s cool Support Frantic Fanfic! Tell ya friends about us :&gt; Stream the game!! Wooo!! A physical version
        is
        coming but yeah, not quite yet lol. In the meantime! DIGITAL!</BodyText>
    <BodyText><a href={"https://ko-fi.com/s/42a7b48e13"}>Link to purchase our Zine on Ko-Fi here!</a></BodyText>
    <a href={"https://ko-fi.com/s/42a7b48e13"}><img height={"auto"} width={"100%"} src={zine} alt={zine}/></a>

    <BodyTitle>NOTE FOR NEXT TIME:</BodyTitle>
    <BodyText>We’ve mocked up our online play screens and are working pretty hard on making the MOST REQUESTED NEW FEATURE
        happen! Public lobbies!! Here’s a small preview of what they’ll look like!
    </BodyText>
    <img width={"100%"} height={"auto"} src={lobbies} alt={lobbies}/>

    <BodyText>WHEW! Big updates. We also did a couple interviews this month, one with Paige at Digital Trends and another with
        The One True Podcast <a href={"https://twitter.com/theonetruepod"}>@theonetruepod</a>. They were both super fun! Thanks for chatting with us
        and we're always down to chat about this weird silly game we made hahaha.
    </BodyText>

</Body>

export let blog = {
    title: blogTitle,
    author: blogAuthor,
    content: blogContent
}