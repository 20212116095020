type Props = {
    disabled?: boolean
    value: number

    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    checked: boolean
    label: string
}

const ThemeSelect: React.FC<Props> = ({disabled, value, onChange, checked, label}: Props) => {
    return <div className={`square-radio2 p-0 ${disabled && 'opacity-50'} break-words`}>
        <input name="color-scheme" type="radio"
               id={`color-scheme-${value}`}
               value={value}
               disabled={disabled}
               onChange={onChange}
               checked={checked}/>
        <label className={`text-s p-0 ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
               htmlFor={`color-scheme-${value}`}>{label}</label></div>
}

export default ThemeSelect