import React from "react";
import Waiting from "../../game/waiting/waiting";
import {Player, PlayerAction} from "../../../types";
import {GameStatus} from "../../game/game";
import {useAppSelector} from "../../../redux/store";

type Props = {

    playerActionHandler?: (playerAction: PlayerAction) => void
    waitingFor: GameStatus[]
    waitHeader: string

    subHeader?: string
    audienceOverride?: boolean
    children?: React.ReactNode
}

const Waitable: React.FunctionComponent<Props> = props => {

    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)
    const metaState = useAppSelector(state => state.meta)

    function _audienceWait() {
        return playerState.player?.data.audience && !props.audienceOverride
    }

    function _statusWait() {
        return playerState.player && (props.waitingFor.includes(playerState.player?.status))
    }

    function shouldWait() {
        return _audienceWait() || _statusWait()
    }

    function skippyText() {
        if (gameState.game?.status === GameStatus.GameStatusLobby || gameState.game?.status === GameStatus.GameStatusQuickStart || gameState.game?.status === GameStatus.GameStatusCharacters || gameState.game?.status === GameStatus.GameStatusStoryCreate) {
            return "you can kick players by clicking on them and clicking kick."
        } else if (gameState.game?.status === GameStatus.GameStatusStoryTitle || gameState.game?.status === GameStatus.GameStatusVoting) {
            return "you can skip players by selecting them and then clicking skip."
        }
    }

    function filter(p: Player) {
        return !props.waitingFor.includes(p.status) && p.id !== playerState.player?.id
    }

    return <>
        {shouldWait() ?
            <Waiting
                subHeader={skippyText()}
                // players={gameState.game?.players}
                players={gameState.game?.players ? gameState.game?.players.filter(filter) : []}
                header={props.waitHeader} onPlayerAction={props.playerActionHandler} loading={metaState.loading}
                countdown={gameState.game?.countdown}/> : props.children}
    </>
}

export default Waitable