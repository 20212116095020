import React from "react";
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";
import BodyTitle from "../../components/common/body-title/body-title";
import BodyList, {BodyListItem} from "../../components/common/body-list/body-list";

export let blog5Title = "Entry #5 - April 17th Changes"

export let blog5Author = "Posted by Michael / Saturday April 17th 2021"

export let blog5Content = <Body>
    <BodyText>Hi everyone, we have released a number of changes today.</BodyText>

    <BodyTitle>New features:</BodyTitle>
    <BodyList>
        <BodyListItem>Hedgee is now available as selectable avatar! (Thanks <a
            href={"https://ko-fi.com/franticfanfic"}>Ko-fi</a> supporters!)
        </BodyListItem>
        <BodyListItem>You can now change your avatar after joining a lobby. If you leave the lobby, and change your
            avatar and
            rejoin,
            the game should update your avatar.
        </BodyListItem>
        <BodyListItem>New "force-through" button on writing pages for VIP to "force" the game to the next step instead
            of waiting
            out
            the timer. This is intended to help cases where you've chosen the 10 minute mode but everyone has finished
            writing
            before time is up.
        </BodyListItem>

    </BodyList>
    <BodyTitle>Bug Fixes:</BodyTitle>
    <BodyList>
        <BodyListItem>Fixed game stats bug that was incorrectly showing the player with the most/least selected
            character.</BodyListItem>
    </BodyList>
    <BodyTitle>Other changes.</BodyTitle>
    <BodyList>
        <BodyListItem>Tweaked shuffling strategy when mixing up characters and stories. It <i>should</i> feel "more
            random"
            especially in cases with large groups.
        </BodyListItem>
        <BodyListItem>Reduced image sizes to help them load quicker.</BodyListItem>
        <BodyListItem>Content changes on About/Contact/Support Us.</BodyListItem>
        <BodyListItem>Blog posts get their own section now.</BodyListItem>
    </BodyList>

    <BodyText>If you notice anything wonky, please contact us at our Twitter <a rel="noreferrer"
                                                                                target={"_blank"}
                                                                                href={"https://twitter.com/franticfanfic"}>@franticfanfic</a> or
        our email franticfanficgame [at] gmail [dot] com.</BodyText>

    <BodyText>It's been fun seeing a bunch of people all over the world enjoying this game we built and we hope that
        you'll
        continue to enjoy it! </BodyText>
    <BodyText>For some fun statistics, here are the top 10 most submitted characters so far!</BodyText>
    <BodyList ordered>
        <BodyListItem>Pikachu</BodyListItem>
        <BodyListItem>Naruto</BodyListItem>
        <BodyListItem>Sonic</BodyListItem>
        <BodyListItem>Batman</BodyListItem>
        <BodyListItem>Mario</BodyListItem>
        <BodyListItem>Luigi</BodyListItem>
        <BodyListItem>Hatsune Miku</BodyListItem>
        <BodyListItem>Goku</BodyListItem>
        <BodyListItem>Shrek</BodyListItem>
        <BodyListItem>Jesus</BodyListItem>
    </BodyList>
    <BodyText>The top 5 favourite avatars are:</BodyText>
    <BodyList ordered>
        <BodyListItem>Ghost</BodyListItem>
        <BodyListItem>Buffpire</BodyListItem>
        <BodyListItem>Raccoon</BodyListItem>
        <BodyListItem>Protec</BodyListItem>
        <BodyListItem>Skellybts</BodyListItem>
    </BodyList>


    <BodyText> - Michael</BodyText>

</Body>

export let blog = {
    title: blog5Title,
    author: blog5Author,
    content: blog5Content
}