import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import {config} from "./constants";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const debug = process.env.REACT_APP_I18N_DEBUG === 'true'

const dev = config.development


function backendOptions() {
    if (dev) {
        return [
            {expirationTime: 0}, {loadPath: '/locales/{{lng}}/{{ns}}.json'}]
    } else {
        return [
            {expirationTime: 1000 * 60 * 60 * 24}, {loadPath: '/locales/{{lng}}/{{ns}}.json'},
        ]
    }

}

i18n
    // pass the i18n instance to react-i18next.
    .use(ChainedBackend)
    .use(initReactI18next)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        ns: ['common',
            'create-game',
            'game',
            'game-settings',
            'main'],
        defaultNS: "",
        lng: "en",
        fallbackLng: 'en',
        debug: debug,

        backend: {
            backends: [LocalStorageBackend, HttpBackend],

            backendOptions: backendOptions()

        },


        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;