import React from "react";
import logoMed from "./images/logo/logo-med64.gif";
import RoomCode from "./components/common/room-code/room-code";
import AudienceLabel from "./components/common/audience-label/audience-label";

type Props = {
    extraLabel?: string
    roomCode?: string
}

const AppTop: React.FunctionComponent<Props> = props => {


    return <div className={"mx-0 flex flex-row justify-between md:flex md:mx-4"}>
        <div className={" h-full w-1/4 md:w-auto md:max-w-[1/4] z-20"}>
            <div className={"md:mt-2 md:bg-night-primary md:border-black md:border-[3px] md:border-b-0 md:shadow-aestheticTop md:px-1"}>
                <img className={"hidden md:inline-block"} src={logoMed} alt={logoMed}/>
            </div>

            <div className={"h-[32px] md:h-0"}>
                <img
                    className={"bottom-0 bg-night-primary border-black border border-[3px] border-b-0 inline-block md:hidden h-[32px] w-auto"}
                    src={logoMed} alt={logoMed}/>
            </div>

        </div>
        <div className={"self-end flex flex-row justify-end space-x-1"}>
            {props.extraLabel && <AudienceLabel/>}
            {props.roomCode && <RoomCode/>}
        </div>
    </div>
}

export default AppTop