import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {GameMode} from "../types2";


export const selectPlayerCharacters = (state: RootState) => state.player.player?.characters


export const selectIsSolo = (state: RootState) => state.game.game?.settings.game_mode === GameMode.solo_mode

export const selectCountdown = (state: RootState) => state.game.game?.countdown

export const sortedCharactersSelector = createSelector(selectPlayerCharacters, res => res ? [...res].sort((a, b) => {
    if (a.meta.locked && !b.meta.locked) {
        return -1
    } else if (!a.meta.locked && b.meta.locked) {
        return 1
    } else {
        return a.id - b.id
    }
}) : [])

export const selectIsHost = (state: RootState) => state.player.player?.id === state.game.game?.host_id