import Page from "../../common/page/page";
import AvatarHeader from "../../common/avatar-header/avatar-header";
import {useAppSelector} from "../../../redux/store";
import React, {ReactNode} from "react";

export type Props = {
    titlesJsx?: ReactNode[]
    titles?: string[]

    subHeaderJsx?: JSX.Element
    subHeaders?: string[]

    footer?: JSX.Element
    footerJsx?: JSX.Element // inside <Footer>
    footerLabel?: string

    onBack?: () => void

    hideAvatar?: boolean
    hack?: JSX.Element[]

    children?: React.ReactNode

}

const GamePage: React.FunctionComponent<Props> = props => {

    const gameState = useAppSelector(state => state.game.game)
    const playerState = useAppSelector(state => state.player.player)
    const metaState = useAppSelector(state => state.meta)
    const menu = useAppSelector(state => state.menu)


    function extraLabel() {
        return gameState?.extra.num_audience !== undefined && gameState?.extra.num_audience > 0 ? gameState?.extra.num_audience.toString() : undefined
    }


    return <Page
        header={<AvatarHeader hideAvatar={props.hideAvatar} titles={props.titles} titlesJsx={props.titlesJsx}
                              avatarName={playerState?.name}
                              avatarSpriteId={playerState?.meta.sprite_id}/>}
        subHeaders={props.subHeaders}
        subHeaderJsx={props.subHeaderJsx}

        extraTopLabel={extraLabel()}
        footer={props.footer}
        footerJsx={props.footerJsx}
        footerLabel={props.footerLabel}

        onBack={props.onBack}

        hack={props.hack}
        countdown={gameState?.countdown}
        roomCode={menu.playerId?.room_code}
        connected={metaState.connected}
        error={metaState.error}>
        {props.children}
    </Page>
}

export default GamePage

