import React from "react";
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";
import BodyList, {BodyListItem} from "../../components/common/body-list/body-list";

export let blog6Title = "Entry #6 - The Big Backend Stability Update "

export let blog6Author = "Posted by Zee / Wednesday May 12 2021"

export let blog6Content = <Body>
    <BodyText>Hi everyone, Michael wanted to update the backend so that he can push updates without interrupting games.
        Unfortunately in order to push this update… We’re going to need some down time. He’ll be starting the process at
        6PM EST and it should take a few hours. The game will still be playable in this timeframe as he needs to be able
        to test with a group to make sure all is well, but please note the game will probably be glitchy so we don’t
        recommend playing it during maintenance. After that we’ll be clear to update the game without interrupting!</BodyText>

    <BodyText>We have a few ideas about new game modes and features we want to add, so here’s a short list for the people that
        read the blog :). If you’re really interested in us introducing one feature over the other, let us know at
        @franticfanfic on twitter or franticfanficgame [at] gmail [dot] com.</BodyText>


    <BodyList>
        <BodyListItem>Hooking stories into Twitter “screenshots” / automatically posting stories to Twitlonger with permission
        </BodyListItem>
        <BodyListItem>An option to hide writers until the final scores so parts will remain anonymous.</BodyListItem>
        <BodyListItem>Fanfic about you! An option to automatically add usernames of current players into the selection pool, and
            only write 4 characters instead. Your own username would not shuffle to yourself in that mode, but they
            would still be randomly distributed.
        </BodyListItem>
        <BodyListItem>A player cap for less than 16 players when you don’t want 16 players to be able to join.</BodyListItem>
        <BodyListItem>The ability to start the game with two characters that you pick yourself. So there’s no random shuffle at
            the start, and the next people will need to continue the story you started.
        </BodyListItem>
        <BodyListItem>Audience play - let people who aren’t writing but are watching react along.</BodyListItem>
        <BodyListItem>Realtime react updates! So people know which parts get the most reacts as they read through the fanfics.
        </BodyListItem>
        <BodyListItem>Fleshing out the new game modes we have planned.</BodyListItem>
    </BodyList>

    <BodyText>Okay, that’s all we have for now! Keep an eye out because we’re planning on making some FF merch. Enamel pins,
        maybe
        some hats with our characters embroidered on top? Everybody likes hats, right?! Follow us at @franticfanfic on
        twitter to keep up with that!!</BodyText>

    <BodyText>- Zee</BodyText>

</Body>

export let blog = {
    title: blog6Title,
    author: blog6Author,
    content: blog6Content
}