import InputProps from "./input";

type TextInputProps = InputProps & {}


const TextInput: React.FC<TextInputProps> = (props) => {

    return <input autoComplete={"off"} id={props.id}
                  className={"pl-2 relative  bg-black text-white text-3xl leading-tight outline-none border-none w-[11rem]"}
                  minLength={3}
                  value={props.value}
                  maxLength={10}
                  onChange={props.onChange} required/>
}

export default TextInput