import React, {useContext, useEffect, useState} from "react";

import magicbook1 from '../../../images/BOOKassetNEW_1.gif'
import magicbook2 from '../../../images/BOOKassetNEW_2.gif'
import magicbook3 from '../../../images/BOOKassetNEW_3.gif'

import {Player, PlayerAction} from "../../../types";
import "./styles.css"
import {config, constants} from "../../../constants";
import AvatarLobby from "../../common/avatar-lobby/avatar-lobby";
import Waitable from "../../common/waitable/waitable";
import {GameStatus} from "../game";
import StateButton from "../../common/state-button/state-button";
import {useAppSelector} from "../../../redux/store";
import GamePage from "../game-page/game-page";
import {GameContext, MenuContext} from "../../../contexts";
import {Maturity} from "../../../types2";
import {PageNumber} from "../../menu/menu";
import {useTranslation} from "react-i18next";


const Lobby = () => {


    const {submit} = useContext(GameContext)
    const {setPage} = useContext(MenuContext)

    // const [footer, setFooter] = useState("")
    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)
    const [imgCount, setImgCount] = useState(0)

    const [checked, setChecked] = useState(-1)

    const {t} = useTranslation(['game', 'common'])

    useEffect(() => {
        let interval = setInterval(() => {
            setImgCount(prev => (prev + 1) % 2)
        }, 1000);

        return () => clearInterval(interval)

    })

    function handleExit() {
        let playerAction: PlayerAction = {
            // room_id: Gamers.roomDetails?.room?.id as string,
            // player_id: Gamers.roomDetails?.player?.id as string,
            // player_secret: Gamers.roomDetails?.player?.secret as string,
            code: "game/exit",
            body: {},
            // game_id: gameState.gameId as string,
        }
        submit(playerAction)

        setPage(PageNumber.Main)

    }

    function handleKick() {
        if (checked !== playerState.player?.id) {
            let playerAction: PlayerAction = {
                body: {
                    "vip_action": {
                        "kick_player": checked
                    }
                },
                code: "game/vip",
            }
            submit(playerAction)
        }
    }

    function handleBook() {
        let playerAction: PlayerAction = {
            // room_id: Gamers.roomDetails?.room?.id as string,
            // player_id: Gamers.roomDetails?.player?.id as string,
            // player_secret: Gamers.roomDetails?.player?.secret as string,
            code: "game/start",
            body: {},
            // game_id: gameState.gameId as string,
        }
        submit(playerAction)
        // setFooter("Loading...")
    }

    function makeAvatars2() {
        // let players = gameState.game?.players as Player[]
        let players = [] as Player[]
        if (gameState.game?.players) {
            players = gameState.game?.players
        }

        // players = players.sort((a, b) => (a.id as number) - (b.id as number) ?? 0)
        let divs = []
        let playerIdx = 0
        for (let i = 0; i < 20; i++) {
            if (i === 13 || i === 14 || i === 17 || i === 18) {
                divs.push(<div key={"lobby-element-" + i} className={"w-1/4"}>
                    <div className={"square"}/>
                </div>)
            } else {
                let player = players[playerIdx++]

                divs.push(<div key={"lobby-element-" + i} className={"w-1/4"}>
                    <div className={"square"}>

                        {playerIdx <= (gameState.game?.settings?.max_players ?? 16) && <AvatarLobby
                            onClick={(playerId) => setChecked(ps => ps === playerId || playerId === playerState.player?.id ? -1 : playerId)}
                            checked={checked === player?.id} key={i} player={player}
                            closed={playerIdx > (gameState.game?.settings?.max_players ?? 16)}/>}
                    </div>

                </div>)
            }

        }
        return divs
    }

    function newFooter() {
        if (!gameState.game?.settings) {
            return
        }
        const startGuide = gameState.game?.settings.show_instructions ? t('lobby.footers.startGuideOn') : t('lobby.footers.startGuideOff')

        let gameSpeed: string

        if (gameState.game?.settings.write_duration >= constants.leisureSpeed) {
            gameSpeed = t('speeds.leisurely', {ns: 'common'})
        } else if (gameState.game?.settings.write_duration >= constants.briskSpeed) {
            gameSpeed = t('speeds.brisk', {ns: 'common'})
        } else if (gameState.game?.settings.write_duration >= constants.franticSpeed) {
            gameSpeed = t('speeds.frantic', {ns: 'common'})
        } else if (gameState.game?.settings.write_duration >= constants.lightningSpeed) {
            gameSpeed = t('speeds.lightning', {ns: 'common'})
        } else {
            gameSpeed = t('speeds.sonic', {ns: 'common'})
        }
        // const duration = `${(gameState.game?.settings.write_duration as number / 60).toFixed(2)} min parts`
        // const extraTime = gameState.game?.settings?.extra_reading_time ? "EXTRA TIME ON" : "EXTRA TIME OFF"
        const maturity = gameState.game?.settings.maturity === Maturity.MATURE ? t('label.mature', {ns: 'common'}) : t('label.allAges', {ns: 'common'})


        const label = [startGuide, gameSpeed, maturity].join(' | ')


        return (
            <>
                <StateButton onClick={handleExit}
                             states={[amHost() ? t('lobby.footers.closeGame') : t('lobby.footers.leaveGame'), t('lobby.footers.areYouSure')]}/>
                <div className={"flex-grow text-sm text-center flex justify-center items-center mx-2 leading-none"}>
                    {label}
                </div>
                {amHost() ? <button disabled={checked <= 0} onClick={handleKick}
                                    className={"text-lg whitespace-nowrap"}>{t('button.kickPlayer', {ns: 'common'})}</button> : ''}
            </>
        )

    }

    function clickable() {
        return gameState.game?.players && gameState.game?.players?.length >= config.minimumLobbySize

    }

    function amHost() {
        return gameState.game?.host_id === playerState.player?.id
    }

    function disabled() {
        return !amHost()
    }

    function shouldFlash() {
        return gameState.game?.players && gameState.game?.players?.length >= config.minimumLobbySize
    }

    function subheaders() {
        return [t('lobby.subheaders.defaultOne'), t('lobby.subheaders.defaultTwo')]
    }

    return <Waitable playerActionHandler={() => {
    }} waitingFor={[GameStatus.GameStatusPostLobby]} waitHeader={t('lobby.headers.waiting')}>
        <GamePage hideAvatar
                  subHeaders={subheaders()}
                  titles={[t('lobby.headers.default')]} footerJsx={newFooter()}>
            <div className={"lobby-container"}>
                <div className={"lobby-players2"}>
                    {makeAvatars2()}

                    <button className={"magic-book-button"}
                            onClick={handleBook}
                            disabled={disabled() || !clickable()}>
                        {shouldFlash() ? <img className={"w-full h-full relative"}
                                              src={disabled() ? magicbook2 : (imgCount === 0 ? magicbook2 : magicbook3)}
                                              alt="submit"/>
                            : <img className={"w-full h-full relative"} src={magicbook1}
                                   alt="submit"/>}

                    </button>
                </div>
            </div>
        </GamePage></Waitable>
}

export default Lobby