import {useState} from "react";
import {SelectGameMode} from "./game-mode";
import GameSettingsComponent from "./game-settings";
import {GameMode} from "../../../types2";
import {Room} from "../../../types";


type Props = {
    onCreate: (room: Room) => void
}

export const CreateGame = ({onCreate}: Props) => {

    let [page, setPage] = useState(0)

    let [gameMode, setGameMode] = useState(GameMode.default)

    function handleModeSelect(gameMode: GameMode) {
        setGameMode(gameMode)
        setPage(1)
    }


    function handleCreate(room: Room) {
        onCreate(room)
    }

    let comp;
    switch (page) {
        case 0:
            comp = <SelectGameMode onSelect={handleModeSelect}/>
            break
        case 1:
            comp = <GameSettingsComponent gameMode={gameMode} onCreate={handleCreate}/>
            break
        default:
            comp = <SelectGameMode onSelect={handleModeSelect}/>

    }

    return comp

}