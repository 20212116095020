import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";

export let blog3Title = "ENTRY #3 - WOW!!"

export let blog3Author = "Posted by Zee / Saturday April 10 2021"

export let blog3Content = <Body>


    <BodyText>Hi!!!</BodyText>

    <BodyText>This is Zee. We’ve been live for a week now! Can you believe it?! Hahah wow. Okay, so here we go. We’re
        extremely
        proud of this game and we can’t believe how fast it has spread to people that aren’t our friends or family. So
        thank
        you for that, that’s all you guys! I think we’ve had like 2500ish users on the site so far so that’s amazing.
        We’re
        working on a number of bugs and updates to the game that should come out very shortly. Some issues with viewing
        the
        room code on Apple devices, the VIP being able to push the game through without needing to restart the room and
        we’re also adding some fun stats to the last page. Those should all be taken care of in the next update. In the
        update after that we should be shifting a few buttons around on the main screen and also adding a F.A.Q. section
        too. And many more bug fixes that have been flagged to us. So that’s what’s down the pipeline.</BodyText>

    <BodyText>Another thing! We reached $200 on our Ko-Fi from supporters like you! Thanks so much for supporting our
        team of
        two
        lol. We’ll be adding a new character to the roster chosen and voted for by our Ko-Fi supporters. After that vote
        is
        done, we’ll raise that goal to $500 for one more character. The plan after that is to raise the bar to $1000. If
        we
        get that, we’ll release a new game mode! What’s the mode? Guess we’ll need to find out.</BodyText>

    <BodyText>Some things that happened this week:
        Added accessibility text option. Gave back upper and lower case to stories (before it was just uppercase… I
        guess
        that’s hard to read!! Whoops!) and attempted to fix our SEO so that when you search for “Frantic Fanfic” on
        Google
        it doesn’t just show my Twitter… uh… we’re working on that too. The website was down for a bit on Wednesday but
        we
        were able to fix that pretty fast!! Please note we have day jobs and we made this game for fun.</BodyText>

    <BodyText>Overall, we’re just really happy that people are playing and having a good time with their friends. That’s
        what
        we
        made the game for. So we’re smiling about that.</BodyText>

    <BodyText>Again, if you want to kick us some money for making a really cool game the link is <a
        href={"https://ko-fi.com/franticfanfic"}>https://ko-fi.com/franticfanfic</a>.
        Another thing you can do to support us is tell a friend the game is a thing. And they’ll be like “WHAT?! NO
        WAY?!?
        THAT’S A THING?!” and you’ll be like “Yeah and it’s FREE!” and they’ll be like “WHAAAAAAAT.” and you’ll be like
        “Ya
        it’s free but they keep telling people to support them on Ko-Fi and I guess I will because I don’t remember the
        last
        time I laughed so hard tears fell from my eyes so that’s worth 5 bucks I guess” and your friend will say “That
        is
        very nice I cannot wait for that to happen to me too.” Dang was that a fanfic about you, dear reader and your
        friend?! I think I can’t stop writing them. Might be a problem later… Oh boy.</BodyText>


</Body>

export let blog = {
    title: blog3Title,
    author: blog3Author,
    content: blog3Content
}