import React, {useEffect, useRef} from "react";
import StoryVoteCard from "./story-vote-card";
import "./styles.css"
import {Story, StoryVote} from "../../../../types";
import {config} from "../../../../constants";
import {useAppSelector} from "../../../../redux/store";
import GameFormPage from "../../game-page/game-form-page";
import {useTranslation} from "react-i18next";

type Props = {
    vote: StoryVote

    onBack: () => void
    onVote: (voteId: number, storyId: number) => void
    toggleVote: (storyID?: number) => void
    currentVote?: number
    loading?: boolean
}
const StoryVoteComponent = ({vote, onBack, onVote, toggleVote, currentVote, loading}: Props) => {
    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)

    const {t} = useTranslation(['game', 'common'])

    const voteId = useRef(vote.id)

    const containerRef = useRef<HTMLDivElement>(null)

    function handleSubmit() {
        if (currentVote) {
            onVote(vote.id, currentVote)
        } else {
            alert("YOU NEED TO VOTE FOR A STORY!")
        }
    }

    useEffect(() => {
        if (containerRef.current && vote.id !== voteId.current) {
            voteId.current = vote.id
            containerRef.current.scrollIntoView()
        }
    }, [vote])

    function handleBack() {
        onBack()
    }

    function checked(id: number): boolean {
        return currentVote === id
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.checked) {
            toggleVote(undefined)
        } else {
            toggleVote(parseInt(event.target.value))
        }
    }

    function id(storyId: number) {
        return vote.id + ':' + storyId
    }

    function ready() {
        return currentVote !== undefined
    }

    function filterStory(story: Story) {
        // let submissions = story.submissions.sort((a, b) => a.index - b.index);

        switch (vote.award.meta.rule) {
            case 0:
                return config.canReactToSelf || (story.captain.id !== playerState.player?.id) || gameState.game?.players.length === 1
            case 1:
                return config.canReactToSelf || (story.captain.id !== playerState.player?.id) || gameState.game?.players.length === 1
            case 2:
                return config.canReactToSelf || (story.captain.id !== playerState.player?.id) || gameState.game?.players.length === 1
            case 3:
                return config.canReactToSelf || (story.submissions[1].player_id !== playerState.player?.id) || gameState.game?.players.length === 1
            case 4:
                return config.canReactToSelf || (story.submissions[2].player_id !== playerState.player?.id) || gameState.game?.players.length === 1
        }
        return false
    }


    return <GameFormPage ready={ready()}
                         readyFlash
                         countdownThreshold={120}
                         subHeaders={[t('storyVote.subheaders.default'), vote.award.meta.rule === 0 ? t('storyVote.subheaders.constant') : vote.award.meta.rule === 2 ? t('storyVote.subheaders.constantTitle') : t('storyVote.subheaders.random')]}
                         titles={[t('storyVote.headers.default')]}
                         onBack={handleBack} submit={handleSubmit} submitText={t('button.vote', {ns: 'common'})}>
        <div ref={containerRef} className={"story-vote-container"}>
            <div className={"story-vote-award-title"}>🏆 {vote.award.meta.name} 🏆</div>
            <div className={"story-vote-award-subtitle"}>{vote.award.meta.subtitle}</div>
            <div className={"story-vote-stories"}>
                {gameState.game?.stories?.filter(filterStory).map(s =>
                    <div key={"row" + s.id} className={"story-vote-row"}>
                        <label className={"story-vote-label w-[80%]"} htmlFor={id(s.id)}><StoryVoteCard
                            hideAuthors={gameState.game?.settings?.hide_authors} story={s}/></label>
                        <input className={"story-vote-checkbox w-[20%]"} id={id(s.id)} name="story-vote"
                               type="checkbox"
                               disabled={loading}
                               checked={checked(s.id)}
                               onChange={handleChange} value={s.id}/>
                    </div>
                )}
            </div>
        </div>
    </GameFormPage>

}

export default StoryVoteComponent