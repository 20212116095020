import "./censor-button.css"

type Props = {
    index: number
    onChange: (checked: boolean) => void
    checked: boolean
}

const CensorButton = ({index, onChange, checked}: Props) => {


    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const checked = e.currentTarget && e.currentTarget.checked
        onChange(checked)
    }

    return <div className={"flex flex-row-reverse"}>
        <input className={"censor-button-input"} id={"censorlabel-" + index} type={"checkbox"}
               onChange={handleChange}
               checked={checked}/>
        <label htmlFor={"censorlabel-" + index}
               className={"flex items-center justify-center select-none cursor-pointer min-w-[6rem] border-2 border-black "}>
            {checked ? "CENSORED" : "CENSOR?"}</label>
    </div>

}

export default CensorButton