import React from "react";
import CountdownComponent from "../countdown/countdown";
import {Countdown} from "../../../types";

type Props = {
    onBack?: () => void
    countdown?: Countdown
    onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void
    error?: string

    loading?: boolean
    ready?: boolean
    loadingText?: string
    submitText?: string

    submitTexts?: string[]


    showButton?: boolean
    countdownThreshold?: number
    readyFlash?: boolean
}

const FormFooter: React.FunctionComponent<Props> = props => {


    return <div className={"form-book-footer"}>

        <div className={"form-book-footer-container"}>
            {props.onBack ?
                <button type="button" className={"form-book-footer-back"} onClick={props.onBack}>back</button>
                : props.countdown ?
                    <CountdownComponent countdown={props.countdown} threshold={props.countdownThreshold}/> : ''}

        </div>

        <div className={"form-book-footer-container form-book-footer-container-error"}>
            {props.error ? <div className={"form-book-footer-error"}>
                {props.error}
            </div> : ''}

        </div>

        <div className={"form-book-footer-container"}>
            {props.onSubmit ?
                <button
                    className={props.ready && props.readyFlash ? "form-book-submit form-book-submit-flash" : "form-book-submit"}
                    disabled={props.loading || !props.ready}
                    type="submit">{props.loading ? (props.loadingText ?? "loading...") : (props.submitText ? props.submitText : "submit")}</button> : ''}
        </div>

    </div>
}

export default FormFooter