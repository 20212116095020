import React from "react";
import "./avatar6.css"
import {config} from "../../../constants";

const paths = config.avatars
const medPaths = config.avatars192
const smallPaths = config.avatarsSmall
const xsmallPaths = config.avatarsXSmall
const specialPaths = config.avatarsSpecial

type Props = {
    spriteId: number,
    name?: string
    vip?: boolean
    noAnimate?: boolean
    special?: boolean

    onClick?: () => void
    bigText?: boolean
    premium?: boolean

    small?: boolean
    big?: boolean
}

const Avatar6 = ({spriteId, name, noAnimate, special, onClick, bigText, premium, small, big}: Props) => {

    let bigPath = special ? specialPaths[spriteId] : paths[spriteId]
    let medPath = special ? specialPaths[spriteId] : medPaths[spriteId]
    let smallPath = special ? specialPaths[spriteId] : smallPaths[spriteId]
    let xsmallPath = special ? specialPaths[spriteId] : xsmallPaths[spriteId]


    return <div
        className={`relative flex flex-col items-center justify-start ${small && 'h-[72px] w-[64px] sm:h-[104px] sm:w-[96px]'} ${big && 'h-[264px] w-[256px]'} ${!small && !big && 'h-[200px] w-[192px]'}`}
        onClick={onClick}>
        <div
            className={`relative flex flex-row items-center justify-center ${small && 'h-[64px] w-[64px] sm:h-[96px] sm:w-[96px]'} ${big && 'h-[256px] w-[256px]'} ${!small && !big && 'h-[192px] w-[192px]'}`}>
            <div className={"w-3/4 h-3/4 absolute bg-white opacity-100 border border-solid border-border-blue"}/>

            <img className={`inline-block ${noAnimate ? "avatar6-img-no-bounce" : "avatar6-img"} sm:hidden`}
                 src={small ? xsmallPath : big ? bigPath : medPath}
                 alt={small ? xsmallPath : big ? bigPath : medPath}/>
            <img className={`hidden ${noAnimate ? "avatar6-img-no-bounce" : "avatar6-img"} sm:inline-block`}
                 src={small ? smallPath : big ? bigPath : medPath}
                 alt={small ? smallPath : big ? bigPath : medPath}/>
        </div>

        {name &&
            <div className={"absolute bottom-0 z-30"}>
                <div
                    className={`bg-opacity-60 bg-black break-words z-30 text-white leading-none px-1 text-center ${bigText ? 'text-2xl' : 'text-base'} ${[premium && "border-amber-500"]}`}>{name}</div>
            </div>}

    </div>
}

export default Avatar6