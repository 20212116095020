import {constants} from "../../../constants";

type Props = {
    label: string
    subLabel?: string
}

const GameSettingsLabel: React.FC<Props> = (props) => {

    return <div className={"self-stretch w-3/5 flex flex-col items-stretch my-1 space-y-1"}>
        <label
            className={"min-h-[2.5rem] text-2xl leading-snug bg-gray-drk shadow-basic flex justify-center items-center pl-1 text-center"}>{props.label}</label>
        {props.subLabel && <label
            className={"bg-night-primary ml-4 shadow-basic px-4 text-sm"}>{constants.rightButton} {props.subLabel}</label>}
    </div>
}

export default GameSettingsLabel