import MenuFormPage from "../menu-page/menu-form-page";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import React, {useEffect, useState} from "react";
import RoomAPI from "../../../RoomAPI";
import {updateConfig} from "../../../redux/slices/meta";
import MenuPage from "../menu-page/menu-page";
import SquareRadio2 from "../../common/square-radio/square-radio2";


const Admin = () => {

    let selectConfig = useAppSelector(state => state.meta.config);

    const dispatch = useAppDispatch()

    const [maintenance, setMaintenance] = useState<boolean>()
    const [maintenanceMessage, setMaintenanceMessage] = useState<string>()
    const [noticeMessage, setNoticeMessage] = useState<string>()

    const [ready, setReady] = useState<boolean>(false)

    const secretPassword = localStorage.getItem("supersecretadminkey")

    const [ok, setOK] = useState<boolean>(false)

    useEffect(() => {
        if (selectConfig) {
            setMaintenance(selectConfig.maintenance)
            setMaintenanceMessage(selectConfig.maintenance_message)
            setNoticeMessage(selectConfig.notice_message)
            setReady(true)
        }


    }, [selectConfig])

    useEffect(() => {
        RoomAPI.GetAdminAccess(secretPassword ?? "").then(r => {
            setOK(r === 200)
        }).catch(_ =>
            setOK(false)
        )
    }, [secretPassword])

    function handleSubmit() {
        if (maintenance !== undefined && maintenanceMessage !== undefined && noticeMessage !== undefined) {
            RoomAPI.UpdateConfig({
                maintenance_message: maintenanceMessage,
                maintenance: maintenance,
                notice_message: noticeMessage
            }, secretPassword ?? "sdf").then(r => dispatch(updateConfig(r)))
        }

    }

    function formContent() {
        return <MenuFormPage submit={handleSubmit} ready={ready} titles={["admin"]}>


            <div className={"flex-1 flex flex-col justify-center items-center space-y-4 px-2"}>

                <div>
                    <div>{"maintenance mode"}</div>
                    <SquareRadio2 name={"maintenance"}
                                  options={[{label: "no", value: 'false'}, {label: "yes", value: 'true'}]}
                                  value={maintenance ? 'true' : 'false'}
                                  onSelect={v => setMaintenance(v === 'true')}/>
                </div>


                <div className={"self-stretch py-0 flex flex-row justify-between items-center"}>
                    <div className={"mr-4"}>{"maintenance message"}</div>
                    <textarea className={"border-black border-2 pl-2 flex-1 h-64"}
                              value={maintenanceMessage}
                              onChange={e => setMaintenanceMessage(e.target.value)}/>
                </div>

                <div className={"self-stretch py-0 flex flex-row justify-between items-center"}>
                    <div className={"mr-4"}>{"notice message"}</div>
                    <textarea className={"border-black border-2 pl-2 flex-1 h-64"}
                              value={noticeMessage}
                              onChange={e => setNoticeMessage(e.target.value)}/>
                </div>

            </div>

        </MenuFormPage>
    }


    return ok ? formContent() : <MenuPage/>
}

export default Admin