import {Story} from "../../../../types";
import StoryButton from "../../../common/story-button/story-button";

type Props = {
    story: Story
    hideAuthors?: boolean
}

const StoryVoteCard = ({story, hideAuthors}: Props) => {


    return <div className={"story-vote-card-container"}>
        <StoryButton hideAuthors={hideAuthors} story={story}/>
    </div>

}

export default StoryVoteCard