import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {constants} from "../../../constants";
import {updateHiddenSubheaders} from "../../../redux/slices/menu";
import React, {useEffect} from "react";

function formatSubheaders(subHeaders: string[], onClick?: () => void) {

    return <div className={`book-subheader ${onClick && 'cursor-pointer'}`} onClick={onClick}>
        {subHeaders.map((s, idx) => <div key={"subheader" + idx}>{constants.littleTriangle} {s}<br/></div>)}
    </div>
}

function hidden(onClick: () => void) {

    return <div className={`book-subheader cursor-pointer`} onClick={onClick}>
        <div>{constants.littleTriangleDown} <span className={"underline"}>click to show instructions </span></div>
    </div>
}

type Props = {
    subheaders?: string[]
}

const Subheader: React.FC<Props> = props => {

    let hiddenSubheaders = useAppSelector(state => state.menu.hiddenSubheaders);

    let dispatch = useAppDispatch()

    let [clickable, setClickable] = React.useState(false)


    useEffect(() => {
        if (props.subheaders && props.subheaders.length > 1) {
            setClickable(true)
        } else {
            setClickable(false)
        }
    }, [props.subheaders])

    function handleClick() {
        if (clickable) {
            dispatch(updateHiddenSubheaders(!hiddenSubheaders))
        }
    }

    function content() {
        if (!props.subheaders || props.subheaders.length === 0) {
            return ''
        }
        if (hiddenSubheaders && clickable) {
            return hidden(handleClick)
        }

        return formatSubheaders(props.subheaders, clickable ? handleClick : undefined)

    }

    return <>{content()}</>


}
export default Subheader

