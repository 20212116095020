import {Player, Story} from "../../../types";
import StoryButton2 from "../../common/story-button2/story-button2";
import Avatar4 from "../../common/avatar4/avatar";
import React, { useEffect } from "react";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import html2canvas from "html2canvas";
import moment from "moment/moment";
import {constants} from "../../../constants";
import ResultsAvatar from "./results-avatar";
import "./styles.css"
import CanvasView from "../../common/canvas-view/canvas-view";
import {storyToText} from "./story-results";
import {useAppSelector} from "../../../redux/store";
import GamePage from "../game-page/game-page";
import {GameMode} from "../../../types2";
import {cleanStoryName} from "./story-view";
import {useTranslation} from "react-i18next";

type ResultsProps = {
    onGameStats: () => void
    onStoryClick: (index: number) => void
}

type Rank = {
    player?: Player
    playerRank?: number
    story?: Story
    storyRank?: number
}


const Leaderboard = ({onGameStats, onStoryClick}: ResultsProps) => {

    const gameState = useAppSelector(state => state.game)
    const roomCode = useAppSelector(state => state.menu.playerId?.room_code)

    const stories = gameState.game?.stories?.slice().sort((a, b) => b.meta.points - a.meta.points)

    const players = gameState.game?.players?.slice().sort((a, b) => (b.data.points ? b.data.points : 0) - (a.data.points ? a.data.points : 0))


    const {t} = useTranslation('game')

    useEffect(() => {
        window.onbeforeunload = (e: BeforeUnloadEvent) => {
            e.returnValue = 'Are you sure?'
        }

        return () => {
            window.onbeforeunload = null
        }
    }, [])

    function playAgain() {
        window.open("https://franticfanfic.com", "_self")
    }

    const footer = <>
        <button className={"text-lg"}
                onClick={_ => playAgain()}>{t('storyResults.footers.default')}</button>
        <button className={"text-sm flex-grow mx-1"}
                onClick={handleSupportUs}>{t('storyResults.footers.likeGame')}<br/>{t('storyResults.footers.supportUs')}
        </button>
        <button className={"text-lg"}
                onClick={handleGameStats}>{t('storyResults.footers.gameStats')}</button>
    </>

    function storyAndPlayerRanks(): Rank[] {
        const size = Math.max(stories?.length ?? 0, players?.length ?? 0)

        let storyRank = 1, playerRank = 1
        let prevStoryPoints = 0, prevPlayerPoints = 0


        let storyRankRunner = 0
        let playerRankRunner = 0

        let ranks: Rank[] = []
        for (let i = 0; i < size; i++) {
            const rank: Rank = {}

            if (stories && stories[i]) {
                rank.story = stories[i]

                if (stories[i].meta.points === prevStoryPoints) {
                    rank.storyRank = storyRank
                    storyRankRunner++
                } else {
                    rank.storyRank = storyRank + storyRankRunner
                    storyRankRunner = 1
                }
                prevStoryPoints = stories[i].meta.points
                storyRank = rank.storyRank

            }
            if (players && players[i]) {
                rank.player = players[i]

                if (players[i].data.points === prevPlayerPoints) {
                    rank.playerRank = playerRank
                    playerRankRunner++
                } else {
                    rank.playerRank = playerRank + playerRankRunner
                    playerRankRunner = 1
                }
                prevPlayerPoints = players[i].data.points
                playerRank = rank.playerRank

            }
            ranks.push(rank)

        }

        return ranks
    }

    function handleSupportUs() {
        window.open("https://ko-fi.com/franticfanfic", "_blank")
    }

    function handleGameStats() {
        onGameStats()
    }

    function handleBulkDownloadImages() {
        zippyImages()
    }

    function handleBulkDownloadText() {
        zippyTexts()
    }

    function screeny(canvasId: string) {
        return html2canvas(document.querySelector(`#${canvasId}`) as HTMLElement, {scrollY: -window.scrollY});
    }

    function zippyImages() {
        let zip = new JSZip()

        // let promises: Promise<void>[] = []
        let promises = gameState.game?.stories?.map(async (s) => {
            let canvas: HTMLCanvasElement = await screeny(`canvas-${s.id}`)

            let blob: Blob = await new Promise(res => canvas.toBlob(b => res(b as Blob)))

            const filename = `${s.meta.index + 1}-${cleanStoryName(s)}`


            // zip.file(filename.replace(/[/\\?%*:|"<>]/g, "_"), blob as Blob)

            zip.file(filename.replace(" ", "_").replace(".", "_") + ".png", blob)
        })

        Promise.allSettled(promises as Promise<any>[])
            .then(_ => {
                console.log(_)
                zip.generateAsync({type: 'blob'}).then(v => saveAs(v, fileName("Images")))
            })
    }

    function fileName(label: string) {
        return `FranticFanfic_${roomCode}_${moment().format("MMM_DD_YYYY")}_${label}.zip`
    }

    function zippyTexts() {
        let zip = new JSZip();

        gameState.game?.stories?.forEach(s => {
            const filename = `${s.meta.index + 1}-${cleanStoryName(s)}`
            // zip.file(filename.replace(/[/\\?%*:|"<>]/g, "_"), storyToText(s))
            zip.file(filename.replace(" ", "_").replace(".", "_") + ".txt", storyToText(s))
        })

        zip.generateAsync({type: "blob"})
            .then(value => saveAs(value, fileName("Texts")))
    }

    function placingTitle(rank: number, points?: number) {
        if (rank === 1) {
            return `${constants.star} #${rank} - ${points} ${constants.star}`
        } else {
            return `#${rank} - ${points}`
        }
    }

    function subheaders() {
        if (gameState.game?.settings.game_mode !== GameMode.solo_mode) {
            return [t('storyResults.subheaders.react'), t('storyResults.subheaders.click'), t('storyResults.subheaders.download')]
        } else {
            return [t('storyResults.subheaders.downloadSolo'), t('storyResults.subheaders.tweetSolo')]
        }
    }

    return <GamePage footerJsx={footer} titles={[t('storyResults.headers.default')]}
                     subHeaders={subheaders()}
                     hack={gameState.game?.stories?.map(s => <CanvasView key={"canvas-" + s.id} story={s}/>)}>
        <div className={"story-results2-container2"}>
            <div className={"story-results2-row2"}>
                <div className={"story-results2-header story-results2-fanfic-column"}>FANFICS</div>
                <div className={"story-results2-header story-results2-player-column"}>WRITERS</div>
            </div>

            {storyAndPlayerRanks().map((value, idx) => {
                let story = value.story
                let storyRank = value.storyRank
                let player = value.player
                let playerRank = value.playerRank

                return <div key={"row-" + idx} className={"story-results2-row2-result"}>
                    {story && storyRank !== undefined && <div key={"story-results-" + idx}
                                                              className={"story-results2-fanfic-card2 story-results2-fanfic-column"}>
                        <div
                            className={"story-results2-story-placing"}>{placingTitle(storyRank, story ? story.meta.points : 0)}</div>
                        <StoryButton2 story={story} onClick={() => onStoryClick(idx)}/>
                    </div>}
                    {player && playerRank !== undefined && <div key={"player-" + idx}
                                                                className={"story-results2-player-card2 story-results2-player-column"}>
                        <div
                            className={"story-results2-player-placing"}>{placingTitle(playerRank, player ? player.data.points : 0)}</div>
                        <div className={"story-results2-avatar"}>
                            <Avatar4 spriteId={player ? player.meta.sprite_id : 0}
                                     name={player ? player.name : ''}/>
                            <ResultsAvatar player={player}/>
                        </div>
                    </div>}
                </div>
            })}

        </div>
        <div className={"story-results2-container2 story-results2-button-border"}>
            <div className={"story-download-container py-2"}>
                <button className={"story-list-download-all"} onClick={handleBulkDownloadImages}>Download Images
                </button>
                <button className={"story-list-download-all"} onClick={handleBulkDownloadText}>Download Texts
                </button>
            </div>
        </div>
    </GamePage>
}

export default Leaderboard