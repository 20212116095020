import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";

export let blog1Title = "Entry #1 - We're live"

export let blog1Author = "Posted by Zee / Friday April 2nd 2021"

export let blog1Content = <Body>
    <BodyText>
        Hi everyone! This is our first day being live! Well we have had this website up since Januaryish but this is the
        first time we're sharing it with everyone. It was pretty much ready to show off a couple weeks ago so we were
        like… hmm there's still a
        few things we want to add, but those can be done later. It's time to LAUNCH this puppy!!! Speaking of puppies,
        wonder what clicking the dog avatar does.. hmm...
    </BodyText>
    <BodyText>
        So some ground rules I
        guess before we get into the meat of this blog post. #1 - Neither of us are professional game makers. This is
        not our trade,
        we did not go to school for this. Don't expect this to be a crazy good game with top level graphics or… or even
        mid level graphics. We put them where we thought made sense, that's about it. COuld there be more graphics?
        SURE! Do I want to make them right now? Not really!
        I'm more interested in designing a physical version of this game at the moment... I think that would be pretty
        sweet.
        #2 - Please be nice. We made this
        game by committing a lot of our free time to making it into the best thing we could. Maybe you hate it. That's
        cool.
        Just don't send us a 10,000 word essay on how you could do it better. Because you can't, it's our idea and we
        hold the copyright for it. Frantic Fanfic is ours. HAHAHAH! #3 - HOLY JEEZE WE MADE A GAME AND IT'S ON THE
        INTERNET AND ANYONE CAN PLAY IT AT ANY HOUR OF THE DAY THAT'S SO NEAT!!!
        Ok so ground rules were more like little points I wanted to make. #1 we haven't really done this before (to this
        scale anyway) and #2 see point 1 and be nice to us for that reason hahah.
    </BodyText>

    <BodyText>
        So I wanted to chat a bit about the idea for making the game. I'm dating Michael. About 6 months or a year into
        our
        relationship (IDK I'm terrible with remembering details like that) he asks me if I have any ideas for a game or
        app that he could develop in his spare time as practice (Michael has a degree in computer science from the
        University of Toronto) and he knows I'm just full of fun ideas. Or so he thought. Because I did have a quick
        think
        about it, and what I gave to him was a click app. My idea there was everyone hates waiting around. Gotta wait
        around for 5 minutes somewhere? Guess I'll check my phone. See what's up. My idea was an app you could click,
        and it would record your clicks. The faster you could complete one thousand clicks, the higher up on the
        leaderboard you got (there would be a self leaderboard, social leaderboard, daily and weekly and monthly) and
        the clickier you were. I thought that was simple and fun, but Michael hated it. LOL. Okay, so we're not gonna
        make the Tapp app. I think that's the name I came up with… because it was an app you could tap. Anyway, I might
        be going too fast here. I actually should have started with my own experiences in the digital media realm. Or
        even further back, working with others on communications technology projects...
    </BodyText>
    <BodyText>
        In high school we were tasked with
        a project and I was away the day that we had the explanation and the rubric. So my teammates took some notes,
        gave them to me, and I directed our group's project because I was elected leader. We nearly failed it. Why? The
        notes were unclear, we failed to complete the most important task that was outlined on day one and... I felt
        terrible. Like dang, I really messed that up. Couldn't have been my team's fault, right? Well... Cut to me in
        university in my digital practices class, we're teaming up artists with coders and creating apps. I'm again the
        project leader, but a few people really are not pulling weight and I end up having to do a ton of work myself.
        But the way we decided what app to make was completely voted on as a team and I didn't get to pick the app I
        wanted to make and AAaaaaAaaAaa working with other people on things you want to bring to life but take SO LONG
        to do is actually incredibly soul crushing? So I was like okay. I'm not impressed with that. Is that what life
        is like? Dang it kinda is hahah. Again, cut to the future.
    </BodyText>
    <BodyText>
        Michael and I worked very well together on this app
        because of a few reasons. It was my idea, so he deferred all process to me. I asked him plenty of times if x or
        y made sense or he could ask me to do something one way or not, but generally it was up to one person. That's
        important, to have a general decision maker. Another reason we worked really well together is we were both
        equally excited to see it come to life. In other group projects I may have just been unlucky or maybe too
        passionate about school or learning for others… but our wavelengths of passion are there at the same level. And
        we both really liked the idea! When
        the website was coming together and things were clicking it was like wow… we're actually doing this!!! It really
        works!! AND IT'S COOL!! And I have not felt that with a digital project with someone else in a very long time.
        So Michael is great for that. I think
        another reason we're working very well together is that we can sense we're tired of the project for now after
        having it be our secret lives for so much time so we'd better release it before we get sick of it 😂.
    </BodyText>

    <BodyText>
        So here it is!!! Woohoo! Also, just so you all know I do have an idea on how to make moderators work and mod
        players but for now please just play it with people you trust. If you're streaming the game, hide the room code
        from viewers and give it to players individually to combat random players getting in. Or not, whatever, it's
        your life.

        Oh yeah, another thing. If you like this game, share it with people who you think will like it too! I wrote
        about why I came up with this idea in our
        "ABOUT" section but really I just want to see people laughing and having a good time. The pandemic sucks. Let's
        make it a little less sucky by coming up with weird and funny stories together.
    </BodyText>


    <BodyText>
        Zee
    </BodyText>

</Body>

export let blog = {
    title: blog1Title,
    author: blog1Author,
    content: blog1Content
}