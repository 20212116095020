import React from "react";
import "./styles.css"

type Option<T> = {
    value: T
    label?: string
}

type Props<T> = {
    name: string

    options: Option<T>[]

    value: T
    onSelect: (v: string) => void

    disabled?: boolean
    subHeader?: string

}

const SquareRadio2 = <T extends number | string>({name, options, value, onSelect, disabled, subHeader}: Props<T>) => {


    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        onSelect(e.target.value)
    }

    return <div className={"square-radio"}>
        {options.map((option, idx) => {
            return <div className={"square-radio-buttons"} key={`buttons-${name}-${idx}`}>
                <input tabIndex={0} disabled={disabled} name={name} type="radio" id={name + idx} value={option.value}
                       checked={option.value === value}
                       onChange={handleChange}/>
                <label htmlFor={name + idx} id={name + idx + "-label"}>
                    <div>{(option.label ?? option.value)}</div>
                    <div className={"square-radio-subheader"}>{subHeader}</div>
                </label>
            </div>
        })}
    </div>
}

export default SquareRadio2