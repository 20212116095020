import React from "react";
import '../../../text/help'
import {body} from "../../../text/help";
import MenuPage from "../menu-page/menu-page";
import {useTranslation} from "react-i18next";

type Props = {
    about: boolean
}

const Help = ({about}: Props) => {

    const {t} = useTranslation('main', {keyPrefix: "abouthelp"})

    return <MenuPage subHeaders={about ? [t('subheaders.aboutSubHeader')] : [t('subheaders.default')]}
                     titles={about ? [t('headers.aboutTitle')] : [t('headers.default')]}>
        <div className={"normal-case m-4 break-normal text-xl font-comic"}>
            {about ? body.about : body.howToPlay}
        </div>
    </MenuPage>
}
export default Help