type Props = {
    handleClick: () => void
    label: string
    label2?: string

    id?: string
}

const SelectGameButton: React.FunctionComponent<Props> = (props) => {


    function label() {

        if (props.label2) {
            return <>
                <div className={"text-4xl"}>{props.label}</div>
                <div className={"text-3xl"}>{props.label2}</div>
            </>
        } else {
            return <div>{props.label}</div>
        }


    }

    return <button id={props.id} className={"text-4xl leading-snug px-2"}
                   onClick={props.handleClick}>{label()}</button>

}

export default SelectGameButton