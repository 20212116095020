import CheckButton from "./check-button";
import {YesNoRadioButtons} from "../radio-buttons/radio-buttons";

type Option<T> = {
    label: string
    value: T
}

type Props<T> = {
    label: string
    subLabel?: string

    options: Option<T>[]

    onSelect: (val: T, checked: boolean) => void
    selected: Set<T>

    disabled?: (val: T) => boolean

    forcedPrompts: boolean
    yesLabel?: string
    noLabel?: string
    onForcePrompt: (p: boolean) => void
    hideForcedPrompts?: boolean

    multiPromptsLabel: string
    multiPrompts: boolean
    yesMulti?: string
    noMulti?: string
    onMultiPrompts: (p: boolean) => void

}

const CustomCheckButtons = <T, >(props: Props<T>) => {

    return <div className={"square-radio-group flex-col"}>
        {!props.hideForcedPrompts && <div className={`flex flex-row mb-2`}>
            {/*<GameSettingsLabel label={props.label} subLabel={props.subLabel}/>*/}
            {/*<SquareRadio2 name={props.label.replace(' ', '-')} options={[]}*/}
            {/*              value={'dfsgdfg'} onSelect={() =>{}}/>*/}

            <YesNoRadioButtons label={props.label} subLabel={props.subLabel} value={props.forcedPrompts}
                               yesLabel={props.yesLabel} noLabel={props.noLabel}
                               onSelect={props.onForcePrompt}/>
        </div>}

        {/* multi prompt */}
        {

            <YesNoRadioButtons label={props.multiPromptsLabel} value={props.multiPrompts} yesLabel={props.yesMulti}
                               noLabel={props.noMulti}
                               disabled={!props.forcedPrompts}
                               onSelect={props.onMultiPrompts}/>}


        <div className={"my-2 grid grid-cols-3 self-stretch gap-y-2 gap-x-2"}>
            {props.options.map(o => <CheckButton disabled={props.disabled && props.disabled(o.value)} key={o.label}
                                                 label={o.label} selected={props.selected.has(o.value)}
                                                 onSelect={(s => props.onSelect(o.value, s))}/>)}
        </div>

        {/*<div className={"create-game-button-container flex-col items-stretch space-y-2"}>*/}
        {/*    {props.options.map(o => <CheckButton key={o.label} label={o.label} selected={props.selected.has(o.value)}*/}
        {/*                                         onSelect={(s => props.onSelect(o.value, s))}/>)}*/}
        {/*</div>*/}


    </div>
}

export default CustomCheckButtons