import MenuPage from "../menu-page/menu-page";

const Loading = () => {


    return <MenuPage titles={["Loading..."]} hideAvatar disableBack>
        <div className={"flex-grow text-6xl flex justify-center items-center"}>
            Loading...
        </div>
    </MenuPage>
}
export default Loading