import React from "react";
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";

export let blog9Title = "Entry #9 - Twitch Streamers"

export let blog9Author = "Posted by Michael / Saturday May 29 2021"

export let blog9Content = <Body>
    <BodyText>
        Hi everyone!
    </BodyText>

    <BodyText>There are a couple of new additions to the website!</BodyText>

    <BodyText>Live Twitch streamers will now be visible at the bottom left of the desktop site.
        If you are a streamer all you need to do is make sure that your stream is categorized as 'Frantic Fanfic' on
        Twitch. We think that this change will help FF writers and streamers find each other!</BodyText>
    <BodyText>We also added a safety option to hide the room code while entering a game so streamers can hide that if
        they
        please.</BodyText>


    <BodyText>
        Currently we are working on improving the story writing process by fixing a few bugs. You may have noticed a
        flicker happening during the part transition... this is not intentional so we're trying to remove that.
        We're also planning on adding a submit button while writing to indicate to the VIP that you are done writing
        your part
        so that it shows up visually and will shift automatically if everyone clicks "done".</BodyText>


    <BodyText>
        We have a few exciting larger changes in the pipeline that we hope to share with you soon. New game modes and
        a new way to find players!
    </BodyText>

    <BodyText>Zee and Michael</BodyText>
</Body>

export let blog = {
    title: blog9Title,
    author: blog9Author,
    content: blog9Content
}