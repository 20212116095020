import {body} from "../../../text/supportus"
import MenuPage from "../menu-page/menu-page";
import {useTranslation} from "react-i18next";


const SupportUs = () => {
    const {t} = useTranslation('contact')

    return (<MenuPage titles={[t('headers.default')]}
                      subHeaders={[t('subheaders.default')]}>
        <div className={"normal-case m-4 text-2xl"}>
            {body.content}
        </div>
    </MenuPage>)
}

export default SupportUs