import React from "react";
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";
import BodyList, {BodyListItem} from "../../components/common/body-list/body-list";

export let blog10Title = "Entry #10 - Bug? Squashed. Get outta here, bugs."

export let blog10Author = "Posted by Michael / Saturday June 5th 2021"

export let blog10Content = <Body>
    <BodyText>Hi everyone!</BodyText>

    <BodyText>Hope you're all having fun with the new modes we introduced! We're also pretty jazzed that AS SOON AS WE
        UPDATED
        THE TWITCH STREAMER LINK SOMEONE WAS STREAMING THE GAME HAHA.
        So that was pretty amazing on our end. We're so happy the fanfic maker / generator / creative writing tool we've
        made has reached so many people and made a lot of happy times.
        Okay, that's the sappy stuff... Now for the bug update.</BodyText>

    <BodyText>We recently discovered a bug during the writing phase of Frantic Fanfic while playing where submissions
        would
        seemingly randomly
        fail to save. This was a rare occurrence but it happened to us twice during lightning mode.</BodyText>

    <BodyText>After digging into it we believe we found the cause of the bug and released a fix.
        Just so you all know, it is our highest priority to make sure everyone has a good experience while playing the
        game. </BodyText>

    <BodyText>As always, if anyone encounters any bugs or issues or things that don't seem quite right during a
        playthrough,
        please contact us at <a rel={"noreferrer"} target={"_blank"}
                                href={"https://twitter.com/franticfanfic"}> @franticfanfic</a> and

        let us know! We should get back to you within the day.</BodyText>

    <BodyText>We're also aware of connections being disconnected due to poor internet quality of players.
        In the situation where you have an unstable connection we recommend passing the role of the VIP to the person
        who has the strongest connection.
    </BodyText>
    <BodyText>IF YOU DO LOSE CONNECTION DUE TO YOUR INTERNET BEING UNSTABLE: To reconnect you need to make sure the name
        is the
        same one that you joined the game with previously. Click enter game and enter the same room code again.
        That should re-connect you, but we're hearing of some instances where the previous connection is sticking. In
        that situation, close the browser completely and try again.
        It should save all your writing from the point that you were disconnected from the game. We're working on an
        update that will allow players to connect twice and kick the previous
        connection automatically, but until that is pushed in the next update this is what we think is the easiest
        solution. </BodyText>

    <BodyText>We're working on a few new updates for the next code push. Take a look! </BodyText>
    <BodyList>
        <BodyListItem>Censorship abilities given to the VIP which will hopefully help out streamers prevent
            griefing.</BodyListItem>
        <BodyListItem> A "DONE!" flag that if pressed by everyone will automatically advance the game to the next
            round.</BodyListItem>
        <BodyListItem> Updates to our avatar characters! Opossum and Poulet Poulet will be available
            soon!</BodyListItem>
    </BodyList>


    <BodyText>
        Might not be this code push but we're also looking to add the ability for players to leave a lobby and come
        back. Game development is hard!! HAhah. Very fun tho. Real rewarding :)

        Thanks for playing!
    </BodyText>


    <BodyText>We would also like to thank MajesticMaehem for helping us test our fixes on such short notice! You're the
        best! </BodyText>
    <BodyText> - Michael and Zee </BodyText>

</Body>

export let blog = {
    title: blog10Title,
    author: blog10Author,
    content: blog10Content
}