import {Link} from "react-router-dom"

type Props = {
    path: string
    buttonText: string

    small?: boolean
}

const LinkButton: React.FC<Props> = ({path, buttonText, small}: Props) => {
    return <Link
        className="no-underline text-black hover:text-black visited:text-black"
        to={path} tabIndex={-1}>
        <button type="button"
                className={`w-full ${small ? 'text-2xl' : 'text-4xl'} leading-snug px-2 bg-gray-drk`}>{buttonText}</button>
    </Link>
}

export default LinkButton