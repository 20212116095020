import React from "react";
import {GameSettings} from "../types";
import {numSwitches, textNumber} from "./quick-start";


export const quickStartList = function (settings: GameSettings) {
    return (
        <>
            <u><b>QUICK START GUIDE</b></u>
            <ol>
                <li> Write {settings ? <b>{textNumber.get(settings.min_characters)}</b> : "some"} characters.</li>

                <li> Write the beginning of the story.</li>
                {numSwitches(settings?.num_switches)}

                <li>Title your work from the beginning.</li>
                <li>Take turns reading!</li>
                <li>React! more reacts = more points.
                </li>
                <li>Award your favourites with bonus points!</li>
            </ol>

            <p>
                The stories and writers that get the most reacts and
                bonus votes are the winners!
            </p>


        </>
    )
}

export const body = {

    // QUICK START GUIDE. INSIDE GAME. AFTER LOBBY. NOT MENU. NOT HOW TO PLAY.
    contentGOTIT: function (settings: GameSettings) {

        return (<>
                <div className={"quick-start-ol"}>

                    {/*<b><u>WELCOME TO FRANTIC FANFIC</u></b>*/}
                    {/*<p>Here's an overview on how this game mode works.</p>*/}

                    {quickStartList(settings)}

                    <p>yes! You can save your stories after the game.</p>
                    <p>
                        Do not share any personal identifiable information,
                        illegal activities, or otherwise harmful material within these stories.
                        By clicking "GOT IT" you agree to our full <a href="https://franticfanfic.com/terms"
                                                                      target="_blank" rel="noreferrer">Terms of
                        Service</a>.
                    </p>
                </div>


            </>
        )
    },
}

export const footers = {
    submit: "OK, GOT IT!",
    loading: "loading..."

}