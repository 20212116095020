import React, {useContext, useEffect, useState} from "react";
import "./styles.css"
import {config} from "../../../constants";
import Waitable from "../../common/waitable/waitable";
import {GameStatus} from "../game";
import {useAppSelector} from "../../../redux/store";
import GameFormPage from "../game-page/game-form-page";
import {GameContext} from "../../../contexts";
import {selectIsSolo} from "../../../redux/selectors";
import {Maturity} from "../../../types2";
import {useTranslation} from "react-i18next";
import {Character} from "../../../types";


const CreateStory = () => {

    const {submit} = useContext(GameContext)

    const playerState = useAppSelector(state => state.player)

    const settings = useAppSelector(state => state.game.game?.settings)

    const {t} = useTranslation('game')

    let isSolo = useAppSelector(selectIsSolo);

    const numChoices = settings?.max_characters ?? config.numChoices

    // const [loading, setLoading] = useState(isLoading)
    const [picks, setPicks] = useState(new Map<number, Character>())
    const [mature, setMature] = useState<boolean | undefined>(undefined)

    const [hasAllAges, setHasAllAges] = useState(false)


    useEffect(() => {
        let temp = false
        picks.forEach(p => {
                if (!p.meta.mature) {
                    temp = true
                }
            }
        )
        if (temp) {
            setMature(false)
            setHasAllAges(true)
        } else {
            setHasAllAges(false)
        }
    }, [picks])

    function gameSubmit() {
        let chars = Array.from(picks.keys()).map(value => {
            return {
                id: value,
            }
        });


        return {
            body: {
                story: {
                    id: playerState.player?.current_story?.id,
                    characters: chars,
                    settings: {
                        mature: mature
                    }
                }
            },
            code: "story/create",
        }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, char: Character) {
        let charId = parseInt(event.target.id)
        let s = new Map<number, Character>(picks)
        if (!event.target.checked) {
            s.delete(charId)
            setPicks(s)
        } else {
            if (picks.size >= numChoices) {
                let next = s.keys().next();
                s.delete(next.value)
            }
            s.set(charId, char)
            setPicks(s)
        }
    }


    function checked(id: number): boolean {
        return picks.has(id) || picks.has(id)
    }

    function allAges() {
        return settings?.maturity === Maturity.ALL_AGES
    }


    function handleMature(event: React.ChangeEvent<HTMLInputElement>) {
        setMature(event.target.value === "true")
    }

    function ready(): boolean {
        const charactersSelected = settings?.min_characters && (picks.size >= settings?.min_characters)

        const maturitySelected = settings?.maturity === Maturity.ALL_AGES || (mature !== undefined)

        return (charactersSelected && maturitySelected) || false
    }

    function subHeaders() {
        const range = settings?.min_characters !== settings?.max_characters

        const lines = []

        if (range) {
            lines.push(t('createStory.subheaders.defaultRange', {
                min: settings?.min_characters,
                max: settings?.max_characters
            }))
        } else {
            lines.push(t('createStory.subheaders.default', {
                number: settings?.min_characters
            }))
        }

        if (isSolo) {
            return lines
        }
        lines.push(t('createStory.subheaders.guide'))
        if (!allAges()) {
            lines.push(t('createStory.subheaders.maturity'))
        }
        return lines

    }


    return <Waitable playerActionHandler={submit} waitingFor={[GameStatus.GameStatusPostStoryCreate]}
                     waitHeader={t('createStory.headers.waiting')}>
        <GameFormPage ready={ready()}
                      submitText={t('createStory.footers.default')}
                      gameSubmit={gameSubmit}
                      subHeaders={subHeaders()}
                      titles={[t('createStory.headers.default')]}>
            <div className={"story-create max-w-full"}>

                <div className={"story-characters max-w-full"}>
                    {
                        playerState.player?.current_story?.characters && playerState.player?.current_story.characters.map(c =>
                            <div className={"story-characters-row max-w-full"} key={c.id}>
                                {settings?.character_maturity &&
                                    <div
                                        className={"w-24 flex justify-center items-center leading-none fake-button p-0.5 ml-2"}> {c.meta.mature ? '18+' : 'all ages'}</div>}
                                <input id={'' + c.id} type="checkbox" checked={checked(c.id)}
                                       onChange={(e) => handleChange(e, c)}/>
                                <label className={"max-w-full break-all"} htmlFor={'' + c.id}>{c.name}</label>
                            </div>)
                    }
                </div>

                {!allAges() && !isSolo ?
                    <div className={"select-row"}>
                        <div className={"mature-select-container"}>
                            <div className={"mature-select-title fake-button"}>
                                {t('createStory.body.maturity')}
                            </div>

                            <div className={"mature-select"}>
                                <div className={"mature-square-radio-group"}>
                                    <input id="mature-yes" name="mature" type="radio" value="true"
                                           onChange={handleMature}
                                           disabled={hasAllAges}
                                           checked={mature === true}/>
                                    <label htmlFor="mature-yes">18+</label>
                                    <input id="mature-no" name="mature" type="radio" value="false"
                                           onChange={handleMature}
                                           disabled={hasAllAges}
                                           checked={mature === false}/>
                                    <label htmlFor="mature-no">all ages</label>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
            </div>
        </GameFormPage>
    </Waitable>
}

export default CreateStory