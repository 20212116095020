import React from "react";

import "./styles.css"
import Streamer from "./components/common/twitch/twitch";
import {AdinplaySquare, AdinplayTall} from "./components/common/ad/adinplay";

type Props = {
    children?: React.ReactNode
}


const AppWrapper: React.FunctionComponent<Props> = props => {
    return <div className={"relative flex-grow items-stretch flex flex-row"}>
        <div className={"basis-0 flex-grow flex flex-col-reverse"}>
            <Streamer/>
        </div>
        <div className={"max-w-[640px] w-full flex flex-col-reverse"}>
            {props.children}
        </div>
        <div className={"basis-0 flex-grow flex flex-col-reverse"}>
            <AdinplayTall/>
            <AdinplaySquare/>
        </div>

    </div>
}

export default AppWrapper