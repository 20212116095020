import React, {useContext} from "react";
import {useAppSelector} from "../../../redux/store";
import FormPage from "../../common/formpage/formpage";
import AvatarHeader from "../../common/avatar-header/avatar-header";
import {Props} from "./game-page";
import {GameContext} from "../../../contexts";
import {PlayerAction} from "../../../types";


type FormProps = Props & {
    submit?: () => void
    gameSubmit?: () => PlayerAction


    ready?: boolean
    hack?: JSX.Element[]

    submitText?: string
    loadingText?: string

    countdownThreshold?: number
    readyFlash?: boolean
}
const GameFormPage: React.FunctionComponent<FormProps> = props => {

    const {submit} = useContext(GameContext)

    const playerState = useAppSelector(state => state.menu.playerDetails)
    const metaState = useAppSelector(state => state.meta)
    const roomCode = useAppSelector(state => state.menu.playerId?.room_code)
    const numAudience = useAppSelector(state => state.game.game?.extra.num_audience)

    const countdown = useAppSelector(state => state.game.game?.countdown)

    function handleSubmit() {
        if (props.submit) {
            props.submit()
        } else if (props.gameSubmit) {
            submit(props.gameSubmit())
        }
    }

    function extraLabel() {
        return numAudience !== undefined && numAudience > 0 ? numAudience.toString() : undefined
    }

    return <FormPage
        header={<AvatarHeader titles={props.titles} titlesJsx={props.titlesJsx} avatarName={playerState?.name}
                              avatarSpriteId={playerState?.meta.sprite_id}/>}

        subHeaderJsx={props.subHeaderJsx}
        subHeaders={props.subHeaders}

        loadingText={props.loadingText ?? "loading"}
        submitText={props.submitText ?? "submit"}
        roomCode={roomCode}
        extraTopLabel={extraLabel()}
        countdown={countdown}
        countdownThreshold={props.countdownThreshold}
        hack={props.hack}
        onBack={props.onBack}
        error={metaState.error}
        onSubmit={handleSubmit}
        connected={metaState.connected}
        ready={props.ready}
        readyFlash={props.readyFlash}
        loading={metaState.loading}>
        {props.children}
    </FormPage>

}

export default GameFormPage