import React from "react";
import {Story} from "../../../types";
import "./styles.css"
import {SafeJoin} from "../../../util";


type Props = {
    onClick?: () => void
    story: Story
    disabled?: boolean

    hideAuthors?: boolean
}

const StoryButton = ({onClick, story, disabled, hideAuthors}: Props) => {

    function handleClick() {
        if (onClick) {
            onClick()
        }
    }

    function storyTitle(story: Story) {
        return <div className={"story-button-content max-w-full"}>
            <div className={"text-xl underline font-bold break-words max-w-full"}>{story.meta.title}</div>
            <div>
                CHARACTERS: {SafeJoin(story.characters?.filter(c => c.selected).map<React.ReactNode>((c, a, b) =>
                <span className={"italic break-words"}
                      key={"char-" + c.id}>{c.name}</span>), ', ')}
            </div>

            {!hideAuthors && <div>
                WRITERS: {SafeJoin(story.submissions?.map<React.ReactNode>(s =>
                <span className={"italic"}
                      key={"player-" + s.player_id + "-" + s.id}>{s.player.name}</span>), ', ')}
            </div>}

            <div>
                {story.settings.mature ? 'rated 18+' : 'rated all ages'}
            </div>

        </div>
    }

    return <div className={"story-button-wrapper"}>
        <div className={"story-button-container"}>
            {onClick ? <button className={"story-button"} disabled={disabled}
                               onClick={handleClick}>{storyTitle(story)}</button> :
                <div className={"story-button fake-button"}>{storyTitle(story)}</div>}
        </div>
    </div>
}

export default StoryButton