import {useAppSelector} from "../../../redux/store";
import {constants} from "../../../constants";
import {useState} from "react";


type Props = {
    hideOutline?: boolean
}
const AudienceLabel: React.FC<Props> = ({hideOutline}) => {
    const numAudience = useAppSelector(state => state.game.game?.extra.num_audience)

    const [hidden, setHidden] = useState(false)

    function handleClick() {
        if (hideOutline) {
            setHidden(!hidden)
        }
    }


    return numAudience ? <label onClick={handleClick}
                                className={`app-top-roomLabel cursor-auto`}>{hidden ? constants.littleTriangle : `${constants.audience}${numAudience} `}</label> : <></>
}

export default AudienceLabel