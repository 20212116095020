import {useEffect, useRef, useState} from "react";

const KONAMI_CODE = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];

export const useKonami = () => {

    const [success, setSuccess] = useState(false)

    const konamiIndex = useRef<number>(0)

    useEffect(() => {
        const handler = (e: { keyCode: number; }) => {
            if (!success && e.keyCode === KONAMI_CODE[konamiIndex.current]) {
                if (konamiIndex.current === KONAMI_CODE.length - 1) {
                    setSuccess(true)
                } else {
                    konamiIndex.current += 1
                }
            } else if (e.keyCode !== KONAMI_CODE[konamiIndex.current]) {
                if (e.keyCode === KONAMI_CODE[0]) {
                    konamiIndex.current = 1
                } else {
                    konamiIndex.current = 0
                }
            }
        }


        window.addEventListener('keydown', handler)

        return () => window.removeEventListener('keydown', handler)
    }, [success])

    return success


}

export default useKonami