type Props = {
    text: string | JSX.Element
    hidden?: boolean
}


const StoryTransitionRow: React.FC<Props> = (props) => {

    return <div
        className={`text-center text-lg bg-gray-drk shadow-basic p-2 my-1 ${props.hidden ? 'invisible' : 'visible'}`}>
        {props.text}
    </div>
}

export default StoryTransitionRow