import Avatar4 from "../avatar4/avatar";
import React from "react";
import {Player} from "../../../types";

type Props2 = {
    player: Player
    onClick: (player: Player) => void
    checked: boolean
}
const ClickableAvatar = ({player, onClick, checked}: Props2) => {

    function handleClick() {
        onClick(player)
    }


    return <div onClick={handleClick} className={`cursor-pointer ${checked ? "bg-border-magenta" : ''}`}>
        <Avatar4 key={player.id} name={player.name}
                 spriteId={player.meta.sprite_id}/>
    </div>
}

export default ClickableAvatar