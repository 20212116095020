import {useEffect, useRef} from "react";

function useInterval(callback: Function, interval: number) {
    const savedCallback = useRef(callback);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        let id = setInterval(tick, interval);
        return () => clearInterval(id);

    }, [interval]);
}

export default useInterval