import {config, constants} from "../../../constants";
import {Avatar} from "./name";
import {useEffect, useState} from "react";
import Avatar6 from "../../common/avatar6/avatar6";

export type ImgProps = {
    spriteId: number,
    setSpriteId: (img: number) => void
    curNames: Avatar[]
}
const ImgPicker = ({spriteId, setSpriteId, curNames}: ImgProps) => {

    let back = constants.leftButton
    let forward = constants.rightButton

    const [idx, setIdx] = useState(0)

    useEffect(() => {
        let abc = curNames.findIndex(c => c.id === spriteId)
        if (abc >= 0) {
            setIdx(abc)
        }
    }, [curNames, spriteId])

    // useEffect(() => {
    //     if (idx < curNames.length && idx >= 0) {
    //         setSpriteId(curNames[idx].id)
    //     }
    // }, [curNames, idx, setSpriteId])

    const [dogPet, setDogPet] = useState(false)

    useEffect(() => {
        let interval = setTimeout(() => {
            setDogPet(false)
        }, 1000)
        return () => clearInterval(interval)
    }, [dogPet])


    function handleClick() {
        if (spriteId === 4) {
            setDogPet(true)
        } else if (spriteId === 22) {
            window.open(config.pouletUrl, "_blank")
        }
    }

    function handleButton(n: number) {
        setIdx(n)
        setSpriteId(curNames[n].id)
    }

    return (<div className={"text-7xl flex justify-center self-stretch"}>

        <button className={"text-7xl self-center px-2 leading-none"} type="button" onClick={() => {
            handleButton((((idx - 1) % curNames.length) + curNames.length) % curNames.length)
        }}>
            {back}
        </button>
        <div className={"h-auto aspect-1"} onClick={handleClick}
             style={spriteId === 4 || spriteId === 22 ? {cursor: "pointer"} : {}}>
            <Avatar6 spriteId={spriteId} noAnimate special={dogPet} big/>
        </div>
        <button className={"text-7xl self-center px-2 leading-none"} type="button" onClick={() => {
            handleButton((idx + 1) % curNames.length)
        }}>
            {forward}
        </button>
    </div>)
}
export default ImgPicker