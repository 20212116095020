import React from "react";
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";
import BodyTitle from "../../components/common/body-title/body-title";

export let blogTitle = "Happy New Year"

export let blogAuthor = "Posted by Zee / Jan 2023"

export let blogContent = <Body>

    <BodyTitle>OMG AN UPDATE!</BodyTitle>
    <BodyText>
Hiya!! Zee here, omg the last update we did was in Feb last year. Needless to say, Michael and I got EXTREMELY BUSY IN 2022 WITH OTHER PROJECTS! We made it a goal over the winter holiday to push some changes that needed to happen and overall just do a bit of a cleanup on the site.
    </BodyText>
    <BodyTitle>NEW! BUG REPORT BUTTON!</BodyTitle>
    <BodyText>
     We added a super easy way to report any bugs. You need to be logged in to report the bug. Click on the "REPORT A BUG" button to check it out!
    </BodyText>
    <BodyTitle>WHAT TOOK YA SO LONG?</BodyTitle>
    <BodyText>
Michael fixed some bugs, I went over the site and tried to make it as readable as possible, we removed some buttons that didn't really make any sense and we deleted public lobbies.
        Why delete public lobbies? Because we didn't want to make a separate chat client for the game, and without that people have told us using public lobbies is really difficult and frustrating. So instead of having a bad experience for
        people using PL, we decided to get rid of it completely. You can still make use of our Discord to find people to play with if you are over 18.
    </BodyText>
    <BodyText>
Also! A big milestone for us as 2022 closed out: we hit 200 supporters! Thank you so much!
    </BodyText>
    <BodyText>
Happy New Year! Until next time!
    </BodyText>
    <BodyText>
        Zee and Michael
    </BodyText>
</Body>

export let blog = {
    title: blogTitle,
    author: blogAuthor,
    content: blogContent
}