import {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";


export function useToken() {

    const {isAuthenticated, getAccessTokenSilently} = useAuth0()

    const [token, setToken] = useState<string | undefined>()


    useEffect(() => {
        async function getToken() {
            if (isAuthenticated) {
                await getAccessTokenSilently().then(t => {
                    setToken(t)

                })

            } else {
                setToken(undefined)
            }
        }

        getToken().catch(console.error)

    }, [getAccessTokenSilently, isAuthenticated])

    return token
}