import React from 'react';
import MenuPage from "../menu-page/menu-page";
import {useTranslation} from 'react-i18next';
import SelectGameButton from "./select-game-button";


type Props = {
    onCreateGame: () => void
    onEnterGame: () => void
    onEnterAudience: () => void
}


const SelectGame = ({onCreateGame, onEnterGame, onEnterAudience}: Props) => {

    // let [numRooms, setNumRooms] = useState(0)


    const {t} = useTranslation('main', {keyPrefix: "start"})
    //
    // useEffect(() => {
    //     RoomAPI.CountRooms().then(r =>
    //         setNumRooms(r.count)
    //     )
    // }, [])


    return (
        <MenuPage titles={[t('headers.default')]}
                  subHeaders={[t('subheaders.defaultOne'), t('subheaders.lineThree'), t('subheaders.defaultTwo'), t('subheaders.defaultThree')]}>
            <div className={"flex-1 flex flex-col justify-center items-center py-2"}>

                <div className={"flex flex-col items-stretch space-y-4"}>

                    <SelectGameButton id={"create-game-button"} handleClick={onCreateGame}
                                      label={t('body.createGame')}/>
                    <SelectGameButton id={"join-game-button"} handleClick={onEnterGame} label={t('body.enterGame')}/>
                    <SelectGameButton id={"join-audience-button"} handleClick={onEnterAudience}
                                      label={t('body.enterGameAudience')}/>

                    {/*<LinkButton path={"/howtoplay"}*/}
                    {/*            buttonText={t('body.help')}/>*/}
                </div>

            </div>
        </MenuPage>)


}

export default SelectGame