import {Countdown} from "../../../types";
import {useEffect, useState} from "react";
import "./styles.css"
import moment from "moment";

type Props = {
    countdown: Countdown
    noBreak?: boolean

    threshold?: number
}


export function useCountdown(countdown: Countdown) {

    const [timeRemaining, setTimeRemaining] = useState(moment.now() - (countdown.limit > 0 ? countdown.limit : countdown.hard_limit))

    useEffect(() => {
        if (countdown) {
            let interval = setInterval(() => {
                setTimeRemaining(moment.now() - (countdown.limit > 0 ? countdown.limit : countdown.hard_limit))
            }, 500)

            return () => {
                clearInterval(interval)
            }
        }
    }, [countdown])

    return timeRemaining
}

const CountdownComponent = ({countdown, noBreak, threshold}: Props) => {


    const timeRemaining = useCountdown(countdown)


    const secondsRemaining = -1 * timeRemaining / 1000;
    const paddedMinutes = Math.floor(secondsRemaining / 60).toString().padStart(2, "0");
    const paddedSeconds = (Math.floor(secondsRemaining) % 60).toString().padStart(2, "0")


    return <div className={"countdown-container"}>
        {/*<strong className={`${threshold && secondsRemaining < threshold && 'countdown-warning'}`}>{paddedMinutes}:{paddedSeconds}</strong>*/}
        {threshold !== undefined && secondsRemaining < threshold ?
            <div className={"countdown-warning"}>{paddedMinutes}:{paddedSeconds}</div> :
            <div>{paddedMinutes}:{paddedSeconds}</div>}
    </div>

}

export default CountdownComponent