import React from "react";
import Body from "../components/common/body/body";
import BodyText from "../components/common/body-text/body-text";
import BodyTitle from "../components/common/body-title/body-title";
import StyledHeader from "../components/common/styled-header/styled-header";


export const body = {
    code: <Body>
        <StyledHeader header={"Frantic Fanfic"} subHeader={"A game for creative fun with your friends."}/>
        {/*<BodyText>Frantic Fanfic is a website designed for having some creative fun with your friends.</BodyText>*/}
        <BodyText>In order to make sure this space keeps being exactly that - we have written a code of conduct for the benefit
            of all users.</BodyText>
        <BodyTitle>1. <u>USE BY MINORS</u></BodyTitle>

        <BodyText>
            All minors (persons under 18 years of age or the age of majority in their jurisdiction) are restricted to
            the “ALL AGES” rooms. Minors may not enter any Frantic Fanfic lobby for 18+ “MATURE” rooms under any
            circumstance. All minors must have their parent or guardian’s permission before using the site.</BodyText>

        <BodyTitle>2. <u>RATINGS</u></BodyTitle>

        <BodyText>
            Choose your room rating appropriately. If “ALL AGES” is selected the tone of the fanfic written should
            adhere to an all ages environment with no sexual content, harmful scenes or graphic violent acts. If
            “MATURE” 18+ is selected adult themes may be written about. Adhere to the requested rating that a player has
            selected for a character to be written about.
        </BodyText>
        <BodyTitle>3. <u>PROHIBITED CONTENT</u></BodyTitle>

        <BodyText>
            Content which is considered to be a violation of Canadian Law. Such as… Age Of Consent: Sexual content of
            characters which are less than 16 years old. Illegal Sex Acts: necrophilia, incest, bestiality, etc.
            Organized Hate Groups: stories about groups that exist to violently enact their terrorist activity. Hate
            speech based on race, gender identity, religious affiliation, ethnicities, ages, sexual orientations or
            otherwise threatening/harassment.
        </BodyText>
        <BodyTitle>4. <u>PERSONAL INFORMATION</u></BodyTitle>

        <BodyText>
            Don’t share personal information in these stories. Do not use phone numbers, places of work, home addresses,
            etc. Do not use full names of people that you know personally. We can remove stories from our database if
            you wrote something by accident but it’s best to not write it in the first place.
        </BodyText>
        <BodyTitle>5. <u>RESPECT</u></BodyTitle>

        <BodyText>
            Usernames, characters and stories should not consist of hate speech. We have implemented a block list on our
            character screen as well as our avatar screen. Do not attempt to bypass the word filter - if a word is
            blocked from use, don’t use it. Treat your fellow players with respect!
        </BodyText>
        <BodyTitle>6. <u>IMPERSONATION </u></BodyTitle>

        <BodyText>
            Never impersonate the developers of Frantic Fanfic. All official Frantic Fanfic email comes from
            franticfanficgame at gmail dot com.
        </BodyText>

        <BodyText>
            <b> DATE</b>: Jan 2 2023
        </BodyText>
    </Body>
}
