import React, {ReactNode} from "react";
import {constants} from "../../../constants";
import Avatar6 from "../avatar6/avatar6";


type Props = {
    titles?: string[]
    titlesJsx?: ReactNode[]

    avatarName?: string
    avatarSpriteId?: number

    hideAvatar?: boolean
    tall?: boolean
}


const AvatarHeader = ({titles, titlesJsx, avatarName, avatarSpriteId, hideAvatar, tall}: Props) => {

    function showAvatar() {
        return !hideAvatar && (avatarName !== undefined && avatarSpriteId !== undefined)
    }


    return <div
        className={`relative flex flex-row text-center justify-center items-center text-xl bg-gray-drk border-black border-b-4 ${tall && 'min-h-[10rem]'}`}>

        <div className={"flex-1 h-full"}>
            {/*<div className={"h-full flex flex-col justify-start items-start space-y-1 md:hidden"}>*/}
            {/*    <RoomCode hideOutline/>*/}
            {/*    <AudienceLabel hideOutline/>*/}
            {/*</div>*/}

        </div>

        <div
            className={"break-normal z-50 p-0.5 flex-grow self-center flex flex-col justify-center items-center min-w-0"}>
            {titles
                ? titles.map(value => <div className={"break-normal max-w-full"}
                                           key={value}>{constants.bigTriangle} {value} {constants.bigTriangle}</div>)
                : titlesJsx ? titlesJsx.map((value, idx) => <div className={"break-normal max-w-full"}
                                                                 key={"character-styled-header-" + idx}>{idx === 0 ? constants.bigTriangle : ''} {value} {idx === 0 ? constants.bigTriangle : ''}</div>) : ''}
        </div>

        <div className={"flex-1 flex flex-row justify-end"}>
            {showAvatar() && avatarSpriteId !== undefined && <Avatar6 name={avatarName} spriteId={avatarSpriteId} small/>}
        </div>
    </div>
}

export default AvatarHeader