import {blog as blog1} from "./blog1";
import {blog as blog2} from "./blog2";
import {blog as blog3} from "./blog3";
import {blog as blog4} from "./blog4";
import {blog as blog5} from "./blog5";
import {blog as blog6} from "./blog6";
import {blog as blog7} from "./blog7";
import {blog as blog8} from "./blog8";
import {blog as blog9} from "./blog9";
import {blog as blog10} from "./blog10";
import {blog as blog11} from "./blog11";
import {blog as blog12} from "./blog12";
import {blog as blog13} from "./blog13";
import {blog as blog14} from "./blog14";
import {blog as blog15} from "./blog15";
import {blog as blog16} from "./blog16";
import {blog as blog17} from "./blog17";
import {blog as blog18} from "./blog18";
import {blog as blog19} from "./blog19";
import {blog as blog20} from "./blog20";
import {blog as blog21} from "./blog21";
import {blog as blog22} from "./blog22";

type Blog = {
    title: string
    author: string
    content: JSX.Element
}

export const blogs: Blog[] = [
    blog1,
    blog2,
    blog3,
    blog4,
    blog5,
    blog6,
    blog7,
    blog8,
    blog9,
    blog10,
    blog11,
    blog12,
    blog13,
    blog14,
    blog15,
    blog16,
    blog17,
    blog18,
    blog19,
    blog20,
    blog21,
    blog22
]