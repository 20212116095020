export function SafeJoin(array: React.ReactNode[] | undefined, delim: string) {
    if (array === undefined || array.length === 0) {
        return ''
    } else {
        return array.reduce((prev, cur) => [prev, delim, cur])
    }
}

export function makeNice(arr: Array<React.ReactNode>) {
    if (arr.length === 1) return arr[0];
    const firsts = arr.slice(0, arr.length - 1);
    const last = arr[arr.length - 1];
    return <>{SafeJoin(firsts, ', ')} and {last}</>;
}