import React, {ReactNode, useContext} from "react";
import {useAppSelector} from "../../../redux/store";
import AvatarHeader from "../../common/avatar-header/avatar-header";
import Page from "../../common/page/page";
import {MenuContext} from "../../../contexts";

export type Props = {
    titlesJsx?: ReactNode[]
    titles?: string[]

    subHeaderJsx?: JSX.Element
    subHeaders?: string[]

    footer?: JSX.Element
    footerJsx?: JSX.Element // inside <Footer>
    footerLabel?: string

    disableBack?: boolean
    hideAvatar?: boolean

    overrideBack?: () => void
    children?: React.ReactNode
}

const MenuPage: React.FunctionComponent<Props> = props => {

    const menu = useContext(MenuContext)

    const playerDetails = useAppSelector(state => state.menu.playerDetails)

    const metaState = useAppSelector(state => state.meta)


    function handleBack() {
        if (props.overrideBack) {
            props.overrideBack()
        } else {
            menu.onBack()
        }
    }

    return <Page
        header={<AvatarHeader hideAvatar={props.hideAvatar} titles={props.titles} titlesJsx={props.titlesJsx}
                              avatarName={playerDetails?.name}
                              avatarSpriteId={playerDetails?.meta.sprite_id}/>}

        subHeaderJsx={props.subHeaderJsx}
        subHeaders={props.subHeaders}
        footerJsx={props.footerJsx}
        footer={props.footer}
        footerLabel={props.footerLabel}

        onBack={props.disableBack ? undefined : handleBack}
        error={metaState.error}
        hideConnected={true}
        connected={metaState.connected}>
        {props.children}
    </Page>

}

export default MenuPage