import React from "react";
import {Story} from "../../../types";
import "./styles.css"
import AvatarHeader from "../avatar-header/avatar-header";
import {makeNice, SafeJoin} from "../../../util";
import {PrevSubmissionRead} from "../prev-submission/prev-submission";

type Props = {
    story: Story
}

const CanvasView = ({story}: Props) => {


    const headerNoPlayer = <AvatarHeader tall
                                         titlesJsx={[
                                             <span className={"underline font-bold"}>{story.meta.title}</span>,
                                             <>characters: {SafeJoin(story.characters?.filter(c => c.selected).map<React.ReactNode>(c =>
                                                 <span className={"italic"}
                                                       key={"char-" + c.id}>{c.name}</span>), ', ')} </>,
                                             <>writers: {makeNice(story.submissions.map<React.ReactNode>(s => <span
                                                 className={"italic"}
                                                 key={"player-" + s.id}>{s.player.name}</span>))} </>,
                                             'Rated ' + (story.settings.mature ? '18+' : 'ALL AGES')]}/>


    return <div className={"canvas-view-container"}>
        <div id={"canvas-" + story.id} className={"canvas-view-book"}>
            {headerNoPlayer}
            <div className={"canvas-page-body"}>
                <div className={"canvas-story-view-container"}>
                    {story.submissions.map<React.ReactNode>((s, idx) => {
                        return <div key={"canvas-submission-" + s.id}>
                            <div key={s.id} className={"canvas-story-view-submission"}>
                                <PrevSubmissionRead submission={s} isCensored={s.meta.censored}/>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>

}

export default CanvasView