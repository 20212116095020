import React from "react";

export const privacy = <>

    <h2><u>Introduction</u></h2>

    <p>
        Frantic Fanfic’s and our affiliates and subsidiaries (“Company,” “We,” “US,” “Our,” or “Frantic”) respects your
        privacy and is committed to protecting it by complying with this Privacy Policy (the “Policy”).
    </p>

    <p>
        This Policy describes:
    </p>

    <ul>
        <li>How we collect, use, disclose, and protect the personal information of our website users ("<b>you</b>").
        </li>
        <li>The types of information we may collect from you or that you may provide when you visit our website, use our
            app, or any other software product (collectively, our “Platform”).
        </li>
        <li>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</li>
    </ul>

    <p>Your privacy is important to us.</p>

    <p>It is Frantic Fanfic's policy to respect your privacy regarding any information we may collect while operating
        our website. This Privacy Policy applies to franticfanfic.com (hereinafter, "us", "we", or "franticfanfic.com").
        We respect your privacy and are committed to protecting personally identifiable information you may provide us
        through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be
        collected on our Website, how we use this information, and under what circumstances we may disclose the
        information to third parties. This Privacy Policy applies only to information we collect through the Website.
    </p>
    <p>
        This Privacy Policy, subsumed under the Terms of service posted on our Website, set forth the general rules and
        policies governing your use of our Website. Depending on your activities when visiting our Website, you may be
        required to agree to additional terms of service.
    </p>

    <p>This policy <b>does not</b> apply to information that:
    </p>

    <ul>
        <li>We collect offline or through any other means, including on any other Company or third-party website
            (including our affiliates and subsidiaries).
        </li>
        <li>You provide to or is collected by any third party (including our affiliates and subsidiaries), through any
            application or content (including advertising) that may link to or be accessible from or on the Website.
        </li>
    </ul>


    <h2>1. <u>Website Visitors</u></h2>
    <p>Like most website operators, Frantic Fanfic collects non-personally-identifying information of the sort that web
        browsers and servers typically make available, such as the browser type, language preference, referring site,
        and the date and time of each visitor request. Frantic Fanfic's purpose in collecting non-personally identifying
        information is to better understand how Frantic Fanfic's visitors use its website. From time to time, Frantic
        Fanfic may release non-personally-identifying information in the aggregate, e.g., by publishing a report on
        trends in the usage of its website for general knowledge or statistical insights regarding the game.</p>
    <p>Frantic Fanfic also collects potentially personally-identifying information like Internet Protocol (IP) addresses
        for logged in users and for users leaving comments on https://franticfanfic.com blog posts. Frantic Fanfic only
        discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses
        personally-identifying information as described below.
    </p>
    <h2>2. <u>Advertisements, Advertising Networks and Cookies</u></h2>
    <p>Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These
        cookies allow the ad server to recognize your computer each time they send you an online advertisement to
        compile information about you or others who use your computer. This information allows ad networks to, among
        other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy
        Policy covers the use of cookies by Frantic Fanfic and does not cover the use of cookies by any advertisers.</p>
    <p>We use the information that you provide, and your interaction with our products or services, to infer your likely
        interests and consumer preferences in order to provide you with more relevant advertising. This information
        tells us about websites you’ve visited, apps you’ve downloaded, and purchases you have made so that we can
        predict what might interest you in the future as well as assess the effectiveness of the advertising on our
        Platform. We collect this information through the use of Cookies and similar technologies on our Platform and
        from similar information received from third parties who advertise on our Platform and whose sites you visit.
    </p>
    <p>We and our vendors and service providers use cookies and other similar technologies automatically collect
        information and assess which pages you click on and how you use our platform, to enhance your user experience
        and provide you with more relevant advertisement. By using any product within our Platform, you consent to our
        use of cookies, and other similar technology, to collect such described information. The use of such information
        by third parties is not covered by this Policy and we are not responsible for the privacy and information
        practices of such third parties.
    </p>
    <p>You may be able to refuse or disable the use of cookies and other similar technology by adjusting your browser or
        device settings. Please note that doing so may limit the functionality of our products and may alter your
        personal use of them.
    </p>
    <h2>3. <u>Links To External Sites</u></h2>
    <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link,
        you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms
        of service of every site you visit.
    </p>
    <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third
        party sites, products or services.
    </p>
    <h2>4. <u>Privacy Policy Changes</u></h2>
    <p>Although most changes are likely to be minor, Frantic Fanfic may change its Privacy Policy from time to time, and
        in Frantic Fanfic's sole discretion. Frantic Fanfic encourages visitors to frequently check this page for any
        changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will
        constitute your acceptance of such change.
    </p>
    <h2>5. <u>Information that We Collect About You</u></h2>
    <p>We collect and use several types of information from and about you, including:</p>
    <ul>
        <li><p><b>Personal information</b>, that we can reasonably use to directly or indirectly identify you, such as
            your
            name, mailing address, e-mail address, birth date, gender, telephone number, credit card information, other
            billing information, country of residence, Internet protocol (IP) address used to connect your computer to
            the Internet, username or other similar identifier, billing and account information, and any other
            identifier we may use to contact you ("<b>personal information</b>").</p>
            <p>At the time of collection, we will clearly identify the information being collected and the purposes for
                which it will be used. It is always your choice whether or not to provide Personal Information but if
                you choose not to provide certain requested Personal Information, in some instances you may not be able
                to register to use our products or access our products at all. In other instances, your choice not to
                provide certain other Personal Information may mean that you will not be able to use certain features of
                the Website or services. We may collect Personal Information in respect of our products through
                registration processes; communications with you; information downloads; service use; purchases; user
                support; and surveys</p>
            <p>We provide an opportunity for any user to unsubscribe from our e-mails or opt-out of contact for
                marketing purposes on an ongoing basis by using the unsubscribe mechanism at the bottom of our e-mails
                or e-mailing to franticfanficgame@gmail.com
            </p>
        </li>
        <li><b>Your profile information</b>, including your username, age, e-mail address and/or telephone number,
            information you disclose in your user profile, and your profile picture.
        </li>
        <li><b>Non-personal information</b>, that does not directly or indirectly reveal your identity or directly
            relate to an identifiable individual, such as demographic information, or statistical or aggregated
            information. Statistical or aggregated data does not directly identify a specific person, but we may derive
            non-personal statistical or aggregated data from personal information. For example, we may aggregate
            personal information to calculate the percentage of users accessing a specific feature or to report our
            product impact on an aggregate basis.
        </li>
        <li><b>Technical information</b>, including your login information, browser type and version, time zone setting,
            browser plug-in types and versions, operating system and platform, or information about your internet
            connection, the equipment you use to access our Website, and usage details.
        </li>
        <li><b>Non-personal details about your interactions with our product</b>, including the full Uniform Resource
            Locators (URLs), clickstream to, through and from our Website, App, or other products (including date and
            time),
            products you viewed or searched for, page response times, download errors, length of visits to certain
            pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse
            away from the page, or any phone number used to call our customer service number.
        </li>

    </ul>


    <h2>6. <u>Data Security</u></h2>
    <p>
        The security of your personal information is very important to us. Although we do our best to protect your
        personal information, we cannot guarantee the security of your personal information transmitted to our Website.
        Any transmission of personal information is at your own risk. We are not responsible for circumvention of any
        privacy settings or security measures contained on the Website
    </p>
    <h2>7. <u>Data Retention</u></h2>
    <p>
        Except as otherwise permitted or required by applicable law or regulation, we will only retain your personal
        information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of
        satisfying any legal, accounting, or reporting requirements. Under some circumstances we may anonymize your
        personal information so that it can no longer be associated with you. We reserve the right to use such anonymous
        and de-identified data for any legitimate business purpose without further notice to you or your consent.

    </p>
    <h2>8. <u>Accessing and Correcting Your Personal Information</u></h2>
    <p>
        It is important that the personal information we hold about you is accurate and current. Please keep us informed
        if your personal information changes. By law you have the right to request access to and to correct the personal
        information that we hold about you.
    </p>
    <p>
        If you want to review, verify, correct, or withdraw consent to the use of your personal information you may also
        send us an email at franticfanficgame@gmail.com to request access to, correct, or delete any personal
        information that you have provided to us.
    </p>
    <p>
        We may request specific information from you to help us confirm your identity and your right to access, and to
        provide you with the personal information that we hold about you or make your requested changes. If we cannot
        provide you with access to your personal information, we will inform you of the reasons why, subject to any
        legal or regulatory restrictions.
    </p>
    <p>
        If you are concerned about our response or would like to correct the information provided, you may contact us by
        sending us an e-mail stating your concern to franticfanficgame@gmail.com.
    </p>
    <h2>9. <u>Withdrawing Your Consent</u></h2>
    <p>
        Where you have provided your consent to the collection, use, and transfer of your personal information, you may
        have the legal right to withdraw your consent under certain circumstances. To withdraw your consent, if
        applicable, contact us using the contact information listed below. Please note that if you withdraw your consent
        we may not be able to provide you with a particular product or service. We will explain the impact to you at the
        time to help you make your decision
    </p>
    <h2>10. <u>Changes to Our Privacy Policy</u></h2>
    <p>
        We may update our privacy policy from time to time. It is our policy to post any changes we make to our privacy
        policy on this page. If we make material changes to how we treat our users' personal information, we will post
        the new privacy policy on this page with a notice that the privacy policy has been updated.
    </p>
    <p>
        We include the date the privacy policy was last revised at the top of the page. You are responsible for ensuring
        we have an up-to-date, active, and deliverable email address for you, and for periodically visiting this privacy
        policy to check for any changes.
    </p>
    <h2>11. <u>Contact Information and Challenging Compliance</u></h2>
    <p>
        We welcome your questions, comments, and requests regarding this privacy policy and our privacy practices.
        Please contact us at franticfanficgame@gmail.com. We have procedures in place to receive and respond to
        complaints or inquiries about our handling of personal information, our compliance with this policy, and with
        applicable privacy laws. To discuss our compliance with this policy please contact us using the contact
        information listed above.
    </p>
</>