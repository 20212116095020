import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import jwtDecode from "jwt-decode";
import {config} from "../../../constants";
import {User} from "../../../types";
import {useAppSelector} from "../../../redux/store";


const premiumClaim = 'https://franticfanfic.com/premium'
const connClaim = 'https://franticfanfic.com/conn'
const screenName = 'https://franticfanfic.com/screen_name'


export function usePremium(): boolean {

    const user = useUser()


    return user?.premium ?? false
}

export function useUser(): User | undefined {
    // const {isAuthenticated, getAccessTokenSilently, user} = useAuth0();

    const selectUser = useAppSelector(state => state.menu.user)


    return selectUser

}


function useLoggedIn() {
    const {user, isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    const [premium, setPremium] = useState(false)

    const [token, setToken] = useState("")

    const [conn, setConn] = useState("")

    const [name, setName] = useState("")


    useEffect(() => {
        async function getUserMetadata() {

            const accessToken = await getAccessTokenSilently({
                authorizationParams: {audience: config.auth0Audience},
            });

            setToken(accessToken)

            const decoded: any = jwtDecode(accessToken);

            setPremium(decoded[premiumClaim])
            setConn(decoded[connClaim])
            setName(decoded[screenName])

        }

        if (isAuthenticated) {
            getUserMetadata().catch(console.error)
        } else {
            setPremium(false)
        }
    }, [getAccessTokenSilently, isAuthenticated, user?.sub])

    return {
        premium: premium,
        userDetails: user,
        token: token,
        loading: isLoading,
        conn: conn,
        username: name ?? user?.name
    }

}

export default useLoggedIn