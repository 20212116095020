import React, {useState} from "react";
import {PlayerAction} from "../../../types";
import './styles.css'
import Waitable from "../../common/waitable/waitable";
import {GameStatus} from "../game";
import {useAppSelector} from "../../../redux/store";
import GameFormPage from "../game-page/game-form-page";
import {selectIsSolo} from "../../../redux/selectors";
import {Maturity} from "../../../types2";
import {useTranslation} from "react-i18next";
import {numberString} from "./character-submit";

type Props = {
    footerLabel?: string
    onSubmit: (playerAction: PlayerAction) => void
}

const OtpSubmit = ({onSubmit}: Props) => {

    const gameState = useAppSelector(state => state.game)
    const playerState = useAppSelector(state => state.player)

    const {t} = useTranslation('game')
    const [characters, setCharacters] = useState(new Map<number, string>(playerState.player?.characters?.map(c => [c.id, c.name])))
    const [mature, setMature] = useState(false)

    let isSolo = useAppSelector(selectIsSolo);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setCharacters(prevState => new Map(prevState).set(parseInt(event.target.name), event.target.value))
        return event.target.value
    }

    function gameSubmit() {
        let chars = Array.from(characters).map(([id, character]) => (
            {
                id: id,
                name: character,
            }));

        return {
            body: {
                characters: chars,
                story: {
                    id: playerState.player?.current_story?.id,
                    settings: {
                        mature: mature
                    }
                }
            },
            code: "game/otpCharacter",
        }
    }

    function allAges() {
        return gameState.game?.settings?.maturity === Maturity.ALL_AGES
    }

    function handleMature(event: React.ChangeEvent<HTMLInputElement>) {
        setMature(event.target.value === "true")
    }

    function ready() {
        return Array.from(characters.values()).every(value => value.length !== 0)
    }

    function subheaders() {
        const count = numberString(playerState.player?.characters?.filter(c => !c.meta.locked)?.length)

        const allAges = gameState.game?.settings.maturity === Maturity.ALL_AGES


        const lines = [t('otpSubmit.subheaders.default', {number: count})]

        if (isSolo) {
            //no op
        } else {
            lines.push(t('createStory.subheaders.guide'))
            if (!allAges) {
                lines.push(t('createStory.subheaders.maturity'))
            }
        }


        return lines
    }

    return (<Waitable playerActionHandler={onSubmit} waitingFor={[GameStatus.GameStatusPostCharacters]}
                      waitHeader={t('createStory.headers.waiting')}>
        <GameFormPage
            submitText={isSolo ? t('characterSubmit.footers.default') : gameState.game?.settings.min_characters === 3 ? t('otpSubmit.footers.ot3') : t('otpSubmit.footers.otp')}
            gameSubmit={gameSubmit}
            ready={ready()}
            subHeaders={subheaders()}
            // subHeaders={[`write ${numberString(playerState.player?.characters?.filter(c => !c.meta.locked)?.length)} fanfic characters.`].concat((allAges() ? subheaders.allAges(gameState.game?.settings.min_characters, gameState.game?.settings.max_characters) : subheaders.multi(gameState.game?.settings.min_characters, gameState.game?.settings.max_characters)).slice(1))}
            titles={[t('characterSubmit.headers.default')]}>
            <div className={"character-submit-form"}>
                {playerState.player?.characters && playerState.player?.characters.map((v, idx) =>
                    <div className={"flex flex-col mx-1 space-y-1 space-x-1 max-w-full"}>
                        <div className={"flex justify-center items-stretch max-w-full px-4"} key={idx}>
                            <label className={"flex justify-center items-center"}
                                   htmlFor={'char' + v.id}>{idx + 1}</label>
                            <input disabled={v.meta.locked} id={'char' + v.id} minLength={1} maxLength={30} key={v.id}
                                   name={'' + v.id} type="text"
                                   autoComplete={"off"}
                                   className={"max-w-full"}
                                   value={characters.get(v.id) ?? ''}
                                   onChange={handleChange} required/>
                        </div>
                    </div>)}


                {/*<input type='submit' disabled={loading} value={loading ? "loading..." : "submit"}/>*/}
                {!allAges() && !isSolo ?
                    <div className={"select-row"}>
                        <div className={"mature-select-container"}>
                            <div className={"mature-select-title fake-button"}>
                                {t('createStory.body.maturity')}
                            </div>

                            <div className={"mature-select"}>
                                <div className={"mature-square-radio-group"}>
                                    <input id="mature-yes" name="mature" type="radio" value="true"
                                           onChange={handleMature}
                                           checked={mature}/>
                                    <label htmlFor="mature-yes">{t('label.eighteenPlus', {ns: 'common'})}</label>
                                    <input id="mature-no" name="mature" type="radio" value="false"
                                           onChange={handleMature}
                                           checked={!mature}/>
                                    <label htmlFor="mature-no">{t('label.allAges', {ns: 'common'})}</label>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
            </div>

        </GameFormPage>
    </Waitable>)
}
export default OtpSubmit