import {useAppSelector} from "../../../redux/store";
import {ColourScheme} from "../../../types2";

interface Theme {
    theme: string
    inactive: string[]
}


export function useStyle(): Theme {

    const theme = useAppSelector(state => state.menu.playerDetails?.meta.theme)

    const notActive = ["classic", "blood", "goth", "blue", "neon", "night", "nineties", "sparkle", "worm", "petal", "hacker", "winter"]

    // display name to css class
    function getClassName() {
        switch (theme) {
            case ColourScheme.CLASSIC:
                return "classic"
            case ColourScheme.BLOOD:
                return "blood"
            case ColourScheme.GOTH:
                return "goth"
            case ColourScheme.BLUE:
                return "blue"
            case ColourScheme.NEON:
                return "neon"
            case ColourScheme.NOIR:
                return "night"
            case ColourScheme.NINETIES:
                return "nineties"
            case ColourScheme.SPARKLE:
                return "sparkle"
            case ColourScheme.WORM:
                return "worm"
            case ColourScheme.PETAL:
                return "petal"
            case ColourScheme.HACKER:
                return "hacker"
            case ColourScheme.WINTER:
                return "winter"
        }
        return "classic"
    }


    const curClass = getClassName()


    return {theme: curClass, inactive: notActive.filter(c => c !== curClass)}
}

export default useStyle