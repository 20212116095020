import React from "react"

type Props = {
    children: React.ReactNode
    comic?: boolean
    ordered?: boolean
}

export const BodyListItem = ({children}: { children: React.ReactNode }) => {
    return <li>
        {children}
    </li>
}
const BodyList: React.FC<Props> = (props) => {

    if (props.ordered) {
        return <ol>{props.children}</ol>
    } else {
        return <ul>{props.children}</ul>
    }


    // return <div className={"font-comic normal-case"}>{props.children}</div>
}

export default BodyList