import BackButton from "../back-button/back-button";
import React from 'react'


type Props = {
    onBack?: () => void
    text?: string
    jsx?: JSX.Element
}

const Footer = ({onBack, text, jsx}: Props) => {

    return <div
        className={"p-0.5 mt-auto flex flex-row h-14 text-lg bg-gray-med border-2 border-gray-drker border-r-gray-light border-b-gray-light border-t-gray-shadow border-t-4"}>
        {onBack ? <BackButton onBack={onBack}/> : ''}

        <div className={`text-base flex-grow flex flex-row items-stretch justify-between`}>
            {jsx ?? text ?? ''}
        </div>
    </div>
}

export default Footer