import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";
import BodyList, {BodyListItem} from "../../components/common/body-list/body-list";

export let blog2Title = "Entry #2 - Ahhhhhhhhhhhh"

export let blog2Author = "Posted by Michael / Friday April 2nd 2021"

export let blog2Content = <Body>

    <BodyText>
        Hey all, for a while I've been looking to make a game/app/website/something as a way learn some new stuff that I
        don't
        get the opportunity to do at work. Zee came up the idea of passing around and continuing your friends' fanfics a
        while ago
        it worked out great when we tried it out one night ( with a lot of manual effort however!)

        Some time passed and it seemed like a neat idea for an actual game, like, a multiplayer game, on the internet!
        The game would take care of all the annoying manual steps and players could play this thing LIVE
    </BodyText>
    <BodyText>
        Zee planned the whole thing out; the layout of the screen and the flow of the game. I jumped head first into
        developing this thing and started learning stuff along the way as I broke things.

        We both chose to just jump into this thing blind from a game design perspective and we figured stuff out as we
        faced problems
    </BodyText>

    <BodyText>
        It was super exciting to reach a number of milestones throughout the project.
        Like, when we were actually able to just input names on the website, with the game just running on my computer.
        It was really cool to actually see this thing progress, step-by-step.

        At first it was just being able to select your avatar, and then submitting characters, and then shuffling the
        characters and selecting them, then writing a story, and then the stories would switch automatically!
        We could actually play through a game! (while the game was running on my computer. still not on internet.


        Other cool milestones were getting the website running with our domain name, and putting it 100% ️in the
        ☁️cloud☁!
    </BodyText>

    <BodyText>
        Probably lots of weird bugs and things to fix but I hope all the important bits work!
    </BodyText>
    <BodyText>
        - Michael
    </BodyText>


    <div style={{fontSize: "0.8rem"}}>
        Technical overview for anyone interested!
        <br/>
        tl;dr. Website is in ReactJS. Game Server is in Go. Database is Postgres. All hosted on Google Cloud Platform
        (GCP).
        <br/>

        Going into this thing, there were two things I know I wanted to do 100%. I wanted to learn ReactJS and I wanted
        to do a bigger project
        in Go. I did not want to use any conventional game tools or anything as my main goal for this thing was to learn
        more about html/js/css and React. So my approach to making a game was probably a bit unconventional!
        <br/>
        A few notes:
        <BodyList>
            <BodyListItem>Got started with GCP because I'm familiar with it and we get a generous credit for small projects!</BodyListItem>
            <BodyListItem>Used Firestore Database on GCP because it required no work to set up but ended up being a headache</BodyListItem>
            <BodyListItem>Switched to Postgres about half way through because it was more 'ergonomic' to work with</BodyListItem>
            <BodyListItem>Website talks to Game Server via Websockets so that the Server can send updates to you automatically!
            </BodyListItem>
            <BodyListItem>Game & Player state are always persisted to database so that Game Server can be restarted and resumed
                easily!
            </BodyListItem>
        </BodyList>

        I could go on and on about the weird challenges I faced during this but I'll leave it there for now
    </div>


</Body>

export let blog = {
    title: blog2Title,
    author: blog2Author,
    content: blog2Content
}