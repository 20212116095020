import React from "react";
import {GameMode} from "../../../types2";
import MenuPage from "../menu-page/menu-page";
import {useTranslation} from "react-i18next";
import GameModeButton from "./game-mode-button";

type Props = {
    onSelect: (mode: GameMode) => void
}

export const SelectGameMode = ({onSelect}: Props) => {

    const {t} = useTranslation('create-game')

    function handleStandard() {
        onSelect(GameMode.default)
    }

    function handleBoring() {
        onSelect(GameMode.otp)
    }

    function handleAboutYou() {
        onSelect(GameMode.self_insert)
    }

    function handleBrokenTelephone() {
        onSelect(GameMode.broken_telephone)
    }

    function handleSolo() {
        onSelect(GameMode.solo_mode)
    }


    return <MenuPage subHeaders={[t('subheaders.default')]}
                     titles={[t('headers.default')]}>

        <div className={"flex-1 flex flex-col justify-center items-center"}>
            <div className={"w-3/4 flex flex-col space-y-6 my-4"}>

                <GameModeButton handleClick={handleStandard} bigText={t('body.standard')}
                                smallText={t('body.standardSub')}/>
                <GameModeButton handleClick={handleBoring} bigText={t('body.boring')} smallText={t('body.boringSub')}/>
                <GameModeButton handleClick={handleAboutYou} bigText={t('body.aboutYou')}
                                smallText={t('body.aboutYouSub')}/>

                <GameModeButton handleClick={handleBrokenTelephone} bigText={t('body.brokenTelephone')}
                                smallText={t('body.brokenTelephoneSub')}/>
                <GameModeButton handleClick={handleSolo} bigText={t('body.soloMode')}
                                smallText={t('body.soloModeSub')}/>
            </div>

        </div>
    </MenuPage>
}