import {Character, StorySubmission} from "../types";
import React from "react";
import {SafeJoin} from "../util";


const words: Map<number, string> = new Map([
    [0, "first"],
    [1, "second"],
    [2, "third"],
    [3, "fourth"],
    [4, "fifth"],
    [5, "sixth"],
    [6, "seventh"],
    [7, "eighth"],
    [8, "ninth"],
    [9, "tenth"],
    [10, "eleventh"],
    [11, "twelfth"],
    [12, "thirteenth"],
    [13, "fourteenth"],
    [14, "fifteenth"],
    [15, "sixteenth"],
    [16, "seventeenth"],
    [17, "eighteenth"]
])

export function partMap(index: number, totalParts: number) {
    switch (index) {
        case 0:
            return 'the beginning'
        case totalParts - 1:
            return 'the end'
        case 1:
            return 'the second part'
        case 2:
            return 'the third part'
        case 3:
            return 'the fourth part'
        case 4:
            return 'the fifth part'
        case 5:
            return 'the sixth part'
        case 6:
            return 'the seventh part'
        case 7:
            return 'the eighth part'
        case 8:
            return 'the ninth part'
        case 9:
            return 'the tenth part'
        default:
            return 'the n/a'

    }

}

export const subheaders = {
    default: ""
}

export const body = {

    part1: (submissionIndex: number, numParts: number, storyIndex: number) => <>Get ready to write <span
        className={"text-emphasis"}>{partMap(submissionIndex, numParts)}</span> of
        the <span className={"text-emphasis"}>{words.get(storyIndex)} fanfic</span>.</>,
    part1Solo: (submissionIndex: number, numParts: number) => <>Get ready to write <span
        className={"text-emphasis"}>{partMap(submissionIndex, numParts)}</span>.</>,

    partX: (submissionIndex: number, numParts: number, storyIndex: number) => <>The fanfics have been shuffled. Now
        you're writing <span
            className={"text-emphasis"}>{partMap(submissionIndex, numParts)}</span> of
        the <span className={"text-emphasis"}>{words.get(storyIndex)} fanfic</span>.</>,
    partXSolo: (submissionIndex: number, numParts: number) => <>Now you're writing <span
        className={"text-emphasis"}>{partMap(submissionIndex, numParts)}</span>.</>,

    partEnd: (submissionIndex: number, numParts: number, storyIndex: number) => <>The fanfics have shuffled for the last
        time. Time to write <span
            className={"text-emphasis"}>{partMap(submissionIndex, numParts)}</span> of
        the <span className={"text-emphasis"}>{words.get(storyIndex)} fanfic</span>.</>,
    partEndSolo: (submissionIndex: number, numParts: number) => <>Time to write <span
        className={"text-emphasis"}>{partMap(submissionIndex, numParts)}</span>.</>,

    characters: (characters?: Character[]) => <>It features the
        characters {SafeJoin(characters?.filter(c => c.selected).map<React.ReactNode>(c => <span key={'st-char-' + c.id}
                                                                                                 className={"text-emphasis"}>{c.name}</span>), ' & ')}.</>,

    charactersSolo: (characters?: Character[]) => <>Featuring the
        characters {SafeJoin(characters?.filter(c => c.selected).map<React.ReactNode>(c => <span key={'st-char-' + c.id}
                                                                                                 className={"text-emphasis"}>{c.name}</span>), ' & ')}.</>,

    prompt: (submission?: StorySubmission, multiPrompt?: boolean) => <>Your prompt
        is {(submission?.index && submission?.index > 0) && !multiPrompt ? ' STILL ' : ''}<span
            className={"text-emphasis"}>{submission?.meta.prompt}</span>.</>,

    mature: (rating: string) => <>this fanfic is rated <span className={"text-emphasis"}>{rating}</span>, so write
        accordingly...</>,

}

export const footers = {}

