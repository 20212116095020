import React from "react";
import "./styles.css"
import {config} from "../../../constants";
import {useAppSelector} from "../../../redux/store";
import GamePage from "../game-page/game-page";

const paths = config.avatarsBig

const Break = () => {

    const selectPlayerMeta = useAppSelector(state => state.player.player?.meta)

    return <GamePage titles={["congratulations!"]} subHeaders={["you frantic'd a fanfic!"]}>
        <div className={"break-2"}>
            <div className={"break-2-bg"}/>
            <img className={"break-2-img break-2-animation"} src={paths[selectPlayerMeta?.sprite_id as number]}
                 alt={paths[selectPlayerMeta?.sprite_id as number]} style={{objectFit: "contain"}}/>
        </div>
    </GamePage>
}
export default Break