import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import App from "./app";
import {AdinplayBottom} from "./components/common/ad/adinplay";
import {useAppDispatch} from "./redux/store";
import AppWrapper from "./app-wrapper";
import {PageNumber} from "./components/menu/menu";
import {updatePlayerDetails} from "./redux/slices/menu";
import {config} from "./constants";
import useStyle from "./components/common/styled/styled";
import {useUser} from "./components/menu/auth/is_loggedin";
import {FontOptions} from "./types2";


const AppContainer = () => {

    const dispatch = useAppDispatch()

    const style = useStyle()

    const theme = style.theme


    const user = useUser()

    useEffect(() => {
        const savedTheme = localStorage.getItem("playerTheme")

        if (user?.premium) {
            return
        }

        if (savedTheme && parseInt(savedTheme) > 1) {
            localStorage.setItem("playerTheme", "0")
        }

    }, [user?.premium])

    useEffect(() => {
            const savedName = localStorage.getItem("playerName")
            const savedSecret = localStorage.getItem("playerSecret")
            const savedSprite = localStorage.getItem("playerSprite")
            const savedMature = localStorage.getItem("playerMature")
            const savedAccessibility = localStorage.getItem("playerAccessibility")
            const savedTheme = localStorage.getItem("playerTheme")

            if (savedName && savedSprite) {
                dispatch(updatePlayerDetails({
                    name: savedName,
                    secret: savedSecret ?? "",
                    meta: {
                        sprite_id: parseInt(savedSprite),
                        mature: (savedMature === "true"),
                        accessibility: false,
                        accessibility2: savedAccessibility ? (savedAccessibility === "true" ? FontOptions.COMIC_SANS : parseInt(savedAccessibility)) : FontOptions.DEFAULT,
                        theme: savedTheme ? parseInt(savedTheme) : 0
                    }
                }))
            }
        },
        [dispatch]
    )


    return <div className={`holder franticfanficbg ${theme}`}>
        <AppWrapper>
            <Routes>
                <Route path="/" element={<App initialPage={PageNumber.Main}/>}/>
                <Route path="/terms" element={<App initialPage={PageNumber.Terms}/>}/>
                <Route path="/privacy" element={<App initialPage={PageNumber.Privacy}/>}/>
                <Route path="/blog" element={<App initialPage={PageNumber.Blog}/>}/>
                <Route path="/supportus" element={<App initialPage={PageNumber.SupportUs}/>}/>
                <Route path="/howtoplay" element={<App initialPage={PageNumber.Help}/>}/>
                <Route path="/bugreport" element={<App initialPage={PageNumber.BugReport}/>}/>
                <Route path="/contact" element={<App initialPage={PageNumber.Contact}/>}/>
                <Route path="/about" element={<App initialPage={PageNumber.About}/>}/>
                <Route path="/codeofconduct" element={<App initialPage={PageNumber.CodeOfConduct}/>}/>
                <Route path="/user" element={<App initialPage={PageNumber.Name}/>}/>
                <Route path="/tobeyraimi" element={<App initialPage={PageNumber.Admin}/>}/>
                <Route path="/zine" element={<App initialPage={user?.premium ? PageNumber.Zines : PageNumber.Main}/>}/>
                <Route path="/user" element={<App initialPage={PageNumber.Name}/>}/>
                <Route path="/supporter" element={<App initialPage={PageNumber.SupporterBadge}/>}/>
                <Route path="/thankyou" element={<App initialPage={PageNumber.ThankYou}/>}/>
                {/*<Route path="/drawing" element={<App initialPage={PageNumber.Drawing}/>}/>*/}

            </Routes>
        </AppWrapper>

        <div className={"hidden relative mt-2 self-start md:block"}>© Frantic Fanfic 2024 {config.development}</div>

        <AdinplayBottom/>

        {/*<AdFooter/>*/}
    </div>
}

export default AppContainer