import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Game} from "../../types";

export interface GameState {
    game?: Game
}



const initialGameState: GameState = {
    game: undefined
}


const gameSlice = createSlice({
    name: "gameSlice",
    initialState: initialGameState,
    reducers: {
        updateGame: (state, action: PayloadAction<Game>) => {
            action.payload.stories?.sort((a,b) => (a.meta.index - b.meta.index))
            action.payload.stories?.forEach(s => s.submissions.sort((a, b) => a.index - b.index))
            action.payload.players.sort((a, b) => (a.id as number) - (b.id as number) ?? 0)

            state.game = {...state.game, ...action.payload}
        }
    }
})


export const {updateGame} = gameSlice.actions
export const gameReducer = gameSlice.reducer
