import {ChangeEvent, useState} from "react";
import MenuFormPage from "../menu-page/menu-form-page";
import RoomAPI from "../../../RoomAPI";
import RadioButtons, {Option} from "../../common/radio-buttons/radio-buttons";
import {useAuth0} from "@auth0/auth0-react";
import BodyText from "../../common/body-text/body-text";


const BugReportTextField = ({label, value, handleChange, maxLength, required = false}: {
    label: string, value: string | undefined, handleChange: (e: ChangeEvent<HTMLInputElement>) => void, maxLength: number, required?: boolean
}) => {
    return <div>
        <div>{label}</div>
        <input required={required} className={"w-full h-auto font-comic normal-case"} type={"text"} value={value}
               onChange={handleChange}
               maxLength={maxLength}/>
        {/*<textarea className={"w-full h-auto font-comic"} value={value} onChange={handleChange} maxLength={maxLength}/>*/}
    </div>
}

const BugReportBigTextField = ({label, value, handleChange, maxLength}: {
    label: string, value: string | undefined, handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void, maxLength: number
}) => {
    return <div>
        <div>{label}</div>
        <textarea className={"w-full h-auto font-comic normal-case"} value={value} onChange={handleChange}
                  maxLength={maxLength}/>
    </div>
}
const BugReportRadioField = <T extends number | string>({label, value, handleChange, values}: {
    label: string, value: T, handleChange: (e: string) => void, values: Option<T>[]
}) => {
    return <div>
        <div>{label}</div>
        <RadioButtons label={label} value={value} options={values} onSelect={handleChange} hideLabel={true}/>
    </div>
}
const BugReportDropdownField = <T extends number | string>({label, value, handleChange, values}: {
    label: string, value: string | undefined, handleChange: (e: ChangeEvent<HTMLSelectElement>) => void, values: Option<T>[]
}) => {
    return <div>
        <div>{label}</div>
        <select onChange={handleChange}>
            {values.map(v => <option key={v.value} value={v.value}>{v.label ?? v.value}</option>)}
        </select>
    </div>
}


const BugReport = () => {


    const [date, setDate] = useState("")
    const [summary, setSummary] = useState("")
    const [roomCode, setRoomCode] = useState<string>("")
    const [device, setDevice] = useState<string | undefined>()
    const [otherDevice, setOtherDevice] = useState<string>("n/a")
    const [errorDisplayed, setErrorDisplayed] = useState<string>("n/a")
    const [frequency, setFrequency] = useState<string>("once")
    const [additionalInfo, setAdditionalInfo] = useState<string | undefined>("")

    const {isAuthenticated, getAccessTokenSilently} = useAuth0();

    const [submitted, setSubmitted] = useState(false)

    function stringToBool(guy: string) {
        if (guy.toLowerCase() === "yes") {
            return true
        } else if (guy.toLowerCase() === "no") {
            return false
        } else {
            return undefined
        }
    }

    async function handleSubmit() {
        if (true) {
            const token = await getAccessTokenSilently()

            await RoomAPI.SubmitBugReport({
                roomCode,
                device,
                otherDevice: stringToBool(otherDevice),
                summary,
                frequency,
                date,
                errorDisplayed: stringToBool(otherDevice),
                additionalInfo
            }, token)
            setSubmitted(true)
        }

    }

    return <MenuFormPage titles={["report a bug"]} subHeaders={["have you experienced a bug? submit it here!", "you must be logged in to submit a bug report."]}
                         submit={handleSubmit} ready={!submitted && isAuthenticated}>

        {!submitted ?
            <div className={"m-4 normal-case text-base font-sans space-y-2 flex flex-col"}>
                <BugReportTextField required label={"Please describe your issue:"} value={summary}
                                    handleChange={e => setSummary(e.target.value)} maxLength={200}/>
                <BugReportTextField required label={"When did this happen:"} value={date}
                                    handleChange={e => setDate(e.target.value)} maxLength={100}/>
                <BugReportTextField label={"What is the room code:"} value={roomCode}
                                    handleChange={e => setRoomCode(e.target.value)} maxLength={6}/>
                <BugReportTextField label={"What was the device used:"} value={device}
                                    handleChange={e => setDevice(e.target.value)}
                                    maxLength={100}/>
                <BugReportRadioField label={"Did this error happen on another device:"} value={otherDevice}
                                     handleChange={e => setOtherDevice(e)}
                                     values={[{value: "no", label: "No"}, {value: "yes", label: "Yes"}, {
                                         value: "n/a",
                                         label: "N/A"
                                     }]}/>
                <BugReportRadioField label={"Was there an error message:"}value={errorDisplayed}
                                     handleChange={e => setErrorDisplayed(e)}
                                     values={[{value: "no", label: "No"}, {value: "yes", label: "Yes"}, {
                                         value: "n/a",
                                         label: "N/A"
                                     }]}/>

                <BugReportDropdownField label={"Frequency of issue:"} value={frequency}
                                        handleChange={e => setFrequency(e.target.value)}
                                        values={[{value: "once"}, {value: "hardly ever"}, {value: "occasionally"}, {value: "every time"}]}/>

                <BugReportBigTextField label={"Please add any additional info here (error message, etc):"} value={additionalInfo}
                                       handleChange={e => setAdditionalInfo(e.target.value)} maxLength={500}/>

                <BodyText>
                    If you have a screenshot of the issue, please email it to franticfanficgame@gmail.com.
                </BodyText>

                <BodyText>Thank you!</BodyText>
            </div> :
            <div className={"flex-1 flex flex-col self-center justify-center px-2"}><div className={"text-4xl fake-button"}>Thank you for submitting a bug report! </div></div>}
    </MenuFormPage>
}

export default BugReport