import {body} from "../../../text/code-of-conduct"
import MenuPage from "../menu-page/menu-page";
import {useTranslation} from "react-i18next";


const CodeOfConduct = () => {

    const {t} = useTranslation('main')

    return (<MenuPage titles={[t('codeofconduct.headers.default')]}>
        <div className={"normal-case m-4 text-base font-comic"}>
            {body.code}
        </div>
    </MenuPage>)
}

export default CodeOfConduct